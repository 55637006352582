import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FormInput from '../../components/FormInput';
import AuthHeader from '../../components/AuthHeader';

export default function AuthRegister(){
    var base = new Base()

    const [register_form_arr, set_register_form_arr] = useState([
        {label : 'Daiken User name', input_type : 'text', type : 'username', value : '', is_error : false},
        {label : 'Name', input_type : 'text', type : 'name', value : '', is_error : false},
        {label : 'Kata Sandi', input_type : 'password', type : 'password', value : '', is_error : false, is_show : false},
        {label : 'Ulangi Kata Sandi', input_type : 'password', type : 'retype_password', value : '', is_error : false, is_show : false},
    ])

    const [is_button_disable, set_is_button_disable] = useState(false)
    const [alert_data, set_alert_data] = useState({message : '', type : ''})

    const [user_name, set_user_name] = useState('')
    const [name, set_name] = useState('')
    const [password, set_password] = useState('')
    const [retype_password, set_retype_password] = useState('')

    const [is_show_password, set_is_show_password] = useState(false)
    const [is_show_retype_password, set_is_show_retype_password] = useState(false)

    useEffect(async ()=>{
        await localStorage.clear()
    }, [])

    async function changeInput(value, index){
        set_alert()
        set_is_button_disable(false)

        var data_index = register_form_arr[index]
        register_form_arr[index].value = value
        for(var x in register_form_arr){
            register_form_arr[x].is_error = false
        }
        base.update_array(register_form_arr, set_register_form_arr, data_index, index)
    }

    async function set_alert(message='', type=''){
        set_alert_data({message : message, type : type})
    }

    async function regiter(e){
        e.preventDefault()
        var flag = 1
        var data_register = {username : '', name : '', password : '', retype_password : ''}

        for(var x in register_form_arr){
            if(register_form_arr[x].value === ''){
                register_form_arr[x].is_error = true
                base.update_array(register_form_arr, set_register_form_arr, register_form_arr[x], x)
                flag = 0
                break
            }
            else{
                data_register[register_form_arr[x].type] = register_form_arr[x].value
            }
        }

        
        if(flag){
            base.$('#preloader_modal').modal('show')

            set_is_button_disable(true)
            if(data_register.password !== data_register.retype_password){
                set_alert(`Retype Password does't match`, 'danger')
            }
            else{
                var url = '/auth/register'
                var response = await base.request(url, 'post', data_register)
                if(response != null){
                    if(response.status == 'success'){
                        // await localStorage.setItem('token', response.token)
    
                        set_alert('Register Success! Please Wait...', 'success')
    
                        setTimeout(() => {
                            window.location.href = '/auth/login'
                        }, 750);
                    }
                    else{
                        set_alert(response.message, 'danger')
                        setTimeout(() => {
                            base.$('#preloader_modal').modal('hide')
                        }, 750);
                        set_is_button_disable(false)
                    }
                }
                else{
                    setTimeout(() => {
                        base.$('#preloader_modal').modal('hide')
                    }, 750);
                    set_is_button_disable(false)
                }
            }

        }
    }

    return(

        <div className='page_wrap'>
            <div className='row m-0'>

                <div className='col-12'>
                    <AuthHeader title={'Registrasi'} is_back_btn={false} />
                </div>

                <div className='col-12 mt-5'>
                    <form className='row' onSubmit={(e)=>regiter(e)}>
                    
                        {
                            alert_data.type != '' &&
                            <div className='col-12'>
                                <div class={"alert alert-" + (alert_data.type)} role="alert">
                                    {alert_data.message}
                                </div>
                            </div>
                        }

                        {
                            register_form_arr.map((data, index)=>(
                                <div className={"col-12 form-group m-0" + (index != 0 ? ' mt-3' : '')} key={index}>
                                    <FormInput
                                        label={data.label}
                                        type={data.type}
                                        value={data.value}
                                        changeInput={(value)=>changeInput(value, index)}
                                        is_error={data.is_error}
                                        error_message={(data.label) + ` can't be empty`}
                                        />
                                </div>
                            ))
                        }
                        

                        <div className='col-12 mt-4'>
                            <button type='submit' className='btn btn-lg btn-primary shadow-sm rounded w-100' onClick={(e)=>regiter(e)}>Registrasi</button>
                            <button type='button' className='btn btn-lg btn-outline-primary shadow-sm rounded w-100 mt-3' onClick={()=>window.location.replace('/auth/login')}>Masuk</button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}