import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import SelectInputQR from '../../components/selectInputQR';

export default function WarehouseSelectInput(){
    var base = new Base()
    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [menu_arr] = useState([
        {title : 'Input ID', type : 'input', nav : '/warehouse/input-id?type=' + query.get('type')},
        {title : 'Scan Barcode', type : 'scan', nav : '/warehouse/scan-barcode?type=' + query.get('type')},
    ])


    useEffect(async ()=>{
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        await localStorage.removeItem('packing_input_palette')
        await localStorage.removeItem('packing_input_product')
    }, [])

    function menuSelected(index){
        var url = menu_arr[index].nav
        if(query.get('is_temp') != null){
            if(query.get('is_temp') == 1){
                url += '&is_temp=1'
            }
        }

        window.location.href = url
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={(query.get('type') === 'back_to_packing' ? 'Back to Packing' : query.get('type') === 'warehouse' ? 'Collect to Warehouse' : '')} icon={(query.get('type') === 'remove' ? 'bi bi-dash-circle-fill' : null)} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <SelectInputQR menu_arr={menu_arr} menuSelected={(index)=>menuSelected(index)} type={(query.get('type') === 'view' ? 'viewing' : 'entering')} />

                </div>
            </div>

        </>
    )
}