import { useState } from "react"
import $ from 'jquery';
import ScrollUp from "../../../components/scrollUp";

export default function FamilyHistory(data){
    const [familyData, set_familyData]=useState(data.data)

    //console.log(trainingData)
    return(
        <>
        <div className='col-12 py-2'>
            <h5 style={{marginBottom:20}}>Family Information</h5>
            <div className='row'>
                <div className='col-12 mb-5'>
                    <div className='row mb-5'>
                    {
                    familyData.map((data, index)=>(
                        <div className='col-12 py-1 mb-3 custom-tabs-bg-solusiqu' style={{borderBottom : '1px solid #DADDDF', fontSize:'10px'}} key={index}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{width:'80px'}}>Name</td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{data.CNAME}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'80px'}}>Relation</td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{data.CFAMILYSTATUS}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'80px'}}>Address</td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{data.CADDRESS}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'80px'}}>Sex</td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{data.NSEX='2' ? 'Female' : data.NSEX='1' ? 'Male' : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'80px'}}>Telp. </td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{data.CTELP}</td>
                                    </tr>  
                                </tbody>   
                            </table>
                        </div>
                    ))
                    }
                    </div>
                </div>                
            </div>
        </div>
        </>
    )
}