
export default function ViewField({data,label,id,type,style,useLabel,onChange,labelStyle,disabled,readonly,inputClass}){

    return(
        <>
        <div className='form-group row'>
            {useLabel&&
            <div className="col row" style={labelStyle}>
                <label htmlFor={id} className='custom-input-solusiqu col-6 col-form-label'>{label}</label>
            </div>}
            <div className='col-12'>
                <input type={type} style={style} disabled={disabled} readOnly={readonly} className={'custom-input-solusiqu form-control rounded '+inputClass} id={id} value={data} onChange={onChange}/>
            </div>
        </div>
        </>
    )
}