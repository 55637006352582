import React, { useEffect, useState } from 'react';
import Base from '../utils/base';

export default function TrackingButton({count_barcode, actionBtn, is_button_disable, default_input}){
    var base = new Base()

    useEffect(async ()=>{
        
    }, [])

    return(
        <>
            {
                default_input === "camera_input" ?
                <div className='col-12'>
                    <button className='btn btn-lg w-100 rounded btn-outline-primary customBtn' onClick={()=>actionBtn('scan')} disabled={is_button_disable}>
                        <i className='bi bi-upc-scan'></i> Scan Product Barcode {count_barcode != 0 ? count_barcode : ''}
                    </button>
                </div>
                :
                default_input === "manual_input" ?
                <div className='col-12 mt-3'>
                    <button className='btn btn-lg w-100 rounded btn-outline-primary customBtn' onClick={()=>actionBtn('input')} disabled={is_button_disable}>
                        <i className='bi bi-input-cursor'></i> Input Product Barcode {count_barcode != 0 ? count_barcode : ''}
                    </button>
                </div>
                :
                <></>
            }
        </>
    )
}