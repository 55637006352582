import * as React from 'react';
import { useEffect, useState } from 'react';
import Base from '../../../utils/base';

//material-ui
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PageHeader from '../../../components/pageHeader';
import PersonInfo from './personInfo';
import PositionHistory from './positionHistory';
import TrainingHistory from './trainingHistory';
import FamilyHistory from './familyInfo';
import { Diversity1Outlined, ModelTrainingOutlined, PersonOutline } from '@mui/icons-material';

export default function ESS_EmployeeInfo(){
    var base = new Base()
    const [employeeInfo,set_employeeInfo] = useState('')
    const [positionHistory, set_positionHistory] = useState('')
    const [trainingHistoryData, set_trainingHistoryData] = useState('')
    const [familyData, set_familyData] = useState('')

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
    setValue(newValue);
    };

    useEffect(async ()=>{
        // await localStorage.clear()
        base.cek_pwa_version()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                get_data()
            }
        }
    }, [])

    async function get_data(){
        base.$('#preloader_modal').modal('show')
        var cidemployee = await localStorage.getItem('psn_cid')
        //console.log(cidemployee)
        var dataurl = {cidemployee}
        var url = '/hris/employeeinfo'//?cidemployee='+cidemployee
        var response = await base.request(url,'post',dataurl)
        //console.log(response)

        if(response != null){
            if(response.status == 'success'){
                var data = response.data
                var employeeHistory = response.position
                var vtrainingHistory = response.training
                var vfamilyData = response.family

                data.DJOIN = new Date(data.DJOIN).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
                
                for(var x in vtrainingHistory){
                    if(vtrainingHistory[x].DSTART){
                        vtrainingHistory[x].DSTART = new Date(vtrainingHistory[x].DSTART).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
                    }
                    if(vtrainingHistory[x].DFINISH){
                        vtrainingHistory[x].DFINISH = new Date(vtrainingHistory[x].DFINISH).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
                    }
                }

                for(var x in employeeHistory){
                    if(employeeHistory[x].DSTART_HRD){
                        employeeHistory[x].DSTART_HRD = new Date(employeeHistory[x].DSTART_HRD).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
                    }
                    if(employeeHistory[x].DEND_HRD){
                        employeeHistory[x].DEND_HRD = new Date(employeeHistory[x].DEND_HRD).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
                    }
                }

                set_employeeInfo(data)
                set_positionHistory(employeeHistory)
                set_trainingHistoryData(vtrainingHistory)
                set_familyData(vfamilyData)
                base.hidePreloader()

                console.log(employeeHistory)     
            }
        }

     	base.hidePreloader()
 	}

  function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3}}>
                {children}
              </Box>
            )}
          </div>
        );
  }
      
      CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
  function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
  }

    return(
        <>
            <div className='page_wrap' style={{marginTop:70}}>
                <div className='row'>
                    <div className='fixed-top page_wrap shadow-md' style={{backgroundColor: '#FFF', height:'140px'}}>
                        <div className='pt-5'>
                            <PageHeader title='Employee Info' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                        </div>
                        <Box sx={{width:'100%',backgroundColor:'#FFFF'}} className='mt-2'>
                            <Box>
                                <Tabs value={value} onChange={handleChange} variant="fullWidth" scrollButtons={false}>
                                <Tab sx={{fontSize:'12px', padding:0}} icon={<PersonOutline/>} {...a11yProps(0)} />
                                <Tab sx={{fontSize:'12px', padding:0}} icon={<ModelTrainingOutlined/>} {...a11yProps(1)} />
                                <Tab sx={{fontSize:'12px', padding:0}} icon={<Diversity1Outlined/>} {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                        </Box>  
                    </div>
                    <div className='w-100'>
                        <CustomTabPanel value={value} index={0}>
                            <PersonInfo data={employeeInfo} /> 
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <TrainingHistory data={trainingHistoryData} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <FamilyHistory data={familyData} />
                        </CustomTabPanel>
                    </div>
                </div>
            </div>
        </>
    )
}