import * as React from 'react';
import { useEffect, useState } from 'react';
import Base from '../../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import dayjs from 'dayjs';
import MenuHeaderTwoDate from '../../../components/MenuHeaderTwoDate';

export default function ESS_Overtime(){
    var base = new Base()

    const newDate = new Date()
    const nYear = newDate.getFullYear()
    const [iptStart, set_iptStart] = useState(dayjs(nYear+'/01/01'))
    const [iptFinish, set_iptFinish] = useState(dayjs(nYear+'12/31'))
    /*const columnView = [
        {field: 'LHD_DTRANS', headerName: 'Trans. Date', type: 'Date', width: 160},    
        {field: 'LHD_NDAYS', headerName: 'Days', width: 120},    
        {field: 'LVT_CDESCRIPTION', headerName: 'Leave', width: 120},    
        {field: 'LHD_CSTATUS', headerName: 'Status', width: 120}
    ]*/

    const[dataBrowse, set_dataBrowse] = useState([])

    useEffect(async ()=>{
        // await localStorage.clear()
        base.cek_pwa_version()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                get_data()
            }
        }
    }, [])

    async function get_data(){
        base.$('#preloader_modal').modal('show')
        
        var cidemployee = await localStorage.getItem('psn_cid')
        //console.log(cidemployee)
        var dstart = iptStart.$y+'/'+(iptStart.$M+1)+'/'+iptStart.$D
        var dend = iptFinish.$y+'/'+(iptFinish.$M+1)+'/'+iptFinish.$D
        var dataurl = {
            cidemployee, dstart, dend 
        }
        var url = '/hris/employeeovertime'//?cidemployee='+cidemployee+'&nmonth='+(iptMonth.$M+1)+'&nyear='+iptMonth.$y
        var response = await base.request(url,'post',dataurl)
        //console.log(url)
        if(response != null){
            if(response.status == 'success'){                

                var dataView = response.absentee
                for(var x in dataView){
                    if (dataView[x].OVH_NFLAGSTATUS !== 0)
                        dataView[x].DETAIL_URL = '/ess/overtime/detail?type=viewonly&id='+dataView[x].OVH_CID                       
                    else
                        dataView[x].DETAIL_URL = '/ess/overtime/detail?type=view&id='+dataView[x].OVH_CID
                        dataView[x].OVH_DTRANS = new Date(dataView[x].OVH_DTRANS).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"long", day:"numeric"})
                }
                set_dataBrowse(dataView)
                base.hidePreloader()

                console.log(dataView)     
            }
        }

     	base.hidePreloader()
 	}

    return(
        <>
            <div className='page_wrap' style={{marginTop:220}}>
                <div className='row'>
                    <MenuHeaderTwoDate 
                        headTitle={'Overtime'}
                        dateStartValue={iptStart}
                        onChangeDateStart={(newValue) => set_iptStart(newValue)}
                        dateFinishValue={iptFinish}
                        onChangeDateFinish={(newValue) => set_iptFinish(newValue)}
                        onClickFilter={()=>get_data()}
                    />
                    <div className='col-12 mt-2'>
                        {
                            dataBrowse?.map((data,index)=>(
                                <div key={index} className='card mb-4 rounded'>
                                    <a className='card-block stretched-link text-decoration-none' href={data.DETAIL_URL}>
                                        <div className='col mt-2'>
                                            <div className='float-right' style={data.OVH_NFLAGSTATUS=='10'?{color:'#365b7d',fontWeight:'bold',fontSize:'12px'}:data.OVH_NFLAGSTATUS=='20'?{color:'#367d5d',fontWeight:'bold',fontSize:'12px'}:data.OVH_NFLAGSTATUS=='5'?{color:'#a162a8',fontWeight:'bold',fontSize:'12px'}:{fontWeight:'bold',fontSize:'12px'}}>{data.OVH_CFLAGSTATUS}</div>
                                            <h6 className='card-title' style={data.OVH_NFLAGSTATUS=='10'?{color:'#365b7d',fontWeight:'bold'}:data.OVH_NFLAGSTATUS=='20'?{color:'#367d5d',fontWeight:'bold'}:data.OVH_NFLAGSTATUS=='5'?{color:'#a162a8',fontWeight:'bold'}:{fontWeight:'bold'}}>{data.OVH_DTRANS}</h6>
                                                <div className='col mb-3'>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{paddingRight:'10px'}}>Trans. No.</td>
                                                                <td>:</td>
                                                                <td style={{paddingLeft:'10px'}}>{data.OVH_CMANUALNO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{paddingRight:'10px'}}>CC</td>
                                                                <td>:</td>
                                                                <td style={{paddingLeft:'10px'}}>{data.OVH_CNOTES}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                        </div>
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div>
                <a className="btn btn-lg btn-light" 
                    style={{borderRadius:'50%', position:'fixed', bottom:'25px', right:'25px', width:'50px', height:'50px',zIndex:1}} 
                    href='/ess/overtime/detail?type=create'
                ><i className="fas fa-plus"></i></a>
            </div>
        </>
    )
}