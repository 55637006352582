import * as React from 'react';
import { useEffect, useState } from 'react';
import Base from '../../../utils/base';
import PageHeader from '../../../components/pageHeader';

import Personal from './personal';
import { FormControl, MenuItem, Select } from '@mui/material';
import WrongProcessModal from '../../../components/modalWrongProcess';
import PersonalRevision from './personal';
import FamilyRevision from './family';

export default function ESS_Revision(){
    var base = new Base()

    const [value, setValue] = useState('');
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [error_message_modal, set_error_message_modal] = useState('')
    const [v_readOnly, set_v_readOnly] = useState(false)

    useEffect(async ()=>{
        // await localStorage.clear()
        base.cek_pwa_version()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
    }, [])

    async function checkSelect(){
        var iptGroup = value

        if (iptGroup=='') {
            set_error_message_modal('you must choose revision type')
            base.$('#modalWrongProcess').modal('show')
        }else if (iptGroup==1){
            window.location.href='/ess/revision/personal'
            set_v_readOnly(true)
        }else if (iptGroup==2){
            window.location.href='/ess/revision/family'
            set_v_readOnly(true)
        }else if (iptGroup==3){
            window.location.href='/ess/revision/address'
            set_v_readOnly(true)
        }else if (iptGroup==4){
            window.location.href='/ess/revision/academy'
            set_v_readOnly(true)
        }/*else if (iptGroup==5){
            window.location.href='/ess/revision/tax'
            set_v_readOnly(true)
        }*/
    }

    return(
        <>
            <div className='page_wrap' style={{marginTop:60}}>
                <div className='row'>
                    <div className='fixed-top page_wrap shadow-md' style={{backgroundColor: '#FFF', height:100}}>
                        <div className='pt-5'>
                            <PageHeader title='Revision Data' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className={'custom-tabs-bg-solusiqu p-3 mb-3'}>
                            <div className='form-group'>
                                <div className='col'>
                                    <label>Choose to revision</label>
                                </div>
                                <div className='col-12'>
                                    <FormControl 
                                        required
                                        sx={{width:'100%'}}
                                        disabled={v_readOnly}
                                    >
                                        <Select
                                            size='small'
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            <MenuItem value={1}>Personal Data</MenuItem>
                                            <MenuItem value={2}>Family Data</MenuItem>
                                            <MenuItem value={3}>Address Data</MenuItem>
                                            <MenuItem value={4}>Academy Data</MenuItem>
                                            {/*<MenuItem value={5}>Tax Data</MenuItem>*/}
                                        </Select>
                                    </FormControl>
                                    <div className='d-flex justify-content-center mt-2'>
                                        <button className='btn btn-info rounded' 
                                                onClick={checkSelect}
                                                disabled={v_readOnly}
                                        >Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal}/>
        </>
    )
}