import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import FormInput from '../../components/FormInput';
import WrongProcessModal from '../../components/modalWrongProcess';

export default function ProfileIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({CEMPLOYEE : '', CIDEMPLOYEE : ''})
    const [def_input_arr, set_def_input_arr] = useState([
        {title : 'Camera Input', value : 'camera_input', is_selected : false},
        {title : 'Manual Input', value : 'manual_input', is_selected : false},
    ])

    const [register_form_arr, set_register_form_arr] = useState([  
        {label : 'Username', input_type : 'text', type : 'username', value : '', is_error : false, readonly :'readonly'},      
        {label : 'Old Password', input_type : 'password', type : 'old_password', value : '', is_error : false, is_show : false},
        {label : 'New password', input_type : 'password', type : 'password', value : '', is_error : false, is_show : false},
        {label : 'Repeat New Password', input_type : 'password', type : 'retype_password', value : '', is_error : false, is_show : false},
    ])

    const [is_button_disable, set_is_button_disable] = useState(false)
    const [alert_data, set_alert_data] = useState({message : '', type : ''})
    const [input_selected, set_input_selected] = useState('')

    const [error_message_modal, set_error_message_modal] = useState('')

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }
        else {
            if(navigator.onLine){
                get_data()
            }
            else{
                base.$('#error_modal').modal('show')
            }
        }
    }, [])

    async function get_data(){
        base.$('#preloader_modal').modal('show')
        var url = '/auth/profile'
        var response = await base.request(url)
        console.log(response)

        /*if(response != null){
            if(response.status == 'success'){
                var data = response.data
                set_user_data(data)

                for(var x in def_input_arr){
                    if(def_input_arr[x].value === data.DEFAULT_INPUT){
                        def_input_arr[x].is_selected = true
                        base.update_array(def_input_arr, set_def_input_arr, def_input_arr[x], x)
                        set_input_selected(def_input_arr[x].value)
                        break
                    }
                }

                //console.log(register_form_arr)
                for(var x in register_form_arr){
                    if(register_form_arr[x].label === 'Username'){
                        register_form_arr[x].value = data.CIDUSER
                        base.update_array(register_form_arr, set_register_form_arr, register_form_arr[x], x)                        
                        break
                    }                
                }

                base.hidePreloader()
            }
            else{
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }
        }
        else{
            base.hidePreloader()
            base.$('#error_modal').modal('show')
        }*/
    }

    async function logoutBtn(){
        await localStorage.removeItem('token')
        window.location.href = '/auth/login'
    }

    async function changeImage(value){

    }

    async function editDefaultInput(e){
        //e.preventDefault()
        //base.$('#modalDefaultInput').modal('show')
    }

    async function editChangePassword(e){
        //e.preventDefault()
        //base.$('#modalChangePass').modal('show')
    }

    async function changeInput(index){       
        /*var data_index = def_input_arr[index]
        var initSelected = data_index.is_selected
        for(var x in def_input_arr){
            def_input_arr[x].is_selected = false
        }

        def_input_arr[index].is_selected = !initSelected
        if(def_input_arr[index].is_selected){
            set_input_selected(def_input_arr[index].value)
        }

        base.update_array(def_input_arr, set_def_input_arr, data_index, index)*/
    }

    async function changeInputPass(value, index){
        /*if (register_form_arr[index].label != 'Username')
        {

        set_alert()
        set_is_button_disable(false)

        var data_index = register_form_arr[index]
        register_form_arr[index].value = value
        for(var x in register_form_arr){
            register_form_arr[x].is_error = false
        }
        base.update_array(register_form_arr, set_register_form_arr, data_index, index)

        }*/
    }

    async function set_alert(message='', type=''){
        set_alert_data({message : message, type : type})
    }


    async function saveDefInput(){
        
        /*var url = '/auth/change-default-input'
        var response = await base.request(url, 'put', {default_input : input_selected})
        if(response != null){
            if(response.status == 'success'){
                window.location.reload()
            }
        }*/
    }

    async function saveChangePass(){
        /*var data_register = {username : '', old_password : '', password : '', retype_password : ''}

        for(var x in register_form_arr){
            if(register_form_arr[x].value === ''){
                register_form_arr[x].is_error = true
                base.update_array(register_form_arr, set_register_form_arr, register_form_arr[x], x)                
                break
            }
            else{
                data_register[register_form_arr[x].type] = register_form_arr[x].value
            }
        }    

        //console.log(data_register)
        
        if(data_register.password !== data_register.retype_password){              
            set_error_message_modal("Retype Password does't match")
            base.$('#modalWrongProcess').modal('show')
        } else
        {

            var url = '/auth/change-pass'
            var response = await base.request(url, 'put', data_register)
            if(response != null){
                if(response.status == 'success'){
                    window.location.reload()
                } else
                {
                    set_error_message_modal(response.message)
                    base.$('#modalWrongProcess').modal('show')
                }
            }
        }*/
    }

    return(
        <div className='page_wrap'>
            <div className='row'>

                <div className='col-12'>
                    <PageHeader title={'Profile'} style={{fontSize : '1.5rem', fontWeight : '600'}} />
                </div>

                <div className='col-12 mt-5'>
                    <div className='row'>
                        {/*<div className='col-12'>
                            <div className='row'>
                                <div className='col-auto position-relative'>
                                    <img src={base.img_no_profile} style={{height : '5rem', width : '5rem', borderRadius : '5rem'}} />

                                    <input type="file" name="photo" accept="image/*" id="file_input" className="d-none" onChange={(event)=>changeImage(event)} /> 
                                    <div className='position-absolute p-2 d-flex align-items-center' style={{right : '.5rem', bottom : 0, borderRadius : '1.75rem', height : '1.75rem', width : '1.75rem', backgroundColor : '#95B8E2', cursor : 'pointer'}} onClick={()=>base.$('#file_input').trigger('click')}>
                                        <i class="bi bi-pencil-fill text-white" style={{fontSize : '.75rem'}}></i>
                                    </div>
                                    

                                </div>
                                <div className='col d-flex align-items-center'>
                                    <p className='m-0 text-capitalize' style={{fontSize : '1.25rem'}}>{user_data.CEMPLOYEE}</p>
                                </div>
                            </div>
                        </div>*/}

                        <div className='col-12 mt-4'>
                            <div className='row'>
                                <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                    {/*<p className='m-0 text-uppercase'>USERNAME</p>*/}
                                    <p className='m-0'>{user_data.CIDEMPLOYEE}</p>
                                </div>
                                <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                    <a href='#!' className='text-primary text-decoration-none' onClick={(e)=>editChangePassword(e)}>Change Password</a>
                                </div>
                                {/*<div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                    <a href='#!' className='text-primary text-decoration-none' onClick={(e)=>editDefaultInput(e)}>Edit Default Input</a>
                                </div>*/}
                                <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                    {/*<p className='m-0'>System Version v1.0</p>*/}
                                    <p className='m-0'>{document.title}</p>
                                    {
                                        user_data.CIDEMPLOYEE == "ADMIN" &&
                                                <>                                                    
                                                    <p className='m-0'>{base.base_url}</p>                                                    
                                                </>
                                    }
                                </div>
                                <div className='col-12 py-3'>
                                    <a href='#!' onClick={()=>logoutBtn()} className='text-danger text-decoration-none'>Sign Out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="modal fade" id="modalDefaultInput" tabIndex="-1" aria-labelledby="modalDefaultInputLabel" aria-hidden="true">
                <div className="modal-dialog p-4 pt-5 mt-5">
                    <div className="modal-content border-0 shadow-sm" style={{borderRadius : '.75rem'}}>
                        <div className="modal-body">
                            <div className={'row'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3>Default Input</h3>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                {
                                                    def_input_arr.map((data, index)=>(
                                                        <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" name="defInput" id={"defInput" + index} value={data.value} checked={data.is_selected ? true : false} onClick={()=>changeInput(index)} />
                                                                <label class="form-check-label" for={"defInput" + index}>{data.title}</label>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={()=>saveDefInput()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalChangePass" tabIndex="-1" aria-labelledby="modalChangePassLabel" aria-hidden="true">
                <div className="modal-dialog p-4 pt-5 mt-5">
                    <div className="modal-content border-0 shadow-sm" style={{borderRadius : '.75rem'}}>
                        <div className="modal-body">
                            <div className={'row'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3>Change Password</h3>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                            {
                                                register_form_arr.map((data, index)=>(
                                                    <div className={"col-12 form-group m-0" + (index != 0 ? ' mt-3' : '')} key={index}>
                                                        <FormInput
                                                            label={data.label}
                                                            type={data.type}
                                                            value={data.value}
                                                            changeInput={(value)=>changeInputPass(value, index)}
                                                            is_error={data.is_error}
                                                            error_message={(data.label) + ` can't be empty`}
                                                            />
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={()=>saveChangePass()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal} />
        </div>
        
    )
}