import db from '../../utils/db'
import Base from '../../utils/base'
import { useEffect } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'

export default function DexieTest(){
    var base = new Base()
    const view = useLiveQuery(
        () => db.leaveDetail.toArray()
    )
    useEffect(async ()=>{
        getDetail()
    }, [])

    async function getDetail(){
        var url = '/hris/test-dexie'
        var response = await base.request(url,'post',)
        
        var dataOut = response.data

        var getDB = view
        console.log(getDB)

        /*for(var x in dataOut){
            await db.leaveDetail.put({
                LDT_CID : dataOut[x].LDT_CID, 
                LHD_CID : dataOut[x].LHD_CID, 
                LDT_CIDEMPLOYEE : dataOut[x].LDT_CIDEMPLOYEE, 
                LVT_CID : dataOut[x].LVT_CID, 
                LDT_NDAYS : dataOut[x].LDT_NDAYS, 
                LDT_NVALIDDAYS : dataOut[x].LDT_NVALIDDAYS, 
                LDT_DSTARTDATE : dataOut[x].LDT_DSTARTDATE, 
                LDT_DFINISHDATE : dataOut[x].LDT_DFINISHDATE, 
                LVF_CID : dataOut[x].LVF_CID, 
                LDT_NSTATUS : dataOut[x].LDT_NSTATUS, 
                LDT_NDAYSLEFT : dataOut[x].LDT_NDAYSLEFT, 
                LVE_CIDCOMPANY : dataOut[x].LVE_CIDCOMPANY, 
                LVE_CIDPROJECT : dataOut[x].LVE_CIDPROJECT, 
                LVE_CIDDEPT : dataOut[x].LVE_CIDDEPT, 
                LVE_CIDPOSITION : dataOut[x].LVE_CIDPOSITION, 
                LVE_CIDGOLONGAN : dataOut[x].LVE_CIDGOLONGAN, 
                LDT_CCREATEDBY : dataOut[x].LDT_CCREATEDBY, 
                LDT_DTCREATED : dataOut[x].LDT_DTCREATED, 
                LDT_CUPDATEDBY : dataOut[x].LDT_CUPDATEDBY, 
                LDT_DTUPDATED : dataOut[x].LDT_DTUPDATED, 
                LDT_CSERVER : dataOut[x].LDT_CSERVER, 
                LVT_NFLAGKEHADIRAN : dataOut[x].LVT_NFLAGKEHADIRAN, 
                LVT_NFLAGHARIKERJA : dataOut[x].LVT_NFLAGHARIKERJA, 
                LDT_NSISACUTI : dataOut[x].LDT_NSISACUTI, 
                LDT_NSISAHUTANG : dataOut[x].LDT_NSISAHUTANG, 
                LDT_NMAXREQUEST : dataOut[x].LDT_NMAXREQUEST, 
                LDT_CIDSUBTITUTION : dataOut[x].LDT_CIDSUBTITUTION, 
                LVT_NFLAGPOTONGGAJI : dataOut[x].LVT_NFLAGPOTONGGAJI, 
                LVS_CID : dataOut[x].LVS_CID, 
                LDT_NFLAGCLOSIN : dataOut[x].LDT_NFLAGCLOSING,
            })
        }*/

        
        
    }

    return(
        <>
            <div className="col-12">
                <h3>Dexie Test</h3>
                {
                    view.map((data)=>(
                        <div className='col-12 custom-tabs-bg-solusiqu mb-3'>
                            <p>{data.LHD_CID}</p>
                            <p>{data.LDT_CID}</p>
                        </div>
                    ))
                }                
            </div>
        </>
    )
}