import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';

import ScanCamera from '../../components/scanCamera';
import PageHeader from '../../components/pageHeader';
import ErrorQRModal from '../../components/errorQr';
import WrongProcessModal from '../../components/modalWrongProcess';

export default function PackagingScanBarcode(){
    var base = new Base()

    const [scan_type, set_scan_type] = useState('palette')

    const [multiple_qr_result_arr, set_multiple_qr_result_arr] = useState([])
    const [packaging_qr_result, set_packaging_qr_result] = useState({PKC_CID : '', PDB_CDESCRIPTION : '', PDB_CBARCODE : ''})
    const [is_find_qr, set_is_find_qr] = useState(true)

    const [last_process, set_last_process] = useState('')

    const [error_type_modal, set_error_type_modal] = useState('')
    const [error_message_modal, set_error_message_modal] = useState('')

    const [production_line_id, set_production_line_id] = useState('')

    const [palette_item_arr, set_palette_item_arr] = useState([])

    const [palette_data, set_palette_data] = useState({PAL_CID : '', item : []})

    const [last_prod_line, set_last_prod_line] = useState('')

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        var production_line_id = await localStorage.getItem('production_line_id')
        set_production_line_id(production_line_id)

        base.$('#modalPaletteResult').on('hidden.bs.modal', function () {
            if(packaging_qr_result.PKC_CID === ''){
                set_is_find_qr(true)
            }
        })
        base.$('#modalProductResult').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })
        base.$('#error_modal').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })
        base.$('#modalToScanProduct').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })
        base.$('#modalErrorQR').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })
        base.$('#modalWrongProcess').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })

        get_prod_line()
        
    }, [])
    

    useEffect(()=>{
        base.$('#modalToScanProduct').modal('hide')
    }, [scan_type])

    async function get_prod_line(){
        var url = '/production/line/data?type=last_state'

        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                set_last_prod_line(response.data.PDL_CCODE)
            }
        }
    }
    
    async function submitBtn(type){
        if(type === 'palette'){
            if(query.get('type') === 'view'){
                console.log(palette_data)
                await localStorage.setItem('packing_input_palette', JSON.stringify(palette_data))
                window.location.href = '/packaging/input-id/check?type=' + query.get('type')
            }
            else{
                base.$('#modalPaletteResult').modal('hide')
                base.$('#modalToScanProduct').modal('show')
            }
        }
        else {
            var arr_detail = []
            for(var x in multiple_qr_result_arr){
                
                var data_detail = multiple_qr_result_arr[x]
                data_detail.amount = multiple_qr_result_arr[x].quantity
                data_detail.barcode = {id : multiple_qr_result_arr[x].PDB_CID}
                data_detail.status_scan = multiple_qr_result_arr[x].status_scan

                if(palette_data.PAL_CID !== ''){
                    data_detail.P2I_CID = multiple_qr_result_arr[x].P2I_CID
                }
                arr_detail.push(data_detail)
            }

            var data = {
                packing : packaging_qr_result,
                arr_detail : arr_detail
            }

            if(palette_data.PAL_CID !== ''){
                data.palette_data = palette_data
            }

            await localStorage.setItem('packagingData', JSON.stringify(data))

            window.location.href = '/packaging/confirm?type=' + query.get('type')
        }
    }

    async function resultScan(value){
        if(is_find_qr){

            if(navigator.onLine){
                set_is_find_qr(false)
                var barcode = value.split('#')[0]
                var url = '/' + (scan_type === 'palette' ? 'packing' : scan_type === 'product' ? 'tracking' : '') + '?barcode=' + barcode + (scan_type === 'product' ? '&for=palette&production_line_id=' + production_line_id : '')

                // if(query.get('type') === 'remove'){
                //     if(scan_type === 'palette'){
                //         url = '/palette?barcode=' + barcode
                //     }
                // }

                base.$('#preloader_modal').modal('show')
    
                var response = await base.request(url)
                if(response != null){
                    if(response.status == 'success'){
                        var data = response.data
                        var allow_product = 0

                        if(data != null){
                            if(scan_type === 'palette'){
                                set_packaging_qr_result(data)
    
                                base.hidePreloader()

                                base.$('#modalPaletteResult').modal('show')

                                if(data.PKC_NFLAGOBSOLETE == 1){
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Palette ID doesn't have any sales order`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                                else{
                                    if(data.palette != null){
                                        set_palette_data(data.palette)
                                        var item = data.palette.item
                                        for(var x in item){
                                            item[x].production_barcode.quantity = item[x].P2I_NQTY
                                            item[x].production_barcode.status_scan = 'old'
                                            item[x].production_barcode.P2I_CID = item[x].P2I_CID
    
                                            if(query.get('type') === 'add'){
                                                base.add_array(multiple_qr_result_arr, set_multiple_qr_result_arr, item[x].production_barcode)
                                            }
                                        }
                                    }
                                }

                                // if(data.allow_scan){
                                // }
                                // else{
                                //     base.hidePreloader()
                                //     set_error_message_modal(`The Current Pallete ID has already been Scanned`)
                                //     base.$('#modalWrongProcess').modal('show')
                                // }
                                
                            }
                            else if(scan_type === 'product'){
                                allow_product = 1

                                if(data.prod_line != null){
                                    var qty = 0
                                    if(data.previous_monitor == null){
                                        // allow_product = 0
                                        // set_is_find_qr(true)
                                        // base.hidePreloader()
    
                                        // // if(query.get('type') === 'add'){
                                        // //     // set_error_type_modal('packaging')
                                        // //     set_error_type_modal('error')
                                        // //     set_error_message_modal(`Product must enter ASSEMBLY before PACKING`)
                                        // // }
                                        // else if(query.get('type') === 'remove'){
                                        //     set_error_type_modal('error')
                                        //     set_error_message_modal('The Current Product ID is not in the packaging')
                                        // }
                                        // base.$('#modalWrongProcess').modal('show')

                                        if(data.last_monitor != null){
                                            qty = data.last_monitor.PDM_NQTY

                                            if(data.last_monitor.PDM_CPRODUCTIONLINE === 'WAREHOUSE'){
                                                allow_product = 0
                                                set_is_find_qr(true)
                                                base.hidePreloader()
                                                set_error_type_modal('error')
                                                set_error_message_modal(`Product is already in Warehouse`)
                                                base.$('#modalWrongProcess').modal('show')
                                            }
                                            else if(data.last_monitor.PDM_CPRODUCTIONLINE === 'SHIPMENT'){
                                                allow_product = 0
                                                set_is_find_qr(true)
                                                base.hidePreloader()
                                                set_error_type_modal('error')
                                                set_error_message_modal(`Product is already in Shipment`)
                                                base.$('#modalWrongProcess').modal('show')
                                            }
                                            if(data.last_monitor.PDM_CPRODUCTIONLINE === 'MATERIAL'){
                                                allow_product = 0
                                                set_is_find_qr(true)
                                                base.hidePreloader()
                                                set_error_type_modal('error')
                                                set_error_message_modal(`Product is already in Material`)
                                                base.$('#modalWrongProcess').modal('show')
                                            }
    
                                            if(data.last_monitor.PDM_CPRODUCTIONLINE !== last_prod_line){
                                                allow_product = 0
                                                set_is_find_qr(true)
                                                base.hidePreloader()
                                                set_last_process(data.last_monitor.PDM_CPRODUCTIONLINE)
                                                set_error_type_modal('packaging')
                                                base.$('#modalWrongProcess').modal('show')
                                            }
                                        }
                                    }
                                    else{
                                        qty = data.previous_monitor.PDM_NQTY
                                    }

                                    if(data.prod_line != null){
                                        if(data.prod_line.PDL_CCODE !== 'PACKING'){
                                            if(query.get('type') === 'remove'){
                                                allow_product = 0
                                                base.hidePreloader()
                                                set_error_type_modal('error')
                                                set_error_message_modal('The Current Product ID is not in the packaging')
                                                base.$('#modalWrongProcess').modal('show')
                                            }
                                        }
                                    }
    
                                    if(data.PDB_NFLAGNG == 1){
                                        allow_product = 0
                                        set_is_find_qr(true)
                                        set_error_type_modal('error')
                                        set_error_message_modal('Product status not pass in ' + data.last_monitor.PDM_CPRODUCTIONLINE)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
    
                                    if(data.PDB_NFLAGOBSOLETE == 1){
                                        allow_product = 0
                                        set_is_find_qr(true)
                                        set_error_type_modal('error')
                                        set_error_message_modal(`Product ID doesn't have any sales order`)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
    
                                    var palette_item = multiple_qr_result_arr
                                    
                                    // for(var x in palette_item){
                                    //     if(data.SOH_CID !== palette_item[x].SOH_CID){
                                    //         allow_product = 0
                                    //         base.hidePreloader()
                                    //         set_error_type_modal('error')
                                    //         set_error_message_modal(`SO doesn't match`)
                                    //         base.$('#modalWrongProcess').modal('show')
                                    //         break 
                                    //     }
                                    // }
    
                                    if(query.get('type') === 'remove'){
                                        for(var x in palette_item){
                                            if(palette_item[x].PDB_CID != data.PDB_CID){
                                                allow_product = 0
                                                set_is_find_qr(true)
                                                base.hidePreloader()
                                                set_error_message_modal(`The Current Product ID isn't in this palette`)
                                                base.$('#modalWrongProcess').modal('show')
                                                break
                                            }
                                        }
                                    }
                                    else{
                                        for(var x in palette_item){
                                            if(palette_item[x].PDB_CID === data.PDB_CID){
                                                allow_product = 0
                                                set_is_find_qr(true)
                                                base.hidePreloader()
                                                set_error_message_modal(`The Current Product ID has already been Scanned`)
                                                base.$('#modalWrongProcess').modal('show')
                                                break
                                            }
                                        }
                                    }
                                }
                                else{
                                    allow_product = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Product must enter ASSEMBLY before PACKING`)
                                    base.$('#modalWrongProcess').modal('show')
                                }

                            }

                            if(allow_product){
                                if(query.get('type') === 'add'){
                                    var new_flag = 1
                                    var data_arr = multiple_qr_result_arr
                                    for(var x in data_arr){
                                        if(data_arr[x].PDB_CID === data.PDB_CID){
                                            new_flag = 0
                                            if(data_arr[x].quantity < qty){
                                                data_arr[x].quantity += 1
                                            }
                                            data_arr[x].status_scan = 'new'
                                            base.update_array(multiple_qr_result_arr, set_multiple_qr_result_arr, data_arr[x], x)
                                        }
                                    }
                                    if(new_flag){
                                        data.quantity = qty
                                        data.status_scan = 'new'
                                        base.add_array(multiple_qr_result_arr, set_multiple_qr_result_arr, data)
                                    }
                                }
                                else{
                                    var item = palette_data.item
                                    for(var x in item){
                                        if(item[x].production_barcode.PDB_CID === data.PDB_CID){
                                            var data_item = data
                                            data_item.quantity = item[x].P2I_NQTY
                                            data_item.P2I_CID = item[x].P2I_CID
                                            base.add_array(multiple_qr_result_arr, set_multiple_qr_result_arr, data_item)
                                        }
                                    }
                                }
                                base.hidePreloader()
                                base.$('#modalProductResult').modal('show')
                            }
                        }
                        else{
                            errorGet()
                        }

                    }
                    else{
                        errorGet()
                    }
                }
                else{
                    errorGet()
                }
            }
            else{
                errorGet()
            }
        }
    }

    function errorGet(){
        set_is_find_qr(true)
        base.hidePreloader()
        base.$('#modalErrorQR').modal('show')
    }

    async function resultActionBtn(type, index){
        if(type === 'remove'){
            base.remove_array(multiple_qr_result_arr, set_multiple_qr_result_arr, index)
        }
    }

    async function modalToProduct(){
        set_scan_type('product')
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={(query.get('type') === 'add' ? 'Add Packaging Process' : query.get('type') === 'remove' ? 'Remove Packaging Item' : 'View Packing Item')} icon={(query.get('type') === 'remove' ? 'bi bi-dash-circle-fill' : null)} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <div className='col-12 mt-5'>
                        <p className='m-0 text-capitalize' style={{fontSize : '1.25rem'}}>Scan {scan_type} Barcode</p>
                    </div>

                    <div className='col-12 mt-3 p-0' style={{height : '100%'}}>
                        <ScanCamera resultScan={(value)=>resultScan(value)} scan_type={scan_type} type={query.get('type')} />
                    </div>

                </div>
            </div>

            <div className="modal fade" id="modalPaletteResult" tabIndex="-1" aria-labelledby="modalPaletteResultLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable m-0">
                    <div className="modal-content border-0 shadow-sm position-fixed" style={{bottom : 0, borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                        <div class="modal-header" style={{backgroundColor : '#E1EAEF', borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col p-0 d-flex align-items-center'>
                                        <p className='m-0'>Scan Result</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className={'row m-0'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <SingleScan type={'palette'} data={packaging_qr_result} />
                                            </div>
                                        </div>
                                        <div className='col-12 mt-4 p-0'>
                                            <button className='btn btn-lg btn-primary shadow-sm rounded w-100' onClick={()=>submitBtn('palette')}>Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalProductResult" tabIndex="-1" aria-labelledby="modalProductResultLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable m-0">
                    <div className="modal-content border-0 shadow-sm position-fixed" style={{bottom : 0, borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                        <div class="modal-header" style={{backgroundColor : '#E1EAEF', borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col p-0 d-flex align-items-center'>
                                        <p className='m-0'>Scan Result</p>
                                    </div>
                                    <div className='col p-0 d-flex align-items-center justify-content-end'>
                                        <div className='px-3 py-1' style={{backgroundColor : '#EAEAEA', border : '1px solid #347AB5', borderRadius : '5rem'}}>
                                            <p className='m-0' style={{color : '#347AB5', fontSize : '.75rem'}}>{packaging_qr_result.PKC_CID}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className={'row m-0'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <SingleScan type={'palette'} data={packaging_qr_result} />
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <MultipleScan type={'palette'} data_arr={multiple_qr_result_arr} actionBtn={(type, index)=>resultActionBtn(type, index)} viewFrom={'packaging'} />
                                        </div>

                                        <div className='col-12 p-0 mt-3'>
                                            <div className='py-2' style={{backgroundColor : '#F5F5F5'}}>
                                                <p className='m-0 text-center font-weight-bold' style={{color : '#777777'}}>Total {multiple_qr_result_arr.length} Product</p>
                                            </div>
                                        </div>
                                        <div className='col-12 mt-4 p-0'>
                                            <button className='btn btn-lg btn-primary shadow-sm rounded w-100' onClick={()=>submitBtn('product')}>Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalToScanProduct" tabIndex="-1" aria-labelledby="modalToScanProductLabel" aria-hidden="true">
                <div className="modal-dialog p-4 pt-5 mt-5">
                    <div className="modal-content border-0 shadow-sm" style={{borderRadius : '.75rem'}}>
                        <div className="modal-body">
                            <div className={'row'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 px-5'>
                                            <p className='m-0 text-center' style={{fontSize : '1.25rem'}}>Now Please Scan <b className='text-primary'>Product's</b> QR Code</p>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <img src={base.img_scan_qr} style={{height : '12rem'}} />
                                        </div>
                                        <div className='col-12 mt-3 text-center'>
                                            <button className='btn btn-lg btn-primary rounded w-100' onClick={()=>modalToProduct()}>OK</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ErrorQRModal />
            <WrongProcessModal process={last_process} type={error_type_modal} message={error_message_modal} />
        </>
    )
}