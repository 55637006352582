import { useEffect, useState, ReactDOM } from 'react';
import Base from '../../utils/base';

import FormInput from '../../components/FormInput';
import AuthHeader from '../../components/AuthHeader';

//import 'bootstrap-select/dist/js/bootstrap-select.min.js';
//import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function AuthLogin(){
	var base = new Base()

	const [login_form_arr, set_login_form_arr] = useState([{label : 'Username', input_type : 'text', type : 'username', value : '', is_error : false}, {label : 'Password', input_type : 'password', type : 'password', value : '', is_error : false}])

	const [is_button_disable, set_is_button_disable] = useState(false)
	const [alert_data, set_alert_data] = useState({message : '', type : ''})

	const [employee_data, set_employee_data] = useState([])	
	const [empdata, set_empdata] = useState([])	
	const [emp2data, set_emp2data] = useState([])	

	useEffect(async ()=>{
		//await localStorage.removeItem('token')
		var token = await localStorage.getItem('token')
		if (token) {
			window.location.href = '/'
		}
	}, [])

	async function changeInput(value, index){
		set_alert()
		set_is_button_disable(false)

		var data_index = login_form_arr[index]
		login_form_arr[index].value = value
		for(var x in login_form_arr){
			login_form_arr[x].is_error = false
		}
		base.update_array(login_form_arr, set_login_form_arr, data_index, index)
	}

	async function set_alert(message='', type=''){
		set_alert_data({message : message, type : type})
	}

	async function signIn(e){
		e.preventDefault()
		var flag = 1
		var data_login = {username : '', password : '', cidapp : ''}

		for(var x in login_form_arr){
			if(login_form_arr[x].value === ''){
				login_form_arr[x].is_error = true
				base.update_array(login_form_arr, set_login_form_arr, login_form_arr[x], x)
				flag = 0
				break
			}
			else{
				data_login[login_form_arr[x].type] = login_form_arr[x].value
			}
		}

		data_login.cidapp = base.getcidapp()
		
		if(flag){
			base.$('#preloader_modal').modal('show')
			set_is_button_disable(true)

			var url = '/auth/login'
			var response = await base.request(url, 'post', data_login)     
			//console.log(response)       

			if(response != null){
				if(response.status == 'success'){
					await localStorage.setItem('token', response.token)
					//await localStorage.setItem('cidemployee', response.cidemployee)  
					await localStorage.setItem('psn_cid', response.psn_cid)  

					console.log(response.user.CIDUSER)  
					var ciduser = response.user.CIDUSER
					//console.log(response.token)
					if (ciduser === 'ADMIN')
					{    
						base.cek_pwa_version()
						var url = '/hris/employeeactive'
						var response = await base.request(url)
										
		        if(response != null){
		            if(response.status == 'success'){

		            	var data = response.data
		            	//console.log(data)

		            	for(var x in data){
		            		data[x].CEMPLOYEENIP = data[x].CEMPLOYEE+' ('+data[x].CNIP+')'
		            	}
									set_employee_data(data)		
									set_empdata(data[0])	
									set_emp2data(data[0])

									//console.log(employee_data)
									//console.log(data)

							  base.hidePreloader()                    
							  e.preventDefault()

							  //$('.selectpicker').selectpicker('refresh');
								base.$('#modalLoginAs').modal('show')


							}
						}
					} 
					else					
					{
						set_alert('Login Success! Please Wait...', 'success')

						setTimeout(() => {
							window.location.href = '/'
						}, 750);
					}
				}
				else{
					set_alert(response.message, 'danger')
					setTimeout(() => {
						base.$('#preloader_modal').modal('hide')
					}, 750);
					set_is_button_disable(false)
				}
			}
			else{
				setTimeout(() => {
					base.$('#preloader_modal').modal('hide')
				}, 750);
				set_is_button_disable(false)
			}
		}
	}

	async function loginAsEmployee(){
			//var e = document.getElementById("cbxEmployee2");
			var value = empdata.CIDEMPLOYEE;
			if (value === undefined) 
			{
				value = employee_data[0].CIDEMPLOYEE
			}

			var url = '/util/encrypt?ctext='+value
      var response = await base.request(url)
      if(response != null){
        if(response.status == 'success'){			
			
					console.log(value)

		    	//await localStorage.setItem('cidemployee', value)      	
		    	await localStorage.setItem('psn_cid', response.data)

		    	set_alert('Login Success! Please Wait...', 'success')

					setTimeout(() => {
							window.location.href = '/'
						}, 750);    
				}
			}
    }

	
	return(

		<div className='page_wrap'>
			<div className='row m-0'>

				<div className='col-12'>
					<AuthHeader title={''} is_back_btn={false} />
				</div>
				
				<div className='col-12 mt-5'>
					<form className='row' onSubmit={(e)=>signIn(e)}>
						{
							alert_data.type != '' &&
							<div className='col-12'>
								<div className={"alert alert-" + (alert_data.type)} role="alert">
									{alert_data.message}
								</div>
							</div>
						}

						{
							login_form_arr.map((data, index)=>(
								<div className={"col-12 form-group m-0" + (index != 0 ? ' mt-3' : '')} key={index}>
									<FormInput
										label={data.label}
										type={data.type}
										value={data.value}
										changeInput={(value)=>changeInput(value, index)}
										is_error={data.is_error}
										error_message={(data.label) + ` can't be empty`} />
								</div>
							))
						}

						<div className='col-12 mt-2'>
							{/*<a href='/auth/forget-password' className='text-primary' style={{textDecoration : 'underline'}}>Lupa Kata Sandi</a>*/}
						</div>

						<div className='col-12 mt-4'>
							<button type='submit' className='btn btn-lg btn-primary w-100 shadow-sm rounded' onClick={(e)=>signIn(e)} disabled={is_button_disable}>Log In</button>
							{/*<a href='/auth/register' type='button' className='btn btn-lg btn-outline-primary shadow-sm rounded w-100 mt-3'>Registrasi</a>*/}
						</div>
					</form>
				</div>
			</div>
			<div className="modal fade" id="modalLoginAs" tabIndex="-1" aria-labelledby="modalLoginAsLabel" aria-hidden="true" data-toggle="modal" data-backdrop="static" data-keyboard="false">
				<div className="modal-dialog p-4 pt-5 mt-5">
					<div className="modal-content border-0 shadow-sm" style={{borderRadius : '.75rem'}}>
						<div className="modal-body">
							<div className='row'>
								<div className='col-12'>
									<div className='col-12'>
										<div>
											<h3>Login As Employee</h3>
										</div>
										<div className='form-group' id='cbxDropdownSearch'>
											{/*<select className='selectpicker form-control rounded' id='cbxEmployee' data-live-search='true' data-style='btn-link'>
												{
													employee_data.map((data, index)=>(
														<option key={data.CIDEMPLOYEE} value={data.CIDEMPLOYEE}>{data.CEMPLOYEE} ({data.CNIP})</option>)
													)
												}
											</select>		*/}
											
											<Autocomplete												  
												onChange={(event, value) => set_empdata(value)} // prints the selected value
												value={empdata}		
                      						    onInputChange={(event, inputValue) => set_emp2data(inputValue)} // prints the selected value                                                                
                      						    inputValue={emp2data}				
                      						    id="cbxEmployee2"
                      						    options={employee_data}
                      						    size='small'
                      						    getOptionLabel={(option) => option.CEMPLOYEENIP ? option.CEMPLOYEENIP : ""}
                      						    renderInput={(params) => <TextField {...params} label="Employee"/>}
                      						/>									
										</div>
										<div className='form-group'>
										
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-primary rounded" onClick={()=>loginAsEmployee()}>Login</button>
						</div>
					</div>
				</div>
			</div>
		</div>


	)
}