import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FormControl, MenuItem, Select, FormControlLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import ViewField from '../../../../components/viewField';

export default function FamilyForm({
    title,
    disabled,
    genderList,
    master_placebirth,
    familyStatus,
    ipt_emergency,
    handleEmergency,
    ipt_fullName,
    set_ipt_fullName,
    ipt_gender,
    handleGender,
    ipt_birthplace,
    set_ipt_birthplace,
    ipt_birthdate,
    set_ipt_birthdate,
    ipt_relationStatus,
    handleRelationStatus,
    ipt_bpjs,
    set_ipt_bpjs,
    ipt_address,
    set_ipt_address,
    ipt_telp,
    set_ipt_telp

}){
    const filter = createFilterOptions()

    return(
        <div className={'custom-tabs-bg-solusiqu p-3 mb-3'}>
            <FormControlLabel
                className="float-right"
                disabled={disabled}
                label='Emergency'
                labelPlacement="end"
                control={
                    <Checkbox
                        checked={ipt_emergency}
                        onChange={handleEmergency}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{"&.MuiCheckbox-root":{
                            padding:0
                        }}}
                    />
                }
            />
            <h5>{title}</h5>
            <ViewField
                inputClass='text-uppercase'
                data={ipt_fullName??''}
                type='text'
                useLabel={1}
                label='Full Name'
                onChange={set_ipt_fullName}
                //onChange={(e)=>set_ipt_fullName(e.target.value)}
                disabled={disabled}
                readonly={false}
                style={{fontSize:'0.9rem'}}
            />
            <div className="form-group row">
                <div className="col row">
                    <label className='custom-input-solusiqu col-6 col-form-label'>Gender</label>
                </div>
                <div className="col-12">
                    <FormControl 
                        required
                        disabled={disabled}
                        sx={{ width:'100%',
                            "& .MuiInputBase-input": {
                                fontSize: '0.9rem'
                            }
                        }}
                    >
                        <Select
                            size='small'
                            value={ipt_gender}
                            onChange={handleGender}
                        >
                            {
                            genderList?.map((data,index)=>(
                                <MenuItem key={index} value={data.IDGENDER}>{data.CGENDER}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='form-group row'>
                <div className="col row">
                    <label className='custom-input-solusiqu col-6 col-form-label'>Birthplace</label>
                </div>
                <div className="col-12">
                    <Autocomplete
                        disabled={disabled}
                        value={ipt_birthplace}
                        onChange={set_ipt_birthplace}
                        /*onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                set_ipt_birthplace({
                                TRIM: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                set_ipt_birthplace({
                                TRIM: newValue.inputValue,
                                });
                            } else {
                                set_ipt_birthplace(newValue);
                            }
                        }}*/
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.TRIM);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                inputValue,
                                TRIM: `Add "${inputValue.toUpperCase()}"`,
                                });
                            }
                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={master_placebirth}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.TRIM;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.TRIM}</li>}
                        sx={{ width:'100%',
                            "& .MuiInputBase-input": {
                                fontSize: '0.9rem',
                                textTransform:'uppercase'
                            }
                        }}
                        size="small"
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className="col row">
                    <label className='custom-input-solusiqu col-6 col-form-label'>Birth Date</label>
                </div>
                <div className="col-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            value={ipt_birthdate}
                            size='small'
                            disabled={disabled}
                            onChange={set_ipt_birthdate}
                            views={['year', 'month', 'day']}
                            //onChange={(newValue) => set_ipt_birthdate(newValue)}
                            sx={{width: '100%',
                                "& .MuiInputBase-input": {
                                    height: '0.35em',
                                    fontSize: '0.9rem'
                                }
                            }}
                        />
                    </LocalizationProvider>
                </div>
            </div>
            <div className="form-group row">
                <div className="col row">
                    <label className='custom-input-solusiqu col-6 col-form-label'>Relation Status</label>
                </div>
                <div className="col-12">
                    <FormControl 
                        disabled={disabled}
                        required
                        sx={{ width:'100%',
                            "& .MuiInputBase-input": {
                                fontSize: '0.9rem'
                            }
                        }}
                    >
                        <Select
                            size='small'
                            value={ipt_relationStatus}
                            onChange={handleRelationStatus}
                        >
                            {
                            familyStatus?.map((data,index)=>(
                                <MenuItem key={index} value={data.IDSTATUS}>{data.CFAMILYSTATUS}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
            <ViewField
                data={ipt_bpjs??''}
                type='text'
                useLabel={1}
                label='BPJS No.'
                onChange={set_ipt_bpjs}
                //onChange={(e)=>set_ipt_bpjs(e.target.value)}
                disabled={disabled}
                readonly={false}
                style={{fontSize:'0.9rem'}}
            />
            <ViewField
                inputClass='text-uppercase'
                data={ipt_address??''}
                type='text'
                useLabel={1}
                label='Address'
                onChange={set_ipt_address}
               // onChange={(e)=>set_ipt_address(e.target.value)}
                disabled={disabled}
                readonly={false}
                style={{fontSize:'0.9rem'}}
            />
            <ViewField
                data={ipt_telp??''}
                type='number'
                useLabel={1}
                label='Telp. No.'
                onChange={set_ipt_telp}
                //onChange={(e)=>set_ipt_telp(e.target.value)}
                disabled={disabled}
                readonly={false}
                style={{fontSize:'0.9rem'}}
            />
        </div>
    )
}