import Dexie from 'dexie';

var db = new Dexie('myDatabase');
db.version(1).stores({
  palette: '++id, PAL_CID, PKC_CCODE, PDB_CDESCRIPTION, quantity',
  product: '++id, PDB_CID, PDB_CBARCODE, PDB_CDESCRIPTION, quantity, action_type, data_type',   
  employee: '++id, CIDEMPLOYEE, CEMPLOYEE, CNIP',
  leaveDetail: '++id, LDT_CID, LHD_CID, LDT_CIDEMPLOYEE, LVT_CID, LDT_NDAYS, LDT_NVALIDDAYS, LDT_DSTARTDATE, LDT_DFINISHDATE, LVF_CID, LDT_NSTATUS, LDT_NDAYSLEFT, LVE_CIDCOMPANY, LVE_CIDPROJECT, LVE_CIDDEPT, LVE_CIDPOSITION, LVE_CIDGOLONGAN, LDT_CCREATEDBY, LDT_DTCREATED, LDT_CUPDATEDBY, LDT_DTUPDATED, LDT_CSERVER, LVT_NFLAGKEHADIRAN, LVT_NFLAGHARIKERJA, LDT_NSISACUTI, LDT_NSISAHUTANG, LDT_NMAXREQUEST, LDT_CIDSUBTITUTION, LVT_NFLAGPOTONGGAJI, LVS_CID, LDT_NFLAGCLOSING',
});

export default db