import { useEffect, useState } from 'react';
import Base from '../utils/base'

export default function ErrorModal(props){
    var base = new Base()

    return(
        <>
        <div className="modal fade" id="error_modal" tabIndex="-1" role="dialog" aria-labelledby="error_modal" aria-hidden="true">
            <div className="modal-dialog mt-5 p-3" role="document">
                <div className="modal-content border-0">
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <div className={"alert alert-danger m-0"} role="alert">{props.message != null ? props.message : 'Network Error'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}