import { useEffect, useState } from 'react';
import Base from '../utils/base'

export default function Preloader({title, is_back_btn, style}){
    var base = new Base()

    return(
        <>
        <div className="modal fade" id="preloader_modal" tabIndex="-1" role="dialog" aria-labelledby="preloader_modal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content border-0 bg-transparent">
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='bg-white p-4 rounded'>
                            <div className="spinner-grow text-danger m-2" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-warning m-2" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-success m-2" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}