import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import FormInput from '../../components/FormInput';
import PaletteList from '../../components/paletteList';

export default function WarehouseConfirmData(){
    var base = new Base()

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [action_btn_arr] = useState([
        {title : 'Rescan Palette/Product', icon : 'bi bi-upc-scan', btn_type : 'btn-outline-primary', type : 'scan'},
        {title : 'Confirmed ' + (query.get('type') === 'back_to_packing' ? 'Back to Packing' : query.get('type')), icon : '', btn_type : 'btn-primary', type : 'confirm'},
    ])

    const [multiple_qr_result_arr, set_multiple_qr_result_arr] = useState([])
    const [is_disabled, set_is_disabled] = useState(false)


    useEffect(async ()=>{
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        var data_warehouse = await localStorage.getItem('warehouseData')
        data_warehouse = JSON.parse(data_warehouse)

        set_multiple_qr_result_arr(data_warehouse)

    }, [])

    async function actionBtn(type){
        if(type === 'scan'){
            var url = '/warehouse/scan-barcode?type=' + query.get('type')
            if(query.get('is_temp') != null){
                if(query.get('is_temp') == 1){
                    url += '&is_temp=1'
                }
            }
            window.location.replace(url)
        }
        else if(type === 'confirm'){

            if(navigator.onLine){
            
                if(multiple_qr_result_arr.length > 0){
                    base.$('#preloader_modal').modal('show')
                    var url = '/warehouse'
                    var arr_palette = []
                    for(var x in multiple_qr_result_arr){
                        arr_palette.push({
                            palette : {id : multiple_qr_result_arr[x].PAL_CID}
                        })
                    }
                    
                    var data = {
                        type : query.get('type'),
                        arr_palette : arr_palette
                    }
    
                    var response = await base.request(url, 'post', data)
                    if(response != null){
                        if(response.status == 'success'){
                            window.location.href = '/success'
                        }
                        else{
                            errorPost()
                        }
                    }
                    else{
                        errorPost()
                    }
                }
        
            }
            else{
                errorPost()
            }
        }
    }

    function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
        set_is_disabled(false)
    }

    async function resultActionBtn(type, index){
        if(type === 'remove'){
            base.remove_array(multiple_qr_result_arr, set_multiple_qr_result_arr, index)
        }
    }

    async function actionBtnTable(type, index){
        if(type === 'view'){
            var qr_result_temp = multiple_qr_result_arr
            await localStorage.setItem('qr_result_temp', JSON.stringify(qr_result_temp))
            window.location.href = '/inventory/detail-palette?id=' + multiple_qr_result_arr[index].PAL_CID
        }
        else if(type === 'remove'){
            base.remove_array(multiple_qr_result_arr, set_multiple_qr_result_arr, index)
        }
    }


    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={'Confirm Data'} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <div className='col-12 mt-3'>
                        <div className='row m-0'>
                            <div className='col-12 mt-3'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-4 px-0 d-flex align-items-center'>
                                                <p className='m-0'>Process</p>
                                            </div>
                                            <div className='col-8'>
                                                <FormInput
                                                    type={'text'}
                                                    value={(query.get('type') === 'back_to_packing' ? 'Back to Packing' : query.get('type'))}
                                                    readOnly
                                                    style={{textTransform : 'uppercase'}}
                                                    changeInput={(value)=>console.log(value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <PaletteList data_arr={multiple_qr_result_arr} actionBtn={(type, index)=>actionBtnTable(type, index)} />
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 p-0 mt-3'>
                                <div className='py-2' style={{backgroundColor : '#F5F5F5'}}>
                                    <p className='m-0 text-center font-weight-bold' style={{color : '#777777'}}>Total {multiple_qr_result_arr.length} Palette</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className='position-fixed w-100 py-2 shadow-lg' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                <div className='row m-0'>
                    <div className='col-12'>
                    {
                        action_btn_arr.map((data, index)=>(
                            <button className={'btn btn-lg w-100 rounded text-capitalize ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={()=>actionBtn(data.type)} key={index} disabled={is_disabled}>
                                {
                                    data.icon !== '' &&
                                    <i className={data.icon + ' mr-2'}></i>
                                }
                                {data.title}
                            </button>
                        ))
                    }
                    </div>
                </div>
            </div>
        </>
    )
}