import { useState, useEffect } from "react"
import Base from '../../../utils/base';
import PageHeader from '../../../components/pageHeader';
import WrongProcessModal from '../../../components/modalWrongProcess';

import dayjs from 'dayjs';
import FamilyForm from "./components/familyForm";

export default function FamilyRevision(){
    var base = new Base()

    const [v_readOnly, set_v_readOnly] = useState(false)
    const [error_message_modal, set_error_message_modal] = useState('')

    const [ipt_emuid, set_ipt_emuid] = useState('')
////                         ////                    ////
    const [ipt_cidfamily1, set_ipt_cidfamily1] = useState('')
    const [ipt_relationStatus1, set_ipt_relationStatus1] = useState('')
    const handleRelationStatus1 = (event) => {
        set_ipt_relationStatus1(event.target.value)
    }
    const [ipt_fullName1, set_ipt_fullName1] = useState('')
    const [ipt_gender1, set_ipt_gender1] = useState('')
    const handleGender1 = (event) => {
        set_ipt_gender1(event.target.value)
    }
    const [ipt_birthplace1, set_ipt_birthplace1] = useState('')
    const [ipt_bpjs1, set_ipt_bpjs1] = useState('')
    const [ipt_address1, set_ipt_address1] = useState('')
    const [ipt_telp1, set_ipt_telp1] = useState('')
    const [ipt_birthdate1, set_ipt_birthdate1] = useState('')
    const [ipt_emergency1, set_ipt_emergency1] = useState(false)
    const handleEmergency1 = (event) =>{
        set_ipt_emergency1(event.target.checked)
    }
////                        ////                    ////
    const [ipt_cidfamily2, set_ipt_cidfamily2] = useState('')
    const [ipt_relationStatus2, set_ipt_relationStatus2] = useState('')
    const handleRelationStatus2 = (event) => {
        set_ipt_relationStatus2(event.target.value)
    }
    const [ipt_fullName2, set_ipt_fullName2] = useState('')
    const [ipt_gender2, set_ipt_gender2] = useState('')
    const handleGender2 = (event) => {
        set_ipt_gender2(event.target.value)
    }
    const [ipt_birthplace2, set_ipt_birthplace2] = useState('')
    const [ipt_bpjs2, set_ipt_bpjs2] = useState('')
    const [ipt_address2, set_ipt_address2] = useState('')
    const [ipt_telp2, set_ipt_telp2] = useState('')
    const [ipt_birthdate2, set_ipt_birthdate2] = useState('')
    const [ipt_emergency2, set_ipt_emergency2] = useState(false)
    const handleEmergency2 = (event) =>{
        set_ipt_emergency2(event.target.checked)
    }
////                        ////                    ////
    const [ipt_cidfamily3, set_ipt_cidfamily3] = useState('')
    const [ipt_relationStatus3, set_ipt_relationStatus3] = useState('')
    const handleRelationStatus3 = (event) => {
        set_ipt_relationStatus3(event.target.value)
    }
    const [ipt_fullName3, set_ipt_fullName3] = useState('')
    const [ipt_gender3, set_ipt_gender3] = useState('')
    const handleGender3 = (event) => {
        set_ipt_gender3(event.target.value)
    }
    const [ipt_birthplace3, set_ipt_birthplace3] = useState('')
    const [ipt_bpjs3, set_ipt_bpjs3] = useState('')
    const [ipt_address3, set_ipt_address3] = useState('')
    const [ipt_telp3, set_ipt_telp3] = useState('')
    const [ipt_birthdate3, set_ipt_birthdate3] = useState('')
    const [ipt_emergency3, set_ipt_emergency3] = useState(false)
    const handleEmergency3 = (event) =>{
        set_ipt_emergency3(event.target.checked)
    }
////                        ////                    ////
    const [ipt_cidfamily4, set_ipt_cidfamily4] = useState('')
    const [ipt_relationStatus4, set_ipt_relationStatus4] = useState('')
    const handleRelationStatus4 = (event) => {
        set_ipt_relationStatus4(event.target.value)
    }
    const [ipt_fullName4, set_ipt_fullName4] = useState('')
    const [ipt_gender4, set_ipt_gender4] = useState('')
    const handleGender4 = (event) => {
        set_ipt_gender4(event.target.value)
    }
    const [ipt_birthplace4, set_ipt_birthplace4] = useState('')
    const [ipt_bpjs4, set_ipt_bpjs4] = useState('')
    const [ipt_address4, set_ipt_address4] = useState('')
    const [ipt_telp4, set_ipt_telp4] = useState('')
    const [ipt_birthdate4, set_ipt_birthdate4] = useState('')
    const [ipt_emergency4, set_ipt_emergency4] = useState(false)
    const handleEmergency4 = (event) =>{
        set_ipt_emergency4(event.target.checked)
    }
////                        ////                    ////
    const [master_placebirth, set_master_placebirth] = useState([])

    const familyStatus = [
        {IDSTATUS:1, CFAMILYSTATUS:'SUAMI/ISTRI'},
        {IDSTATUS:2, CFAMILYSTATUS:'ANAK'},
        {IDSTATUS:3, CFAMILYSTATUS:'AYAH'},
        {IDSTATUS:4, CFAMILYSTATUS:'IBU'},
        {IDSTATUS:5, CFAMILYSTATUS:'SAUDARA'},
        {IDSTATUS:6, CFAMILYSTATUS:'FAMILY'}
    ]
    const genderList = [
        {IDGENDER:1, CGENDER:'MALE'},
        {IDGENDER:2, CGENDER:'FEMALE'}
    ]
//////

    useEffect(async ()=>{
        // await localStorage.clear()
        await base.cek_pwa_version()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                getData()
            }
        }
    }, [])

    async function backToParent() {
		// await localStorage.removeItem('packing_input_product')
		// DB.product.clear();
		window.history.back()
	}

    async function getData(){
        base.$('#preloader_modal').modal('show')
        var cidemployee = await localStorage.getItem('psn_cid')

        ////master
        var url_birthplace = '/hris/loadplaceofbirth'
        var response_birthplace = await base.request(url_birthplace,'post')
        response_birthplace=response_birthplace.loadplaceofbirth

        set_master_placebirth(response_birthplace)
        ////

        ////trans family data
        var url_trans = '/hris/employeeupdateview'
        var dataSubmit = {
            cidemployee,
            npage:2
        }
        var response_trans = await base.request(url_trans,'post',dataSubmit)
        if (response_trans.status=='success') {
            var data = response_trans.employeeupdateview
            
            for(let x in data){
                if (data[x]==null) {
                    data[x]=''
                }
            }

            if (data.EMU_CID) {
                set_ipt_emuid(data.EMU_CID)
            }
            
            set_ipt_cidfamily1(data.EMU_CIDFAMILY1)
            set_ipt_fullName1(data.EMU_CNAME1)
            set_ipt_gender1(data.EMU_NSEX1)
            set_ipt_birthplace1(data.EMU_CPLACEOFBIRTH1)
            set_ipt_birthdate1(dayjs(data.EMU_DBIRTHDAY1))
            set_ipt_relationStatus1(data.EMU_NFAMILYSTATUS1)
            set_ipt_bpjs1(data.EMU_CBPJSJK1)
            set_ipt_address1(data.EMU_FAMILYCADDRESS1)
            set_ipt_telp1(data.EMU_CTELP1)
            if (data.EMU_NFLAGEMERGENCY1==1) {
                set_ipt_emergency1(true)
            }else{
                set_ipt_emergency1(false)
            }
            ///////////////////////////////
            set_ipt_cidfamily2(data.EMU_CIDFAMILY2)
            set_ipt_fullName2(data.EMU_CNAME2)
            set_ipt_gender2(data.EMU_NSEX2)
            set_ipt_birthplace2(data.EMU_CPLACEOFBIRTH2)
            set_ipt_birthdate2(dayjs(data.EMU_DBIRTHDAY2))
            set_ipt_relationStatus2(data.EMU_NFAMILYSTATUS2)
            set_ipt_bpjs2(data.EMU_CBPJSJK2)
            set_ipt_address2(data.EMU_FAMILYCADDRESS2)
            set_ipt_telp2(data.EMU_CTELP2)
            if (data.EMU_NFLAGEMERGENCY2==1) {
                set_ipt_emergency2(true)
            }else{
                set_ipt_emergency2(false)
            }
            ///////////////////////////////
            set_ipt_cidfamily3(data.EMU_CIDFAMILY3)
            set_ipt_fullName3(data.EMU_CNAME3)
            set_ipt_gender3(data.EMU_NSEX3)
            set_ipt_birthplace3(data.EMU_CPLACEOFBIRTH3)
            set_ipt_birthdate3(dayjs(data.EMU_DBIRTHDAY3))
            set_ipt_relationStatus3(data.EMU_NFAMILYSTATUS3)
            set_ipt_bpjs3(data.EMU_CBPJSJK3)
            set_ipt_address3(data.EMU_FAMILYCADDRESS3)
            set_ipt_telp3(data.EMU_CTELP3)
            if (data.EMU_NFLAGEMERGENCY3==1) {
                set_ipt_emergency3(true)
            }else{
                set_ipt_emergency3(false)
            }
            ///////////////////////////////
            set_ipt_cidfamily4(data.EMU_CIDFAMILY4)
            set_ipt_fullName4(data.EMU_CNAME4)
            set_ipt_gender4(data.EMU_NSEX4)
            set_ipt_birthplace4(data.EMU_CPLACEOFBIRTH4)
            set_ipt_birthdate4(dayjs(data.EMU_DBIRTHDAY4))
            set_ipt_relationStatus4(data.EMU_NFAMILYSTATUS4)
            set_ipt_bpjs4(data.EMU_CBPJSJK4)
            set_ipt_address4(data.EMU_FAMILYCADDRESS4)
            set_ipt_telp4(data.EMU_CTELP4)
            if (data.EMU_NFLAGEMERGENCY4==1) {
                set_ipt_emergency4(true)
            }else{
                set_ipt_emergency4(false)
            }

            console.log(data)
        }
        ////

     	base.hidePreloader()
    }

    async function actSubmit(){
        var dataSubmit = {
            cidemployee : await localStorage.getItem('psn_cid'),
            npage:2,
            emu_cid : ipt_emuid,

            emu_cidfamily1 : ipt_cidfamily1,
            emu_nfamilystatus1 : ipt_relationStatus1,
            emu_cname1 : ipt_fullName1?ipt_fullName1.toUpperCase():null,
            emu_nsex1 : ipt_gender1,
            emu_cplaceofbirth1 : ipt_birthplace1.TRIM?ipt_birthplace1.TRIM.toUpperCase():ipt_birthplace1.toUpperCase(),
            emu_dbirthday1 : ipt_birthdate1.$y?base.formatDate(ipt_birthdate1.$y,ipt_birthdate1.$M+1,ipt_birthdate1.$D):null,
            emu_familycaddress1 : ipt_address1.toUpperCase(),
            emu_ctelp1 : ipt_telp1,
            emu_cbpjsjk1 : ipt_bpjs1,
            emu_nflagemergency1 : ipt_emergency1?1:0,
            /////////
            emu_cidfamily2 : ipt_cidfamily2,
            emu_nfamilystatus2 : ipt_relationStatus2,
            emu_cname2 : ipt_fullName2?ipt_fullName2.toUpperCase():null,
            emu_nsex2 : ipt_gender2,
            emu_cplaceofbirth2 : ipt_birthplace2.TRIM?ipt_birthplace2.TRIM.toUpperCase():ipt_birthplace2.toUpperCase(),
            emu_dbirthday2 : ipt_birthdate2.$y?base.formatDate(ipt_birthdate2.$y,ipt_birthdate2.$M+1,ipt_birthdate2.$D):null,
            emu_familycaddress2 : ipt_address2.toUpperCase(),
            emu_ctelp2 : ipt_telp2,
            emu_cbpjsjk2 : ipt_bpjs2,
            emu_nflagemergency2 : ipt_emergency2?1:0,
            //////////
            emu_cidfamily3 : ipt_cidfamily3,
            emu_nfamilystatus3 : ipt_relationStatus3,
            emu_cname3 : ipt_fullName3?ipt_fullName3.toUpperCase():null,
            emu_nsex3 : ipt_gender3,
            emu_cplaceofbirth3 : ipt_birthplace3.TRIM?ipt_birthplace3.TRIM.toUpperCase():ipt_birthplace3.toUpperCase(),
            emu_dbirthday3 : ipt_birthdate3.$y?base.formatDate(ipt_birthdate3.$y,ipt_birthdate3.$M+1,ipt_birthdate3.$D):null,
            emu_familycaddress3 : ipt_address3.toUpperCase(),
            emu_ctelp3 : ipt_telp3,
            emu_cbpjsjk3 : ipt_bpjs3,
            emu_nflagemergency3 : ipt_emergency3?1:0,
            ////////////
            emu_cidfamily4 : ipt_cidfamily4,
            emu_nfamilystatus4 : ipt_relationStatus4,
            emu_cname4 : ipt_fullName4?ipt_fullName4.toUpperCase():null,
            emu_nsex4 : ipt_gender4,
            emu_cplaceofbirth4 : ipt_birthplace4.TRIM?ipt_birthplace4.TRIM.toUpperCase():ipt_birthplace4.toUpperCase(),
            emu_dbirthday4 : ipt_birthdate4.$y?base.formatDate(ipt_birthdate4.$y,ipt_birthdate4.$M+1,ipt_birthdate4.$D):null,
            emu_familycaddress4 : ipt_address4.toUpperCase(),
            emu_ctelp4 : ipt_telp4,
            emu_cbpjsjk4 : ipt_bpjs4,
            emu_nflagemergency4 : ipt_emergency4?1:0
        }
        set_v_readOnly(true)
        var url = '/hris/employeeupdatepost'
        var response = await base.request(url,'post',dataSubmit)
        if (response.status=='success') {
            window.location.replace('/success')
        }else{
            base.hidePreloader()
            set_error_message_modal('failed to send data')
            base.$('#modalWrongProcess').modal('show')
        }
        console.log(dataSubmit)
    }

    return(
        <div className='page_wrap' style={{marginTop:60}}>
            <div className='row'>
                <div className='fixed-top page_wrap shadow-md' style={{backgroundColor: '#FFF', height:100}}>
                    <div className='pt-5'>
                        <PageHeader title='Family Data' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} customBack={true} backToPage={() => backToParent()}/>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <FamilyForm
                        title='Family 1'
                        disabled={v_readOnly}
                        genderList={genderList}
                        master_placebirth={master_placebirth}
                        familyStatus={familyStatus}
                        ipt_emergency={ipt_emergency1}
                        handleEmergency={handleEmergency1}
                        ipt_fullName={ipt_fullName1}
                        set_ipt_fullName={(e)=>set_ipt_fullName1(e.target.value)}
                        ipt_gender={ipt_gender1}
                        handleGender={handleGender1}
                        ipt_birthplace={ipt_birthplace1}
                        set_ipt_birthplace={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                set_ipt_birthplace1({
                                TRIM: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                set_ipt_birthplace1({
                                TRIM: newValue.inputValue,
                                });
                            } else {
                                set_ipt_birthplace1(newValue);
                            }
                        }}
                        ipt_birthdate={ipt_birthdate1}
                        set_ipt_birthdate={(newValue) => set_ipt_birthdate1(newValue)}
                        ipt_relationStatus={ipt_relationStatus1}
                        handleRelationStatus={handleRelationStatus1}
                        ipt_bpjs={ipt_bpjs1}
                        set_ipt_bpjs={(e)=>set_ipt_bpjs1(e.target.value)}
                        ipt_address={ipt_address1}
                        set_ipt_address={(e)=>set_ipt_address1(e.target.value)}
                        ipt_telp={ipt_telp1}
                        set_ipt_telp={(e)=>set_ipt_telp1(e.target.value)}
                    />
                    <FamilyForm
                        title='Family 2'
                        disabled={v_readOnly}
                        genderList={genderList}
                        master_placebirth={master_placebirth}
                        familyStatus={familyStatus}
                        ipt_emergency={ipt_emergency2}
                        handleEmergency={handleEmergency2}
                        ipt_fullName={ipt_fullName2}
                        set_ipt_fullName={(e)=>set_ipt_fullName2(e.target.value)}
                        ipt_gender={ipt_gender2}
                        handleGender={handleGender2}
                        ipt_birthplace={ipt_birthplace2}
                        set_ipt_birthplace={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                set_ipt_birthplace2({
                                TRIM: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                set_ipt_birthplace2({
                                TRIM: newValue.inputValue,
                                });
                            } else {
                                set_ipt_birthplace2(newValue);
                            }
                        }}
                        ipt_birthdate={ipt_birthdate2}
                        set_ipt_birthdate={(newValue) => set_ipt_birthdate2(newValue)}
                        ipt_relationStatus={ipt_relationStatus2}
                        handleRelationStatus={handleRelationStatus2}
                        ipt_bpjs={ipt_bpjs2}
                        set_ipt_bpjs={(e)=>set_ipt_bpjs2(e.target.value)}
                        ipt_address={ipt_address2}
                        set_ipt_address={(e)=>set_ipt_address2(e.target.value)}
                        ipt_telp={ipt_telp2}
                        set_ipt_telp={(e)=>set_ipt_telp2(e.target.value)}
                    />
                    <FamilyForm
                        title='Family 3'
                        disabled={v_readOnly}
                        genderList={genderList}
                        master_placebirth={master_placebirth}
                        familyStatus={familyStatus}
                        ipt_emergency={ipt_emergency3}
                        handleEmergency={handleEmergency3}
                        ipt_fullName={ipt_fullName3}
                        set_ipt_fullName={(e)=>set_ipt_fullName3(e.target.value)}
                        ipt_gender={ipt_gender3}
                        handleGender={handleGender3}
                        ipt_birthplace={ipt_birthplace3}
                        set_ipt_birthplace={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                set_ipt_birthplace3({
                                TRIM: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                set_ipt_birthplace3({
                                TRIM: newValue.inputValue,
                                });
                            } else {
                                set_ipt_birthplace3(newValue);
                            }
                        }}
                        ipt_birthdate={ipt_birthdate3}
                        set_ipt_birthdate={(newValue) => set_ipt_birthdate3(newValue)}
                        ipt_relationStatus={ipt_relationStatus3}
                        handleRelationStatus={handleRelationStatus3}
                        ipt_bpjs={ipt_bpjs3}
                        set_ipt_bpjs={(e)=>set_ipt_bpjs3(e.target.value)}
                        ipt_address={ipt_address3}
                        set_ipt_address={(e)=>set_ipt_address3(e.target.value)}
                        ipt_telp={ipt_telp3}
                        set_ipt_telp={(e)=>set_ipt_telp3(e.target.value)}
                    />
                    <FamilyForm
                        title='Family 4'
                        disabled={v_readOnly}
                        genderList={genderList}
                        master_placebirth={master_placebirth}
                        familyStatus={familyStatus}
                        ipt_emergency={ipt_emergency4}
                        handleEmergency={handleEmergency4}
                        ipt_fullName={ipt_fullName4}
                        set_ipt_fullName={(e)=>set_ipt_fullName4(e.target.value)}
                        ipt_gender={ipt_gender4}
                        handleGender={handleGender4}
                        ipt_birthplace={ipt_birthplace4}
                        set_ipt_birthplace={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                set_ipt_birthplace4({
                                TRIM: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                set_ipt_birthplace4({
                                TRIM: newValue.inputValue,
                                });
                            } else {
                                set_ipt_birthplace4(newValue);
                            }
                        }}
                        ipt_birthdate={ipt_birthdate4}
                        set_ipt_birthdate={(newValue) => set_ipt_birthdate4(newValue)}
                        ipt_relationStatus={ipt_relationStatus4}
                        handleRelationStatus={handleRelationStatus4}
                        ipt_bpjs={ipt_bpjs4}
                        set_ipt_bpjs={(e)=>set_ipt_bpjs4(e.target.value)}
                        ipt_address={ipt_address4}
                        set_ipt_address={(e)=>set_ipt_address4(e.target.value)}
                        ipt_telp={ipt_telp4}
                        set_ipt_telp={(e)=>set_ipt_telp4(e.target.value)}
                    />
                    <div className={'mt-2 float-right'}>
                        <button className='btn btn-success rounded' 
                                disabled={v_readOnly}
                                onClick={actSubmit}
                        >Save
                        </button>
                    </div>
                </div>
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal}/>
        </div>
    )
}