import * as React from 'react';
import { useEffect, useState } from 'react';
import Base from '../../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import PageHeader from "../../../components/pageHeader"
import { TextareaAutosize } from '@mui/material';

import WrongProcessModal from '../../../components/modalWrongProcess';

export default function ViewLeave(){

    var base = new Base()    

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()
    const [error_message_modal, set_error_message_modal] = useState('')

    const [cemployee, set_cemployee] = useState('')
    const [leaveType, set_leaveType] = useState([''])
    const [viewData, set_viewData] = useState([''])
    const [viewStatus, set_viewStatus] = useState(query.get('type'))
    const [readonlyValue, set_readonlyValue] = useState(false)
    const [viewAttachment, set_viewAttachment] = useState('')

    const [getLeave, setLeave] = React.useState('');  
    const handleChangeLeaveType = (event) => {
        setLeave(event.target.value);
    };
    const onNotesChange = (event) =>
    {
        set_leaveNotes(event.target.value)
    }
    const [leaveStart, set_leaveStart] = useState('')
    const [leaveFinish, set_leaveFinish] = useState('')
    const [leaveNotes, set_leaveNotes] = useState('')
    const [lia_cid, set_lia_cid] = useState('')
    const [flagApprove, set_flagApprove] = useState('') 
    const [flagEdit, set_flagEdit] = useState(0)

    const [selectedFile, set_selectedFile] = useState('')

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                getLeaveReason()
                getViewData()

                if(viewStatus.startsWith('view')){
                    set_readonlyValue(true)
                }else if(viewStatus=='create'){
                    set_readonlyValue(false)
                }
            }
        }
    }, [])

    async function getLeaveReason(){
        var url = '/hris/loadleavetype'//?cidemployee='+cidemployee+'&nmonth='+(iptMonth.$M+1)+'&nyear='+iptMonth.$y
        var response = await base.request(url,'post')
        //console.log(url)
        if(response != null){
            if(response.status == 'success'){                

                var viewType = response.loadleavetype
                
                base.hidePreloader()
                set_leaveType(viewType)

                console.log(viewType)     
            }
        }
    }

    async function getViewData(){
        base.$('#preloader_modal').modal('show')
        var id = query.get('id')
        var dataurl = {
            id
        }
        var url = '/hris/leaveview'
        var response = await base.request(url,'post',dataurl)
        //console.log(url)
        if(response != null){
            if(response.status == 'success'){                

                var dataView = response.leaveview
                for(let x in dataView){
                    if (dataView[x]==null) {
                        dataView[x]=''
                    }
                }
                base.hidePreloader()
                set_viewData(dataView)
                if(viewStatus.startsWith('view')){
                    setLeave(dataView.LVT_CID)
                    set_leaveStart(dayjs(dataView.LDT_DSTARTDATE))
                    set_leaveFinish(dayjs(dataView.LDT_DFINISHDATE))
                    set_leaveNotes(dataView.LHD_CNOTES)
					set_selectedFile({attachmentView: dataView.LHD_CFILENAME})
                    set_lia_cid(dataView.LIA_CID)
                    set_flagApprove(dataView.LHD_NSTATUS)
                    set_cemployee({CEMPLOYEE: dataView.CEMPLOYEE, CNIP: dataView.CNIP, LEAVETYPE: dataView.LVT_CDESCRIPTION})
                }
                
                console.log(dataView)     
            }
        }
        base.hidePreloader()
    }

    async function getAttachment(){
        base.$('#preloader_modal').modal('show')
        var url_attachment = '/hris/leaveattachment'
        var CIDLIA = lia_cid
        var v_cidlia ={CIDLIA}

        var response_attachment = await base.request(url_attachment,'post',v_cidlia)
        if (response_attachment != null) {
            if(response_attachment.status=='success'){
                var blob = response_attachment.attachment
                //var uri = `data:image/jpeg;base64,${blob}`
                var uri = `data:image/jpeg;base64,${blob}`
                set_viewAttachment(uri)

                console.log(uri)
                console.log(v_cidlia)
            }
        }

        base.hidePreloader()
    }

    async function editClicked()
    {
        set_readonlyValue(prevState => !prevState)
        set_flagEdit(1)
        console.log(readonlyValue)
    }

    async function replaceAttachment(){
        base.$('#preloader_modal').modal('show')
        
        var url = '/hris/leaveattachment_replace'
        let id = query.get('id')??''
        var dataSubmit = {
            lia_cid : lia_cid,
            lampiran : selectedFile.attachmentReplace,
            cidreff : id
        }
        const data = new FormData()
        data.append('lia_cid',lia_cid)     
        data.append('lampiran',selectedFile.attachmentReplace)
        data.append('cidreff',id)   

        var response = await base.request(url,'postmultipart',data)
        if (response.status=='success') {
            window.location.replace('/success?bb=1&id=' + id)
        }

        console.log(dataSubmit)
        base.hidePreloader()
    }

    async function saveClicked(){
        var dataSubmit = null        

        dataSubmit = {
            cidemployee : await localStorage.getItem('psn_cid'),
            lhd_cidgroup : query.get('id')?query.get('id'):'',
            lvt_cid : getLeave,
            lhd_dstart : base.formatDate(leaveStart.$y,(leaveStart.$M + 1),leaveStart.$D),
            lhd_dfinish : base.formatDate(leaveFinish.$y,(leaveFinish.$M+1),leaveFinish.$D),
            lhd_cnotes : leaveNotes.toUpperCase(),
            lhd_centryby : viewData.LHD_CENTRYBY?viewData.LHD_CENTRYBY:'',
            lhd_dentry : viewData.LHD_DENTRY?viewData.LHD_DENTRY:new Date(),
            lampiran : selectedFile.attachmentView,
            flagEdit : flagEdit
        }
        console.log(dataSubmit)

        const data = new FormData() 
        data.append('lampiran', selectedFile.attachmentView)
        data.append('cidemployee', await localStorage.getItem('psn_cid'))
        data.append('lhd_cidgroup', query.get('id')?query.get('id'):'')
        data.append('lvt_cid', getLeave)
        data.append('lhd_dstart', base.formatDate(leaveStart.$y,(leaveStart.$M + 1),leaveStart.$D))
        data.append('lhd_dfinish', base.formatDate(leaveFinish.$y,(leaveFinish.$M+1),leaveFinish.$D))
        data.append('lhd_cnotes', leaveNotes.toUpperCase())
        data.append('lhd_centryby', viewData.LHD_CENTRYBY?viewData.LHD_CENTRYBY:'')
        data.append('lhd_dentry', viewData.LHD_DENTRY?viewData.LHD_DENTRY:new Date())
        data.append('flagEdit', flagEdit)

        //console.log(dataSubmit)
        //dataSubmit.push(dataTmp)
        if(!dataSubmit.lvt_cid||dataSubmit.lhd_dstart==='-NaN-'||dataSubmit.lhd_dfinish==='-NaN-'||!dataSubmit.lhd_cnotes||!dataSubmit.lampiran){
            if(!dataSubmit.lvt_cid){
                set_error_message_modal('Leave type is empty')
            }else if(dataSubmit.lhd_dstart==='-NaN-'){
                set_error_message_modal('Start date is empty')
            }else if(dataSubmit.lhd_dfinish==='-NaN-'){
                set_error_message_modal('Finish date is empty')
            }else if(!dataSubmit.lhd_cnotes){
                set_error_message_modal('Notes is empty')
            }else if(!dataSubmit.lampiran){
                set_error_message_modal('Attachment is empty')
            }else{
                set_error_message_modal('Data is empty')
            }
            base.$('#modalWrongProcess').modal('show')
        }else{
            if(window.confirm('Data can\'t change after submit, are you sure?')){
                var url = '/hris/leavepost'
                base.$('#preloader_modal').modal('show')

                if(navigator.onLine){    
                    //var response = await base.request(url, 'post', dataSubmit)
                    var response = await base.request(url, 'postmultipart', data)
                
                    base.$('#preloader_modal').modal('hide')
                    if(response != null){
                        if(response.status == 'success'){
                            base.hidePreloader()  
                            let resultId = response.leavepost.RLHD_CIDGROUP                  
                            window.location.replace('/success?bb=1&id=' + resultId)
                        }
                        else{
                            base.hidePreloader()
                            set_error_message_modal(response.message)
                            base.$('#modalWrongProcess').modal('show')
                        }
                    }
                    else{
                        errorPost()
                    }
                }
                else{
                    base.hidePreloader()
                    set_error_message_modal('Connection Failed')
                    base.$('#modalWrongProcess').modal('show')
                }
                console.log(response)
            }
        }
        //set_readonlyValue(prevState => !prevState)
    }

    async function submitClicked(){
        base.$('#preloader_modal').modal('show')
        var url = '/hris/leaveapproval'
        var dataSubmit = {
            id : query.get('id')?query.get('id'):'',
            NFLAGAPPROVE : 1,
            RCNIP : cemployee.CNIP,
            RCEMPLOYEE : cemployee.CEMPLOYEE,
            LEAVEREASON : cemployee.LEAVETYPE,
            RDSTART : base.formatDate(leaveStart.$y,(leaveStart.$M + 1),leaveStart.$D),
            RDEND : base.formatDate(leaveFinish.$y,(leaveFinish.$M+1),leaveFinish.$D),
            RCNOTES : leaveNotes.toUpperCase()
        }
        console.log(dataSubmit)

        if (dataSubmit.id) {
            var response = await base.request(url, 'post', dataSubmit)
            if (response.status=='success') {
                let resultId = dataSubmit.id
                window.location.replace('/success?bb=1&id=' + resultId)
            }
        }
        base.hidePreloader()
    }

    async function cancelClicked()
    {
        if(viewStatus=='view'){
            set_readonlyValue(prevState => !prevState)
            window.location.reload();
        }else if(viewStatus=='create'){
            //set_readonlyValue(prevState => !prevState)
            window.location.replace('/ess/leave')
        }
    }

    async function deleteClicked()
    {    
        var dataSubmit = {
            id: query.get('id')?query.get('id'):''
        }

        if(navigator.onLine){
            var url = '/hris/leavedelete'
            var response = await base.request(url, 'post', dataSubmit)
            if(response != null){
                if(response.status == 'success'){
                    base.hidePreloader()                 
                    window.location.replace('/success?bb=1.1')
                    //window.location.replace('/success?bb=1')
                }
                else{
                    
                    base.hidePreloader()
                    
                    base.$('#modalWrongProcess').modal('show')
                }
            }
            else{
                errorPost()
            }
        }
        else{
            errorPost()
        }
    }   
    
    function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
    }

    async function changeUploadFile(value,isInput){
        console.log(value.target.files[0])

        if (isInput) {
            set_selectedFile({...selectedFile, attachmentView:value.target.files[0]})
        }else{
            set_selectedFile({...selectedFile, attachmentReplace:value.target.files[0]})
        }

        if(value.target.files[0].size > 5000000){
            alert("File size is too big!");
            value.target.value = "";
        };

      /*const data = new FormData() 
      data.append('images', value.target.files[0])
      data.append('cidreff', await localStorage.getItem('psn_cid'))

      for (var pair of data.entries()) {
          console.log(pair[0]+ ' - ' + pair[1]); 
      }     

      var url = '/hris/uploademployeepicture'
      var response = await base.request(url, 'imagepost', data)
      console.log(response)
      
      if(response != null){
        if(response.status == 'success'){    
           console.log(response) 
           window.location.reload()
        }
      }*/
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>
                    <div className='pt-5 fixed-top page_wrap' style={{backgroundColor:'#FFF',height:80}}>
                        <PageHeader title='Leave' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>
                    <div className='col-12 mt-4 mb-3'>
                        <div className="form-group">
                            <label>Leave Type</label>
                            <Select
                                className='rounded'
                                value={getLeave??``}
                                onChange={handleChangeLeaveType}
                                displayEmpty
                                readOnly={readonlyValue}
                                inputProps={{ 'aria-label': 'Without label' }}
                                size='small'
                                sx={{width:'100%',fontSize:'14px'}}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                            leaveType?.map((data,index)=>(
                                <MenuItem key={index} value={data.LVT_CID}>{data.LVT_CDESCRIPTION}</MenuItem>
                            ))
                            }
                            </Select>
                        </div>
                        <div className="form-group">
                            <label>Start Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    value={leaveStart}
                                    size='small'
                                    views={['year', 'month', 'day']}
                                    readOnly={readonlyValue}
                                    onChange={(newValue) => set_leaveStart(newValue)}
                                    sx={{width: '100%',
                                        "& .MuiInputBase-input": {
                                            height: '0.35em'
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="form-group">
                            <label>Finish Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    value={leaveFinish}
                                    size='small'
                                    views={['year', 'month', 'day']}
                                    readOnly={readonlyValue}
                                    onChange={(newValue) => set_leaveFinish(newValue)}
                                    sx={{width: '100%',
                                        "& .MuiInputBase-input": {
                                            height: '0.35em'
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Notes</label>
                            <TextareaAutosize 
                                className='rounded '
                                defaultValue={leaveNotes}
                                onChange={(event) => onNotesChange(event)}
                                size='small'
                                readOnly={readonlyValue}
                                style={{width:'100%', textTransform:'uppercase'}}
                                minRows='4'
                                maxRows='6'
                            />
                        </div>
                        {
                            readonlyValue === false && flagEdit === 0 &&
                            <>    
                              <div className='form-group'>
                                <label>Attachment <i>(image file only)</i></label>
                                <input className='form-control rounded px-1' 
                                       type='file' 
                                       accept="image/*" 
                                       onChange={(e) => changeUploadFile(e,true)}
                                />
                              </div>
                            </> 
						}{	
							readonlyValue === true && 
							<>
								<div className='form-group'>
								<label>Attachment</label>
								<input className='form-control rounded px-1' 
										type='text' 
                                        readOnly={true}
										value={selectedFile.attachmentView??''}
								/>
                                <a className='btn btn-light rounded float-right mt-2' 
                                    data-toggle='modal' 
                                    data-target={'#'+lia_cid}
                                    onClick={()=>getAttachment()}
                                >Show Attachment
                                </a>
								</div>
                                {/*<div>
                                    <Document
                                        options={{workerSrc: "/pdf.worker.js"}}
                                        file={viewAttachment}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                    <p>Page {pageNumber} of {numPages}</p>
                                </div>*/}

                                <div className='modal fade' id={lia_cid} aria-labelledby='absenteeDetailModalLabel' aria-hidden='true'>
                                    <div className='modal-dialog modal-dialog-scrollable'>
                                        <div className='modal-content rounded'>
                                            <div className='modal-header'>
                                                <h5 className='modal-title'id='absenteeDetailModalLabel'>Attachment</h5>
                                            </div>
                                            <div className='modal-body'>
                                                <div className='col-12 pt-2' style={{height:'70vh'}}>
                                                    <img className='rounded mx-auto d-block' 
                                                         src={viewAttachment} 
                                                         alt='attachment' 
                                                         loading='lazy' 
                                                         style={{width:'70%',height:'auto'}}     
                                                    >
                                                    </img>
                                                </div>
                                            </div>
                                            {
                                                flagApprove===0 &&
                                                <div className='col mb-3'>
                                                    <div className='custom-tabs-bg-solusiqu text-center p-2'>
                                                        <h6>Change Attachment</h6>
                                                        <div className='d-flex justify-content-center'>
                                                            <input className='' 
                                                                type='file' 
                                                                accept="image/*" 
                                                                style={{border:'none',display:'inline'}}
                                                                onChange={(e) => changeUploadFile(e,false)}
                                                            />
                                                            <button type='button' className='btn btn-success rounded' onClick={()=>replaceAttachment()}>Change</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className='modal-footer'>
                                                <button type='button' className='btn btn-secondary rounded' data-dismiss='modal'>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
							</>
                        }
                        
                    </div>
                    <div className='form-group col-12 mt-4'>
                        {
                            ((readonlyValue === true)&&(viewStatus==='view')&&(flagApprove===0)) &&
                            <>    
                                <button className='btn btn-info rounded float-right rounded ml-2' onClick={()=> editClicked()}>Edit</button>
                                <button className='btn btn-danger rounded float-right rounded ml-2' onClick={()=> deleteClicked()}>Delete</button>
                                <button className='btn btn-success rounded float-right rounded' onClick={()=> submitClicked()}>Submit</button>
                            </> 
                        }
                        {
                            readonlyValue === false &&
                            <>
                                <button className='btn btn-secondary rounded float-right rounded ml-2' onClick={()=> cancelClicked()}>Cancel</button>
                                <button className='btn btn-success rounded float-right rounded' onClick={()=> saveClicked()}>Save</button>
                                

                            </>                         
                        }
                    </div>
                </div>
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal} />
        </>
    )
}