import { useEffect, useRef, useState } from 'react';
import Base from '../../utils/base';
import DB from '../../utils/db'

import ViewField from '../../components/viewField';
import WrongProcessModal from '../../components/modalWrongProcess';

export default function HomeIndex() {
	
	var base = new Base()	

	const [menu_arr, set_menu_arr] = useState([])
	const [error_message_modal, set_error_message_modal] = useState('')
	const [attendData, set_attendData] = useState('')
	const [greeting, set_greeting] = useState('')
	const [time, setTime] = useState(0);

	useEffect(() => {
	let intervalId;
	
	// setting time from 0 to 1 every 10 milisecond using javascript setInterval method
	intervalId = setInterval(() => setTime(time + 1), 10);
	
	return () => clearInterval(intervalId);
	}, [time]);

	useEffect(async () => {
		// await localStorage.clear()		
		var token = await localStorage.getItem('token')
		console.log(token)
		
		if (token == null) {
			window.location.href = '/auth/login'
		}
		else {			
			if (navigator.onLine) {
				base.cek_pwa_version()
				/*var get_default_input = await base.get_default_input()
				set_default_input(get_default_input)*/				
			}
			else {
				base.$('#error_modal').modal('show')
			}
		}
	}, [])

	useEffect(async () => {
		base.$('#preloader_modal').modal('show')        
		//if (default_input !== '') {
			await get_data()
			await getAttendData()	
		//}
		base.hidePreloader()			
	}, [])

	async function get_data(e) {		
		var url = '/menu?cidapp='+document.querySelector('meta[name="cidapp"]').content+'&cformname=HomeIndex'
		var response = await base.request(url)		

		if (response != null) {
			if (response.status == 'success') {
				var data = response.data

				for (var x in data) {									
					data[x].title = data[x].COBJECTDESCRIPTION
					data[x].cidappobject = data[x].CIDAPPOBJECT
					data[x].cobjectname = data[x].COBJECTNAME
					data[x].ctypeobject = data[x].CTYPEOBJECT
					data[x].cobjectgroup = data[x].COBJECTGROUP
					data[x].icon = data[x].COBJECTICON	
					data[x].chref = data[x].CHREF													
				}
				set_menu_arr(data)
				base.setConfig()
				
				//base.setConfig()
			}
			else {
				setTimeout(() => {
					base.$('#error_modal').modal('show')
				}, 750);
			}
		}
		else {
			await localStorage.clear()
			window.location.replace('/auth/login')
		}		
	}

	async function getAttendData(){
		var url = '/hris/employeehome' 
		var cidemployee = await localStorage.getItem('psn_cid')
		var dataSend = {cidemployee}
		var response = await base.request(url,'post',dataSend)
		if (response != null){
			if (response.status== 'success'){
				var data = response.employeehome
				var dataEmp = response.employeedata

				var greet = base.greeting()
			      greet.then((resp)=> {

			        //console.log(resp)
			        set_greeting(resp)
			      });
				
				data.CEMPLOYEE = dataEmp.CEMPLOYEE
				data.CABSDATE = new Date(data.ABS_DDATE).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
				data.NSISA = parseInt(data.NSISA)
				if (data.WSH_TSTART) {
					data.WSH_TSTART = new Date('1999-01-01 '+data.WSH_TSTART).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })					
				}
				if (data.WSH_TFINISH) {
					data.WSH_TFINISH = new Date('1999-01-01 '+data.WSH_TFINISH).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })					
				}
				set_attendData(data)
				console.log(data)
			}else{
				setTimeout(() => {
					base.$('#error_modal').modal('show')
				}, 750);
			}
		}else{
			await localStorage.clear()
			window.location.replace('/auth/login')
		}
	}

	async function navigateMenu(index) {
		var menu_index = menu_arr[index]
		console.log(menu_index)

		if (menu_index.CTYPEOBJECT = 'TMenuIndex')		
		{
			if (menu_index.CHREF === null)
			window.location.href = '/'; else
			window.location.href = menu_index.CHREF
		}

	}

	async function actAttend(e){
		e.preventDefault()
		window.location.href = '/ess/employeeattend'
	}

	

	function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
    }

	//console.log(attendData)
	if (!attendData){
		return(null)
	}
	return (
		<div className='page_wrap'>
			<div className='row'>
				<div className='col-auto'>
					{/* <div className='ratio-31' style={{backgroundImage : 'url(' + base.img_logo + ')', width : '10rem'}}></div> */}
					<div className='ratio-11' style={{ backgroundImage: 'url(' + base.img_logo_daiken + ')', width: '64px' }}></div>
				</div>
				<div className='col-12 py-3'>
					<h5>{greeting},</h5>
					<h6>{attendData.CEMPLOYEE}</h6>
					<p>don't forget to attend today!</p>
				</div>
				<div className='col-12'>
					<div className='custom-tabs-bg-solusiqu shadow px-3 py-1'>
						<div className='float-right' style={{marginTop:'6px', marginBottom:'auto'}}>
							<button className='btn btn-info rounded' onClick={(e)=>actAttend(e)}><i className='bi bi-clock-history'></i><br/>Check<br/>In/Out</button>
						</div>
						<div className='col-12'>
							<p><b>{attendData.ABS_DDATE?attendData.CABSDATE:<i>no schedule today</i>}</b></p>
						</div>
						<div className='d-flex justify-content-start'>
							<div className='pl-1 pr-1 mb-2 bd-highlight'>
								<div className='row'>
									<div className='col-5 pr-1 ml-2'>
										<ViewField style={{textAlign:'center'}} readonly={true} data={attendData.WSH_TSTART??''} type='text'/>
									</div>
									<div className='col-auto pr-0 pl-0'>-</div>
									<div className='col-5 pl-1'>
										<ViewField style={{textAlign:'center'}} readonly={true} data={attendData.WSH_TFINISH??''} type='text'/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-12 py-3'>
					<div className='row'>
						<div className='col-6'>
							<div className='py-2 d-flex align-items-center justify-content-center custom-tabs-bg-solusiqu shadow h-100'>
								<div className='text-center'>
									<h6 className='m-0 text-center' style={{fontSize:'14px'}}><span className=''>Leave Remain</span></h6><i>(this year)</i>
									<h5 className='text-center mt-1 mb-0'>{attendData.NSISA}</h5>
								</div>
							</div>
						</div>
						<div className='col-6'>
							<div className='py-2 d-flex align-items-center justify-content-center custom-tabs-bg-solusiqu shadow h-100'>
								<div className='text-center'>
									<h6 className='m-0 text-center' style={{fontSize:'14px'}}><span className=''>Total Permission Req.</span></h6><i>(this month)</i>
									<h5 className='text-center mt-1 mb-0'>{attendData.NPERMISSIONCOUNT_PENDING}</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-12 mt-5' style={{ marginBottom: '4.25rem' }}>
					<div className='row'>		
					{
						menu_arr.map((sub_menu_data, sub_menu_index) => (
							<div className='col-4 mb-3' key={sub_menu_index}>
								<div className='p-3 d-flex align-items-center justify-content-center shadow h-100' style={{ border: '1px solid #eaeaea', borderRadius: '5px', borderBottom: '6px solid #a9d3fc', cursor: 'pointer' }} onClick={() => navigateMenu(sub_menu_index)}>
									<div>
										<h4 className='m-0 text-center' style={{ color: '#4d4d4d' }}><i className={sub_menu_data.icon}></i></h4>
										<p className='m-0 text-center mt-2' style={{ fontSize: '.75rem', lineHeight: '1rem' }}>{sub_menu_data.title}</p>
									</div>
								</div>
							</div>
						))
					}				
					</div>
				</div>
			</div>
			{/*<MailNotification/>*/}
			<WrongProcessModal type={'error'} message={error_message_modal} />
		</div>
	)
}