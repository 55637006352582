import { useState, useEffect } from "react"
import Base from '../../../utils/base';
import PageHeader from '../../../components/pageHeader';
import WrongProcessModal from '../../../components/modalWrongProcess';
import { FormControl, FormControlLabel, MenuItem, Select } from '@mui/material';
import ViewField from "../../../components/viewField";
import Checkbox from '@mui/material/Checkbox';
import { Troubleshoot } from "@mui/icons-material";

export default function AddressRevision(){
    var base = new Base()

    const [v_disabled, set_v_disabled] = useState(false)
    const [disableButton, set_disableButton] = useState(false)
    const [error_message_modal, set_error_message_modal] = useState('')
    const [checked, setChecked] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [ipt_emuid, set_ipt_emuid] = useState('')
    /////
    const [ipt_cidaddress1, set_ipt_cidaddress1] = useState('')
    const [ipt_homeAddress1, set_ipt_homeAddress1] = useState('')
    const [ipt_homePost1, set_ipt_homePost1] = useState('')
    const [ipt_homeCity1, set_ipt_homeCity1] = useState('')
    const handleHomeCity1 = (event) => {
        set_ipt_homeCity1(event.target.value);
    }
    const [ipt_homeProvince1, set_ipt_homeProvince1] = useState('')
    const [ipt_homeCountry1, set_ipt_homeCountry1] = useState('')

    const [ipt_cidaddress2, set_ipt_cidaddress2] = useState('')
    const [ipt_ktpAddress2, set_ipt_ktpAddress2] = useState('')
    const [ipt_ktpPost2, set_ipt_ktpPost2] = useState('')
    const [ipt_ktpCity2, set_ipt_ktpCity2] = useState('')
    const handleKtpCity2 = (event) => {
        set_ipt_ktpCity2(event.target.value);
    }
    const [ipt_ktpProvince2, set_ipt_ktpProvince2] = useState('')
    const [ipt_ktpCountry2, set_ipt_ktpCountry2] = useState('')

    ////////////
    const [ipt_tempktpAddress2, set_ipt_tempktpAddress2] = useState('')
    const [ipt_tempktpPost2, set_ipt_tempktpPost2] = useState('')
    const [ipt_tempktpCity2, set_ipt_tempktpCity2] = useState('')
    /////
    const [masterCity, set_masterCity] = useState([])
    /////

    useEffect(async ()=>{
        // await localStorage.clear()
        await base.cek_pwa_version()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                getData()
            }
        }
    }, [])

    //////merge home address and ktp address
    useEffect(async ()=>{
        console.log(checked)
        if (checked) {
            set_ipt_homeAddress1(ipt_ktpAddress2)
            set_ipt_homePost1(ipt_ktpPost2)
            set_ipt_homeCity1(ipt_ktpCity2)
            //set_ipt_ktpProvince1()
            //set_ipt_ktpCountry2(ipt_ktpCountry1)
            set_v_disabled(true)
        }else{
            set_ipt_homeAddress1(ipt_tempktpAddress2)
            set_ipt_homePost1(ipt_tempktpPost2)
            set_ipt_homeCity1(ipt_tempktpCity2)
            //set_ipt_ktpProvince1()
            //set_ipt_ktpCountry2(ipt_tempktpCountry2)
            set_v_disabled(false)
        }
    },[checked])
    /////////
    useEffect(async ()=>{
        if (ipt_homeCity1) {
            var url = '/hris/loadmasterstatebycity'
            var dataSubmit = {cidcity: ipt_homeCity1}
            var response = await base.request(url,'post',dataSubmit)
            if (response.status=='success') {
                var data = response.loadstatebycity
                set_ipt_homeProvince1(data[0].CSTATE)
                set_ipt_homeCountry1(data[0].CCOUNTRY)
            }
        }
    },[ipt_homeCity1])

    useEffect(async ()=>{
        if (ipt_ktpCity2) {
            var url = '/hris/loadmasterstatebycity'
            var dataSubmit = {cidcity: ipt_ktpCity2}
            var response = await base.request(url,'post',dataSubmit)
            if (response.status=='success') {
                var data = response.loadstatebycity
                set_ipt_ktpProvince2(data[0].CSTATE)
                set_ipt_ktpCountry2(data[0].CCOUNTRY)
            }
        }
    },[ipt_ktpCity2])

    async function getData(){
        base.$('#preloader_modal').modal('show')
        var cidemployee = await localStorage.getItem('psn_cid')

        //////get master
        var url = '/hris/loadmastercity'
        var response = await base.request(url,'post')
        if (response.status=='success') {
            set_masterCity(response.loadcity)
        }
        ///////
        
        var url_trans = '/hris/employeeupdateview'
        var dataSubmit = {
            cidemployee,
            npage:3
        }
        var response_trans = await base.request(url_trans,'post',dataSubmit)
        if (response_trans.status=='success') {
            var data = response_trans.employeeupdateview

            for(let x in data){
                if (data[x]==null) {
                    data[x]=''
                }
            }

            if (data.EMU_CID) {
                set_ipt_emuid(data.EMU_CID)
            }
            set_ipt_cidaddress1(data.EMU_CIDADDRESS1)
            set_ipt_homeAddress1(data.EMU_CADDRESS1)
            set_ipt_homePost1(data.EMU_CZIPCODE1)
            set_ipt_homeCity1(data.EMU_CIDCITY1)
            //set_ipt_ktpProvince1()
            //set_ipt_ktpCountry1(data.EMU_CIDCOUNTRY1)

            set_ipt_cidaddress2(data.EMU_CIDADDRESS2)
            set_ipt_ktpAddress2(data.EMU_CADDRESS2)
            set_ipt_ktpPost2(data.EMU_CZIPCODE2)
            set_ipt_ktpCity2(data.EMU_CIDCITY2)
            //set_ipt_ktpProvince1()
            //set_ipt_ktpCountry2(data.EMU_CIDCOUNTRY2)

            /////temp for merge form
            set_ipt_tempktpAddress2(data.EMU_CADDRESS1)
            set_ipt_tempktpPost2(data.EMU_CZIPCODE1)
            set_ipt_tempktpCity2(data.EMU_CIDCITY1)
            //set_ipt_ktpProvince1()
            //set_ipt_tempktpCountry2(data.EMU_CIDCOUNTRY2)

            console.log(response_trans)
        }
        base.hidePreloader()
    }

    async function actSubmit(){
        var dataSubmit = {
            cidemployee : await localStorage.getItem('psn_cid'),
            npage : 3,
            emu_cid : ipt_emuid,

            emu_cidaddress1 : ipt_cidaddress1,
            emu_naddressstatus1 : 1,
            emu_caddress1 : ipt_homeAddress1?ipt_homeAddress1.toUpperCase():null,
            emu_cidcity1 : ipt_homeCity1,
            emu_cidstate1 : ipt_homeProvince1,
            emu_cidcountry1 : ipt_homeCountry1,
            emu_czipcode1 : ipt_homePost1,

            emu_cidaddress2 : ipt_cidaddress2,
            emu_naddressstatus2 : 5,
            emu_caddress2 : ipt_ktpAddress2?ipt_ktpAddress2.toUpperCase():null,
            emu_cidcity2 : ipt_ktpCity2,
            emu_cidstate2 : ipt_ktpProvince2,
            emu_cidcountry2 : ipt_ktpCountry2,
            emu_czipcode2 : ipt_ktpPost2,
        }
        console.log(dataSubmit)
        set_disableButton(true)
        var url = '/hris/employeeupdatepost'
        var response = await base.request(url,'post',dataSubmit)
        if (response.status=='success') {
            window.location.replace('/success')
        }else{
            base.hidePreloader()
            set_error_message_modal('failed to send data')
            base.$('#modalWrongProcess').modal('show')
        }
    }

    async function backToParent() {
		// await localStorage.removeItem('packing_input_product')
		// DB.product.clear();
		window.history.back()
	}
    
    return(
        <div className='page_wrap' style={{marginTop:60}}>
            <div className='row'>
                <div className='fixed-top page_wrap shadow-md' style={{backgroundColor: '#FFF', height:100}}>
                    <div className='pt-5'>
                        <PageHeader title='Address Data' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} customBack={true} backToPage={() => backToParent()}/>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div className='custom-tabs-bg-solusiqu p-3'>
                        <h5>KTP Address</h5>
                        <ViewField
                            data={ipt_ktpAddress2}
                            inputClass='text-uppercase'
                            type='text'
                            useLabel={1}
                            label='Address'
                            onChange={(e)=>set_ipt_ktpAddress2(e.target.value)}
                            disabled={disableButton}
                            readonly={false}
                            style={{fontSize:'0.9rem'}}
                        />
                        <ViewField
                            data={ipt_ktpPost2}
                            type='number'
                            useLabel={1}
                            label='Post Code'
                            onChange={(e)=>set_ipt_ktpPost2(e.target.value)}
                            disabled={disableButton}
                            readonly={false}
                            style={{fontSize:'0.9rem'}}
                        />
                        <div className="form-group row">
                            <div className="col row">
                                <label className='custom-input-solusiqu col-6 col-form-label'>City</label>
                            </div>
                            <div className="col-12">
                                <FormControl 
                                    required
                                    disabled={disableButton}
                                    sx={{ width:'100%',
                                        "& .MuiInputBase-input": {
                                            fontSize: '0.9rem'
                                        }
                                    }}
                                >
                                    <Select
                                        size='small'
                                        value={ipt_ktpCity2}
                                        onChange={handleKtpCity2}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {
                                        masterCity?.map((data,index)=>(
                                            <MenuItem key={index} value={data.CIDCITY}>{data.CCITY}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <ViewField
                            data={ipt_ktpProvince2}
                            type='text'
                            useLabel={1}
                            label='Province'
                            onChange={(e)=>set_ipt_ktpProvince2(e.target.value)}
                            disabled={disableButton}
                            readonly={true}
                            style={{fontSize:'0.9rem'}}
                        />
                        <ViewField
                            data={ipt_ktpCountry2}
                            type='text'
                            useLabel={1}
                            label='Country'
                            onChange={(e)=>set_ipt_ktpCountry2(e.target.value)}
                            disabled={disableButton}
                            readonly={true}
                            style={{fontSize:'0.9rem'}}
                        />
                    </div>
                    <div className='custom-tabs-bg-solusiqu p-3 mt-5'>
                        <h5>Home Address</h5>
                        <FormControlLabel
                            label='check if current address is ktp address'
                            disabled={disableButton}
                            labelPlacement="end"
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                        <ViewField
                            data={ipt_homeAddress1}
                            inputClass='text-uppercase'
                            type='text'
                            useLabel={1}
                            label='Address'
                            onChange={(e)=>set_ipt_homeAddress1(e.target.value)}
                            disabled={v_disabled?v_disabled:disableButton}
                            readonly={v_disabled}
                            style={{fontSize:'0.9rem'}}
                        />
                        <ViewField
                            data={ipt_homePost1}
                            type='number'
                            useLabel={1}
                            label='Post Code'
                            onChange={(e)=>set_ipt_homePost1(e.target.value)}
                            disabled={v_disabled?v_disabled:disableButton}
                            readonly={v_disabled}
                            style={{fontSize:'0.9rem'}}
                        />
                        <div className="form-group row">
                            <div className="col row">
                                <label className='custom-input-solusiqu col-6 col-form-label'>City</label>
                            </div>
                            <div className="col-12">
                                <FormControl 
                                    required
                                    sx={{ width:'100%',
                                        "& .MuiInputBase-input": {
                                            fontSize: '0.9rem'
                                        }
                                    }}
                                    disabled={v_disabled?v_disabled:disableButton}
                                >
                                    <Select
                                        size='small'
                                        value={ipt_homeCity1}
                                        onChange={handleHomeCity1}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {
                                        masterCity?.map((data,index)=>(
                                            <MenuItem key={index} value={data.CIDCITY}>{data.CCITY}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <ViewField
                            data={ipt_homeProvince1}
                            type='text'
                            useLabel={1}
                            label='Province'
                            onChange={(e)=>set_ipt_homeProvince1(e.target.value)}
                            disabled={true}
                            readonly={true}
                            style={{fontSize:'0.9rem'}}
                        />
                        <ViewField
                            data={ipt_homeCountry1}
                            type='text'
                            useLabel={1}
                            label='Country'
                            onChange={(e)=>set_ipt_homeCountry1(e.target.value)}
                            disabled={true}
                            readonly={true}
                            style={{fontSize:'0.9rem'}}
                        />
                    </div>
                    <div className={'mt-2 float-right'}>
                        <button className='btn btn-success rounded' 
                                disabled={disableButton}
                                onClick={actSubmit}
                        >Save
                        </button>
                    </div>
                </div>
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal}/>
        </div>
    )
}