import { useState, useEffect } from "react"
import Base from '../../../utils/base';
import PageHeader from '../../../components/pageHeader';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FormControl, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import ViewField from "../../../components/viewField";
import WrongProcessModal from "../../../components/modalWrongProcess";

export default function PersonalRevision(){
    var base = new Base()
    const filter = createFilterOptions();

    const [ipt_emuid, set_ipt_emuid] = useState('')

    const [ipt_fullName, set_ipt_fullName] = useState('')
    const [ipt_KK, set_ipt_KK] = useState('')
    const [ipt_KTP, set_ipt_KTP] = useState('')
    const [ipt_telp, set_ipt_telp] = useState('')
    const [ipt_telp_hp, set_ipt_telp_hp] = useState('')
    const [ipt_birthday, set_ipt_birthday] = useState('')
    const [ipt_birthplace, set_ipt_birthplace] = useState(null)
    const [ipt_blood, set_ipt_blood] = useState('')
    const handleBlood = (event) => {
        set_ipt_blood(event.target.value);
    }
    const [ipt_marital, set_ipt_marital] = useState('')
    const handleMarital = (event) => {
        set_ipt_marital(event.target.value);
    }
    const [ipt_religion, set_ipt_religion] = useState('')
    const handleReligion = (event) => {
        set_ipt_religion(event.target.value);
    }
    /////
    const [master_placebirth, set_master_placebirth] = useState([])
    const [master_religion, set_master_religion] = useState([])
    const [master_marital, set_master_marital] = useState([])
    const [master_bloodtype, set_master_bloodtype] = useState([])

    const [disableButton, set_disableButton] = useState(false)
    const [error_message_modal, set_error_message_modal] = useState('')

    useEffect(async ()=>{
        // await localStorage.clear()
        await base.cek_pwa_version()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                getData()
            }
        }
    }, [])

    async function getData(){
        base.$('#preloader_modal').modal('show')
        var cidemployee = await localStorage.getItem('psn_cid')

        ////master personal data
        var url_religion = '/hris/loadreligion'
        var url_marital = '/hris/loadmaritalstatus'
        var url_birthplace = '/hris/loadplaceofbirth'
        var url_bloodtype = '/hris/loadgolongandarah'
        
        var response_religion = await base.request(url_religion,'post')
        var response_marital = await base.request(url_marital,'post')
        var response_birtplace = await base.request(url_birthplace,'post')
        var response_bloodtype = await base.request(url_bloodtype,'post')
        response_religion=response_religion.loadreligion
        response_marital=response_marital.loadmaritalstatus
        response_birtplace=response_birtplace.loadplaceofbirth
        response_bloodtype=response_bloodtype.loadgolongandarah

        set_master_placebirth(response_birtplace)
        set_master_religion(response_religion)
        set_master_marital(response_marital)
        set_master_bloodtype(response_bloodtype)
        ////

        ////trans personal data
        var url_trans = '/hris/employeeupdateview'
        var dataSubmit = {
            cidemployee,
            npage:1
        }
        var response_trans = await base.request(url_trans,'post',dataSubmit)
        if (response_trans.status=='success') {
            var data = response_trans.employeeupdateview

            for(let x in data){
                if (data[x]==null) {
                    data[x]=''
                }
            }
        
            set_ipt_fullName(data.EMU_CEMPLOYEE)
            set_ipt_KK(data.EMU_CKK)
            set_ipt_KTP(data.EMU_CKTP)
            set_ipt_telp(data.EMU_CNOTELP)
            set_ipt_telp_hp(data.EMU_CNOHP)
            set_ipt_birthday(dayjs(data.EMU_DBIRTHDAY))
            set_ipt_birthplace(data.EMU_CPLACEOFBIRTH)
            set_ipt_blood(data.EMU_CGOLONGANDARAH)
            set_ipt_marital(data.EMU_NMARITALSTATUS)
            set_ipt_religion(data.EMU_CIDRELIGION)
            if (data.EMU_CID) {
                set_ipt_emuid(data.EMU_CID)
            }
            console.log(data)
        }
        ////

     	base.hidePreloader()
    }
    
    async function actSubmit(){
        var dataSubmit = {
            cidemployee : await localStorage.getItem('psn_cid'),
            npage : 1,
            emu_cid : ipt_emuid,
            
            emu_cemployee : ipt_fullName.toUpperCase(),
            emu_cplaceofbirth : ipt_birthplace.TRIM?ipt_birthplace.TRIM.toUpperCase():ipt_birthplace.toUpperCase(),
            emu_dbirthday : base.formatDate(ipt_birthday.$y,ipt_birthday.$M+1,ipt_birthday.$D),
            emu_nmaritalstatus : ipt_marital,
            emu_cidreligion : ipt_religion,
            emu_ckk : ipt_KK,
            emu_cktp : ipt_KTP,
            emu_cnotelp : ipt_telp,
            emu_cnohp : ipt_telp_hp,
            emu_cgolongandarah : ipt_blood
        }
        set_disableButton(true)
        var url = '/hris/employeeupdatepost'
        var response = await base.request(url,'post',dataSubmit)
        if (response.status=='success') {
            window.location.replace('/success')
        }else{
            base.hidePreloader()
            set_error_message_modal('failed to send data')
            base.$('#modalWrongProcess').modal('show')
        }

        console.log(dataSubmit)
    }

    async function backToParent() {
		// await localStorage.removeItem('packing_input_product')
		// DB.product.clear();
		window.history.back()
	}

    return(
        <div className='page_wrap' style={{marginTop:60}}>
            <div className='row'>
                <div className='fixed-top page_wrap shadow-md' style={{backgroundColor: '#FFF', height:100}}>
                    <div className='pt-5'>
                        <PageHeader title='Personal Data' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} customBack={true} backToPage={() => backToParent()}/>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div className='custom-tabs-bg-solusiqu p-3'>
                        <ViewField
                            inputClass='text-uppercase'
                            data={ipt_fullName}
                            type='text'
                            useLabel={1}
                            label='Full Name'
                            onChange={(e)=>set_ipt_fullName(e.target.value)}
                            disabled={disableButton}
                            readonly={false}
                            style={{fontSize:'0.9rem'}}
                        />
                        <ViewField
                            data={ipt_KK}
                            type='number'
                            useLabel={1}
                            label='KK No.'
                            onChange={(e)=>set_ipt_KK(e.target.value)}
                            disabled={disableButton}
                            readonly={false}
                            style={{fontSize:'0.9rem'}}
                        />
                        <ViewField
                            data={ipt_KTP}
                            type='number'
                            useLabel={1}
                            label='KTP No.'
                            onChange={(e)=>set_ipt_KTP(e.target.value)}
                            disabled={disableButton}
                            readonly={false}
                            style={{fontSize:'0.9rem'}}
                        />
                        <ViewField
                            data={ipt_telp}
                            type='number'
                            useLabel={1}
                            label='HP No.'
                            onChange={(e)=>set_ipt_telp(e.target.value)}
                            disabled={disableButton}
                            readonly={false}
                            style={{fontSize:'0.9rem'}}
                        />
                        <ViewField
                            data={ipt_telp_hp}
                            type='number'
                            useLabel={1}
                            label='Telp. No.'
                            onChange={(e)=>set_ipt_telp_hp(e.target.value)}
                            disabled={disableButton}
                            readonly={false}
                            style={{fontSize:'0.9rem'}}
                        />
                        <div className="form-group row">
                            <div className="col row">
                                <label className='custom-input-solusiqu col-6 col-form-label'>Birthday</label>
                            </div>
                            <div className="col-12">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={ipt_birthday}
                                        size='small'
                                        views={['year', 'month', 'day']}
                                        onChange={(newValue) => set_ipt_birthday(newValue)}
                                        sx={{width: '100%',
                                            "& .MuiInputBase-input": {
                                                height: '0.35em',
                                                fontSize: '0.9rem'
                                            }
                                        }}
                                        disabled={disableButton}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='form-group row'>
                            <div className="col row">
                                <label className='custom-input-solusiqu col-6 col-form-label'>Birthplace</label>
                            </div>
                            <div className="col-12">
                                <Autocomplete
                                    value={ipt_birthplace}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            set_ipt_birthplace({
                                            TRIM: newValue,
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            set_ipt_birthplace({
                                            TRIM: newValue.inputValue,
                                            });
                                        } else {
                                            set_ipt_birthplace(newValue);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.TRIM);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                            inputValue,
                                            TRIM: `Add "${inputValue.toUpperCase()}"`,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    options={master_placebirth}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.TRIM;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.TRIM}</li>}
                                    sx={{ width:'100%',
                                        "& .MuiInputBase-input": {
                                            fontSize: '0.9rem',
                                            textTransform:'uppercase'
                                        }
                                    }}
                                    size="small"
                                    disabled={disableButton}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col row">
                                <label className='custom-input-solusiqu col-6 col-form-label'>Marital Status</label>
                            </div>
                            <div className="col-12">
                                <FormControl 
                                    required
                                    disabled={disableButton}
                                    sx={{ width:'100%',
                                        "& .MuiInputBase-input": {
                                            fontSize: '0.9rem'
                                        }
                                    }}
                                >
                                    <Select
                                        size='small'
                                        value={ipt_marital}
                                        onChange={handleMarital}
                                    >
                                        {
                                        master_marital?.map((data,index)=>(
                                            <MenuItem key={index} value={data.NIDMARITALSTATUS}>{data.CMARITALSTATUS}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col row">
                                <label className='custom-input-solusiqu col-6 col-form-label'>Religion</label>
                            </div>
                            <div className="col-12">
                                <FormControl 
                                    required
                                    disabled={disableButton}
                                    sx={{ width:'100%',
                                        "& .MuiInputBase-input": {
                                            fontSize: '0.9rem'
                                        }
                                    }}
                                >
                                    <Select
                                        size='small'
                                        value={ipt_religion}
                                        onChange={handleReligion}
                                    >
                                        {
                                        master_religion?.map((data,index)=>(
                                            <MenuItem key={index} value={data.CIDRELIGION}>{data.CRELIGION}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col row">
                                <label className='custom-input-solusiqu col-6 col-form-label'>Blood Type</label>
                            </div>
                            <div className="col-12">
                                <FormControl 
                                    required
                                    disabled={disableButton}
                                    sx={{ width:'100%',
                                        "& .MuiInputBase-input": {
                                            fontSize: '0.9rem'
                                        }
                                    }}
                                >
                                    <Select
                                        size='small'
                                        value={ipt_blood}
                                        onChange={handleBlood}
                                    >
                                        {
                                        master_bloodtype?.map((data,index)=>(
                                            <MenuItem key={index} value={data.CGOLDARAH}>{data.CGOLDARAH}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2 float-right '>
                        <button className='btn btn-success rounded' 
                                onClick={actSubmit}
                                disabled={disableButton}
                        >Save
                        </button>
                    </div>
                </div>
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal} />
        </div>
    )
}