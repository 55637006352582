import React, { useEffect, useRef, useState } from 'react';
import Base from '../utils/base';
import FormInput from './FormInput';

export default function InputQRForm({input_type, qr_text, changeQRInput, searchQR, keyUpInput, total_data}){
    var base = new Base()
    
    
    useEffect(()=>{
        setTimeout(() => {
            base.$('#inputQR').focus()
        }, 750);
    }, [])
    
    return(
        <>
            <div className='col-12 mt-5 pt-4'>
                <div className='row m-0'>
                    <div className='col-12'>
                        <FormInput
                            label={'Barcode ID '}
                            type={'text'}
                            value={qr_text.toUpperCase()}
                            changeInput={(value)=>changeQRInput(value)}
                            onKeyDown={(e)=>searchQR(e.key)}
                            style={{textTransform : 'uppercase'}}
                            id={'inputQR'}
                            autoFocus />
                    </div>

                    {
                        total_data > 0 &&   
                        <div className='col-12 mt-2'>
                            <p className='m-0'>Total Scan : {total_data}</p>
                        </div>
                    }

                    <div className='col-12 mt-3'>
                        <button className='btn btn-lg btn-primary rounded w-100' onClick={()=>searchQR('Enter')}>Validate</button>
                    </div>
                    <div className='col-12 mt-3'>
                        <button className='btn btn-lg btn-outline-primary rounded w-100' onClick={()=>searchQR('Cancel')}>Close</button>
                    </div>
                </div>
            </div>
        </>
    )
}

const useFocus = () => {
    const htmElRef = useRef(null)
    const setFocus = () => {htmElRef.current && htmElRef.current.focus()}

    return [htmElRef, setFocus]
}