import React, { useEffect, useState } from 'react';
import Base from '../utils/base';

export default function PaletteList({data_arr, viewFrom, actionBtn, wh_type, process}){
    var base = new Base()

    useEffect(async ()=>{
        
    }, [])

    return(
        <>
        <div className='row'>
            <div className='col-12 p-0'>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                            <th style={{fontSize : '.75rem'}}>{wh_type == null || wh_type === 'warehouse' ? 'Palette ID' : wh_type === 'back_to_warehouse' ? 'Delivery Order' : 'Palette ID'}</th>
                            <th style={{fontSize : '.75rem'}}>Qty</th>

                            {
                                wh_type == null || wh_type === 'warehouse' ?
                                <th></th>
                                :
                                <></>
                            }
                            {
                                viewFrom !== 'history' &&
                                <>
                                    <th></th>
                                </>
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data_arr.map((data, index)=>(
                                    <tr key={index}>
                                        <td className='align-middle' style={{fontSize : '.75rem'}}>{process == null ? data.PAL_CID : data.PKC_CCODE}</td>
                                        <td className='align-middle' style={{fontSize : '.75rem'}}>{data.quantity}</td>

                                        {
                                            wh_type == null || wh_type === 'warehouse' ?
                                            <td className='align-middle'>
                                                <button className='btn btn-sm btn-primary rounded' onClick={()=>actionBtn('view', index)}>View</button>
                                            </td>
                                            :
                                            <></>
                                        }
                                        {
                                            viewFrom !== 'history' &&
                                            <>
                                                <td className='align-middle'>
                                                    <button className='btn btn-sm btn-danger' style={{borderRadius : '1rem'}} onClick={()=>actionBtn('remove', index)}><i className='bi bi-trash-fill text-white' style={{fontSize : '.75rem'}}></i></button>
                                                </td>
                                            </>
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    )
}