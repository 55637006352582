import react, { useEffect, useState } from 'react';
import Base from '../utils/base';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

export default function SuccessPage(){
    var base = new Base()
    const [tracking_type, set_tracking_type] = useState('')
    const [backButton, set_backButton] = useState('')
    const [uniqueID, set_uniqueID] = useState('')

    function useQuery(){
        const {search} = useLocation()
        return react.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }
        if(query.get('tracking-type') != null){
            set_tracking_type(query.get('tracking-type'))
        }
        if(query.get('bb')){
            set_backButton(query.get('bb'))
            set_uniqueID(query.get('id'))
        }
    }, [])

    function btnAction(type){
        if(type === 'home'){
            window.location.replace('/')
        }
        else if(type === 'scan'){
            window.location.replace('/tracking/add-record?type=' + query.get('type') + '&tracking-type=' + query.get('tracking-type'))
        }else if(type === 'view'){
            if(query.get('bb')=='1'){
                window.location.replace('/ess/leave/detail?type=view&id=' + uniqueID)
            }else if(query.get('bb')=='1.1'){
                window.location.replace('/ess/leave')
            }else if(query.get('bb')=='2'){
                window.location.replace('/ess/permission/detail?type=view&id=' + uniqueID)
            }else if(query.get('bb')=='2.1'){
                window.location.replace('/ess/permission')
            }else if(query.get('bb')=='3'){
                window.location.replace('/ess/overtime/detail?type=view&id=' + uniqueID)
            }else if(query.get('bb')=='3.1'){
                window.location.replace('/ess/overtime')
            }
            //window.location.replace('/ess/leave/')
        }
    }

    return(

        <div className='page_wrap'>
            <div className='row'>

                {/* <div className='col-auto'>
                    <div className='ratio-31' style={{backgroundImage : 'url(' + base.img_logo + ')', width : '10rem'}}></div>
                </div> */}

                <div className='col-12 mt-5 d-flex justify-content-center'>
                    <div>
                        <h4 className='m-0 text-center'>Submit Successful</h4>
                        <p className='m-0 text-center'>You have success added new record</p>
                    </div>
                </div>

                <div className='col-12 mt-5 text-center'>
                    <img src={base.img_success} style={{height : '15rem'}} />
                </div>

                <div className='col-12 w-100 py-2 shadow-lg fixed-bottom' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                    <div className='row'>
                        {
                            query.get('pdl') === 'tracking' &&
                            <div className='col-12 mt-3'>
                                {
                                    tracking_type !== 'MUTATION STOCK' ?
                                    <button className='btn btn-outline-primary shadow-sm rounded w-100 customBtn' onClick={()=>btnAction('scan')}>Scan Again</button>
                                    : <></>
                                }
                            </div>
                        }
                        {
                            backButton &&
                            <div className='col-12 mt-3'>
                                <button className='btn btn-outline-primary shadow-sm rounded w-100 customBtn' onClick={()=>btnAction('view')}>Back to Menu</button>
                            </div>
                        }
                        <div className='col-12 mt-3'>
                            <button className='btn btn-primary shadow-sm rounded w-100 customBtn' onClick={()=>btnAction('home')}>Back to Home</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}