import * as React from 'react';
import { useEffect, useState } from 'react';
import Base from '../../../utils/base';

import dayjs from 'dayjs';
import ViewField from '../../../components/viewField';
import MenuHeader from '../../../components/MenuHeader';

export default function ESS_Absentee(){
    var base = new Base()
    
    const newDate = new Date()
    const newMonth = newDate.getUTCMonth() + 1
    const newYear = newDate.getUTCFullYear()
    const [iptMonth, set_iptMonth] = useState(dayjs(newYear+'/'+newMonth))

    const [absentData, set_absenteeData] = useState([''])
    const [attendRaw, set_attendRaw] = useState([''])
    const [permissionList, set_permissionList] = useState([''])
    const [leaveList, set_leaveList] = useState([''])

    useEffect(async ()=>{
        // await localStorage.clear()
        base.cek_pwa_version()
        
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                get_data()
            }
        }
    }, [])

    async function getAttendLogs(dstart,dend){
        base.$('#preloader_modal').modal('show')
        
        var url_getLog = '/hris/employeeabsentee_raw'
        var url_getPermission = '/hris/employeepermission'
        var url_getLeave = '/hris/employeeleavebydate'

        var cidemployee = await localStorage.getItem('psn_cid')
        var ddate = dstart

        var start_log = new Date(dstart)
        start_log.setDate(start_log.getDate()-1)
        start_log = base.formatDate(start_log.getFullYear(),start_log.getMonth()+1,start_log.getDate())

        var finish_log = new Date(dend)
        finish_log.setDate(finish_log.getDate()+1)
        finish_log = base.formatDate(finish_log.getFullYear(),finish_log.getMonth()+1,finish_log.getDate())

        var submitLog = {start_log,finish_log,cidemployee}
        var submitPermission = {cidemployee,dstart,dend}
        var submitLeave = {cidemployee,ddate}

        var response = await base.request(url_getLog,'post',submitLog)
        var response_permission = await base.request(url_getPermission,'post',submitPermission)
        var response_leave = await base.request(url_getLeave,'post',submitLeave)

        if (response.status=='success') {
            var absentee_raw = response.employeeabsentee_raw
            for(var x in absentee_raw){
                if (absentee_raw[x].RAW_TABSENTEE) {
                    absentee_raw[x].RAW_TABSENTEE = new Date('1999-01-01 '+absentee_raw[x].RAW_TABSENTEE).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })					
                }
                if (absentee_raw[x].RAW_DABSENTEE) {
                    absentee_raw[x].RAW_CDABSENTEE = new Date(absentee_raw[x].RAW_DABSENTEE).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"long", day:"numeric"})
                }
                if (absentee_raw[x].RAW_DABSENTEE != dstart && absentee_raw[x].RAW_DABSENTEE != dend) {
                    absentee_raw[x].CSS_FONT_COLOR = 'text-primary'
                }else{
                    absentee_raw[x].CSS_FONT_COLOR = 'text-body'
                }
            }
            console.log(absentee_raw)
            set_attendRaw(absentee_raw)
        }

        if (response_permission.status=='success') {
            var vpermissionList = response_permission.employeepermission_absentee
            for(var y in vpermissionList){
                vpermissionList[y].PMS_TSTART = new Date('1999-01-01 '+vpermissionList[y].PMS_TSTART).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                vpermissionList[y].PMS_TFINISH = new Date('1999-01-01 '+vpermissionList[y].PMS_TFINISH).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
            }
            set_permissionList(vpermissionList)
        }

        if (response_leave.status=='success') {
            var vleaveList = response_leave.absentee[0]
            set_leaveList(vleaveList)
        }
        
        console.log(vpermissionList)
        base.hidePreloader()
    }

    async function get_data(){
        base.$('#preloader_modal').modal('show')
        var cidemployee = await localStorage.getItem('psn_cid')
        //console.log(cidemployee)
        var nmonth = iptMonth.$M+1 
        var nyear = iptMonth.$y
        var dataurl = {
            cidemployee, nmonth, nyear 
        }
        var url = '/hris/employeeabsentee'//?cidemployee='+cidemployee+'&nmonth='+(iptMonth.$M+1)+'&nyear='+iptMonth.$y
        var response = await base.request(url,'post',dataurl)
        //console.log(url)
        if(response != null){
            if(response.status == 'success'){                

                var dataView = response.absentee
                for(var x in dataView){

                    dataView[x].ABS_REQFORLOG = dataView[x].ABS_DDATE
                    dataView[x].ABS_NLATEH = parseInt(dataView[x].ABS_NLATEH)
                    dataView[x].OVS_NVALUEHOUR = parseInt(dataView[x].OVS_NVALUEHOUR)
                    dataView[x].OVS_NVALUEMINUTE = parseInt(dataView[x].OVS_NVALUEMINUTE)
                    dataView[x].ABS_NLATEM = parseInt(dataView[x].ABS_NLATEM)
                    dataView[x].OVS_TOTALALL = new Date('1999-01-01 '+dataView[x].OVS_NVALUEHOUR+':'+dataView[x].OVS_NVALUEMINUTE+':00').toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    dataView[x].NTOTAL_LATE = new Date('1999-01-01 '+dataView[x].ABS_NLATEH+':'+dataView[x].ABS_NLATEM+':00').toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    if (dataView[x].ABS_DDATE) {
                        dataView[x].ABS_DDATE = new Date(dataView[x].ABS_DDATE).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"long", day:"numeric"})
                    }
                    dataView[x].ABS_NLATE = parseFloat(dataView[x].ABS_NLATE).toFixed(2)
                    dataView[x].OVS_NCALCULATED = parseFloat(dataView[x].OVS_NCALCULATED).toFixed(2)
                    dataView[x].RAW_DDIFF = (new Date(dataView[x].RAW_DFINISH).getTime()-new Date(dataView[x].RAW_DSTART).getTime())/ (1000 * 60 * 60 * 24)
                    if(dataView[x].RAW_TSTART){
                        dataView[x].RAW_TSTART = new Date('1999-01-01 '+dataView[x].RAW_TSTART).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    }
                    if(dataView[x].RAW_TFINISH){
                        dataView[x].RAW_TFINISH = new Date('1999-01-01 '+dataView[x].RAW_TFINISH).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    }
                    if(dataView[x].WSH_TSTART){
                        dataView[x].WSH_TSTART = new Date('1999-01-01 '+dataView[x].WSH_TSTART).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    }
                    if(dataView[x].WSH_TFINISH){
                        dataView[x].WSH_TFINISH = new Date('1999-01-01 '+dataView[x].WSH_TFINISH).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    } 
                }
                set_absenteeData(dataView)
                base.hidePreloader()

                console.log(dataView)     
            }
        }
     	base.hidePreloader()
 	}

    return(
        <>
            <div className='page_wrap' style={{marginTop:220}}>
                <div className='row'>
                    <MenuHeader
                        dateValue={iptMonth}
                        onChangeDate={(newValue) => set_iptMonth(newValue)}
                        onClickFilter={()=>get_data()}
                        headTitle={'Absentee & Attendance'}
                    />
                    <div className='col-12 mt-2'>
                        <div className='mb-5'>
                        {absentData?.map((data,index)=>(
                            <div key={index} className='card mb-4 rounded'>
                                <a className='card-block stretched-link text-decoration-none' 
                                    data-toggle='modal' data-target={'#absentee'+ index} 
                                    onClick={() => getAttendLogs(data.ABS_REQFORLOG,data.ABS_REQFORLOG)}>
                                    <div className='col mt-2'>
                                    <div className='float-right pt-1' style={{fontSize:'12px'}}>{data.RAW_DDIFF&&data.RAW_TSTART&&data.RAW_TFINISH?'+'+data.RAW_DDIFF+' (Day)':''}</div>
                                        <div className='mb-2'>
                                            <h6 className='card-title' style={data.WRK_NFLAGOFF=='1'?{color:'#a11b1b',fontWeight:'bold',display:'inline'}:data.WRK_NFLAGOFF=='0' && data.SCI_CID!=''?{color:'#8c2288',fontWeight:'bold',display:'inline'}:{fontWeight:'bold',display:'inline'}}>{data.ABS_DDATE}</h6>
                                            <i className='pl-2' style={{fontSize:'14px'}}>({data.WRK_CMANUALCODE})</i>
                                        </div>
                                        <div className='d-flex justify-content-center' style={{fontWeight:'bold', fontSize:'12px'}}>
                                            <div className='col-6'>Workshift Start</div>
                                            <div className='col-6'>Workshift Finish</div>
                                        </div>
                                        <div className='d-flex justify-content-center' style={{fontSize:'12px'}}>
                                            <div className='col-6'><ViewField readonly={true} data={data.WSH_TSTART?data.WSH_TSTART:''} type='text'/></div>
                                            <div className='col-6'><ViewField readonly={true} data={data.WSH_TFINISH?data.WSH_TFINISH:''} type='text'/></div>
                                            {/*<div className='col-sm-5'><ViewField type='time' style={{backgroud:'none', textAlign:'center'}} data={data.RAW_TSTART?data.RAW_TSTART:''}/></div>
                                            <div className='col-sm-5'><ViewField type='time' style={{backgroud:'none', textAlign:'center'}} data={data.RAW_TFINISH?data.RAW_TFINISH:''}/></div>*/}
                                        </div>
                                        <div className='d-flex justify-content-center' style={{fontWeight:'bold', fontSize:'12px'}}>
                                            <div className='col-6'>Check In</div>
                                            <div className='col-6'>Check Out</div>
                                        </div>
                                        <div className='d-flex justify-content-center' style={{fontSize:'12px'}}>
                                            <div className='col-6'><ViewField readonly={true} data={data.RAW_TSTART?data.RAW_TSTART:''} type='text'/></div>
                                            <div className='col-6'><ViewField readonly={true} data={data.RAW_TFINISH?data.RAW_TFINISH:''} type='text'/></div>
                                            {/*<div className='col-sm-5'><ViewField type='time' style={{backgroud:'none', textAlign:'center'}} data={data.RAW_TSTART?data.RAW_TSTART:''}/></div>
                                            <div className='col-sm-5'><ViewField type='time' style={{backgroud:'none', textAlign:'center'}} data={data.RAW_TFINISH?data.RAW_TFINISH:''}/></div>*/}
                                        </div>
                                        <div>
                                            <p style={{color:'#ad445e', fontSize:'11px', fontWeight:'bold', lineHeight:'0.1111px',paddingTop:'0px'}}>{data.SCI_CDESCRIPTION}</p>
                                            <p style={{color:'#037010', fontSize:'11px', fontWeight:'bold', lineHeight:'0.1111px',paddingTop:'0px'}}>{data.HLD_CNAME}</p>
                                        </div>
                                    </div>
                                </a>
                                <div className='modal fade' id={'absentee'+ index} aria-labelledby='absenteeDetailModalLabel' aria-hidden='true'>
                                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' id={data.ABS_CID}>
                                        <div className='modal-content rounded'>
                                            <div className='modal-header'>
                                                <h5 className='modal-title' id='absenteeDetailModalLabel'>{data.ABS_DDATE}</h5>
                                            </div>
                                            <div className='modal-body'>
                                                <div className='mb-4'>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width:'110px'}}>Workshift</td>
                                                                <td className='pr-2'>:</td>
                                                                <td>{data.WRK_CMANUALCODE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:'110px'}}>Late Calc.</td>
                                                                <td className='pr-2'>:</td>
                                                                <td>{data.NTOTAL_LATE} (Hour)</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:'110px'}}>Overtime Calc.</td>
                                                                <td>:</td>
                                                                <td>{data.OVS_TOTALALL} (Hour)</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:'110px'}}>Leave</td>
                                                                <td className='pr-2'>:</td>
                                                                <td className='text-break'>{leaveList?leaveList.LVT_CDESCRIPTION:''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:'110px'}}>Leave Reason</td>
                                                                <td className='pr-2'>:</td>
                                                                <td className='text-break'>{leaveList?leaveList.LHD_CNOTES:''}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='mb-4'>
                                                    <h6 className='pr-2' style={{display:'inline'}}>Permission List</h6><br/>(<i>shorted by newest</i>)
                                                    <div className='col mb-2 custom-tabs-bg-solusiqu rounded'></div>
                                                    <div className='p-2'>
                                                        <table>
                                                            <tbody>
                                                                {
                                                                permissionList[0]?
                                                                <tr>
                                                                    <th style={{width:'60px'}}>No.</th>
                                                                    <th style={{width:'160px'}}>Start</th>
                                                                    <th style={{width:'160px'}}>Finish</th>
                                                                    <th style={{width:'130px'}}>Type</th>
                                                                </tr>:
                                                                <tr>
                                                                    <td><i>-- no permission on this workshift --</i></td>
                                                                </tr>
                                                                }
                                                                {
                                                                permissionList?.map((perm,index)=>(
                                                                    <tr key={index}>
                                                                        <td>{index+1}</td>
                                                                        <td>{perm.PMS_TSTART}</td>
                                                                        <td>{perm.PMS_TFINISH}</td>
                                                                        <td>{perm.PMT_CNAME}</td>
                                                                    </tr>
                                                                ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/*<div className='mb-4'>
                                                    <h6 className='pr-2' style={{display:'inline'}}>Leave List</h6>
                                                    <div className='col mb-2 custom-tabs-bg-solusiqu rounded'></div>
                                                    <div className='p-2'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width:'60px'}}>No.</th>
                                                                <th style={{width:'160px'}}>Date Start</th>
                                                                <th style={{width:'160px'}}>Date Finish</th>
                                                                <th style={{width:'130px'}}>Type</th>
                                                            </tr>
                                                            {
                                                            leaveList?.map((leave,index)=>(
                                                                <tr>
                                                                    <td>{index+1}</td>
                                                                    <td>{leave.LHD_DSTART}</td>
                                                                    <td>{leave.LHD_DFINISH}</td>
                                                                    <td>{leave.LVT_CDESCRIPTION}</td>
                                                                </tr>
                                                            ))
                                                            }
                                                        </thead>
                                                    </div>
                                                        </div>*/}
                                                <div>
                                                    <h6 className='pr-2' style={{display:'inline'}}>Attendance Logs</h6><br/>(<i>shorted by newest</i>)
                                                    <div className='col mb-2 custom-tabs-bg-solusiqu rounded'></div>
                                                    {
                                                    attendRaw[0]?
                                                    <></>:
                                                    <><i>-- no attend on this workshift --</i></>
                                                    }
                                                    <div className='p-2'>
                                                    {
                                                        attendRaw?.map((log,index)=>(
                                                            <li key={index} style={{display:'block'}}><i className='bi bi-alarm pr-2'></i><p className={log.CSS_FONT_COLOR} style={{display:'inline'}}>{log.RAW_TABSENTEE} ({log.RAW_CDABSENTEE})</p></li>
                                                        ))
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='modal-footer'>
                                                <button type='button' className='btn btn-secondary rounded' data-dismiss='modal'>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}