import { Alert, Checkbox, FormControlLabel, IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PageHeader from "../../components/pageHeader";
import { useEffect, useState } from "react";
import Base from '../../utils/base';

function Mailbox(){
    var base = new Base()

    const [notifData, set_notifData] = useState([])
    const [showUnread, set_showUnread] = useState(false)
    const handleshowUnread = (e)=>{
        set_showUnread(e.target.checked)
    }

    useEffect(async()=>{
        var token = await localStorage.getItem('token')	
		if (token == null) {
			window.location.href = '/auth/login'
		}
		else {			
			if (navigator.onLine) {
				getData()
			}
		}
    },[showUnread])

    async function getData(){
        base.$('#preloader_modal').modal('show')
        var url = '/notification/loadmessage'
        var dataSubmit = {
            cidemployee:localStorage.getItem('psn_cid'),
            nshowread:showUnread?1:0
        }
        var response = await base.request(url,'post',dataSubmit)
        if (response!=null) {
            if (response.status==='success') {
                var data = response.loadmessage
                for(var x in data){
                    data[x].DMESSAGE = new Date(data[x].DTIMESTAMP).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"long", day:"numeric"})
                }
                set_notifData(data)
                console.log(data)
            }
        }
        base.hidePreloader()
    }

    async function updateMessageStatus(id){
        base.$('#preloader_modal').modal('show')
        var url = '/notification/updatemessage'
        var dataSubmit={
            idmessage: id,
            updatestatus: 1
        }
        var response = await base.request(url,'post',dataSubmit)
        if (response.status==='success') {
            base.hidePreloader()
        }
        console.log(dataSubmit)
        //base.hidePreloader()
    }

    return(
        <div className='page_wrap' style={{marginTop:80}}>
            <div className='row'>
                <div className='pt-5 fixed-top page_wrap' style={{backgroundColor: '#FFF', height:120}}>
                    <PageHeader title='Notification' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={false} />
                    <FormControlLabel
                        className="mt-2"
                        label="Show Unread Notification"
                        control={
                            <Checkbox 
                                checked={showUnread}
                                onChange={handleshowUnread}
                            />
                        }
                    />
                </div>
                <div className="col-12 mt-3">
                    <div className="row">
                    {
                        notifData?.map((data,index)=>(
                            <div className='col-12 py-1' key={index}>
                                <div style={data.CSTATUS=='New'?{borderBottom : '1px solid #DADDDF', cursor : 'pointer', fontWeight:'bold'}:{borderBottom : '1px solid #DADDDF', cursor : 'pointer'}}>
                                    <a className="card-block stretched-link text-decoration-none" data-toggle='modal' data-target={'#message'+ index} 
                                        onClick={() => updateMessageStatus(data.CIDMSG)}>
                                        <p className="float-right">{data.DMESSAGE}</p>
                                        <div className='row'>
                                            <div className='col py-auto'>
                                                <p className='m-0'>{data.CSUBJECT}</p>
                                            </div>
                                            
                                        </div>
                                    </a>
                                </div>
                                <div className='modal fade' id={'message'+ index} aria-labelledby='messageModalLabel' aria-hidden='true' data-backdrop="static" data-keyboard="false" tabIndex={-1}>
                                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' id={'message'+index}>
                                        <div className='modal-content rounded'>
                                            <div className='modal-header'>
                                                <h5 className='modal-title' id='absenteeDetailModalLabel'>{data.CSUBJECT}</h5>
                                            </div>
                                            <div className='modal-body'>
                                                <div className='mb-4'>
                                                    <p>{data.CMESSAGE}</p>
                                                </div>
                                            </div>
                                            <div className='modal-footer'>
                                                <button type='button' className='btn btn-secondary rounded' data-dismiss='modal' onClick={() => getData()}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

function MailNotification(){
    var base = new Base()
    
    //get config interval, convert to milisecond
    var getIntervalMessage = localStorage.getItem('MSG_TIMER')*1000
    /////

    const [onNotif, set_onNotif] = useState(false)
    const closeNotif = ()=>{
        set_onNotif(false)
    }

    useEffect(async () => {
		// await localStorage.clear()		
		var token = await localStorage.getItem('token')	
		if (token == null) {
			window.location.href = '/auth/login'
		}
		else {			
			if (navigator.onLine) {
				base.cek_pwa_version()
			}
		}
	}, [])

    useEffect(() => {

        if (getIntervalMessage>0) {
            /*const interval = setInterval(() => { 
                console.log('5 detik')
                //getData()
            }, getIntervalMessage)
            return () => clearInterval(interval); */
        }

    }, []);

    async function getData(){
        var dataSubmit = {
            cidemployee : localStorage.getItem('psn_cid')
        }
        var url = '/notification/loadnotification'
        var response = await base.request(url,'post',dataSubmit)
        if (response!=null) {
            if (response.status==='success') {
                var data = response.loadnotification[0].NCOUNT
                if (data>0) {
                    console.log('lebih dari satu notif')
                    set_onNotif(true)
                }
            }
        }
    }

    return(
        <Snackbar
            anchorOrigin={{vertical:'top',horizontal:'right'}}
            open={onNotif}
            autoHideDuration={15000}
            onClose={closeNotif}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5 }}
                    onClick={closeNotif}
                >
                    <CloseIcon />
                </IconButton>
            }
        >
            <Alert severity="info" onClose={closeNotif}>You have new notification</Alert>
        </Snackbar>
    )
}

export {Mailbox,MailNotification}