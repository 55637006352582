import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { data, event } from 'jquery';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

export default function MaterialDetail({type, actionBtn, data_arr, viewFrom}){
    var base = new Base()

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [is_disabled, set_is_disabled] = useState(false)
    const [action_btn_arr, set_action_btn_arr] = useState([
        {title : 'Reinput Palette', icon : 'bi bi-upc-scan', btn_type : 'btn-outline-primary', type : 'retype_palette'},
        {title : 'Confirm', icon : '', btn_type : 'btn-primary', type : 'confirm'},
    ])

    async function actionBtn(type){
        if(type === 'retype_palette'){
            await localStorage.removeItem('packing_input_palette')
            window.location.replace('/material/input-id?type=' + query.get('type') + '&input_type=palette')
        }
        else if(type === 'confirm'){
            var nqtyTake = 0
            var ipt_taken = []
            var dataOk = null

            var DataSubmit = []
            /*for(var x in data_arr){
                //console.log(item[x])
                //nqtyTake = document.getElementById('i'+data.RRD_CID).value
                ipt_taken[x]['PAL_CID'] = data_arr.PAL_CID
                ipt_taken[x]['RRD_CID'] = data_arr.RRD_CID
                ipt_taken[x]['quantity'] = parseInt(data_arr.RRD_NQTY)
                //data_product.push(item[x])
            }*/
            
            data_arr.map((data, index)=>(
                nqtyTake = parseInt(document.getElementById('i'+data.RRD_CID).value),
                ipt_taken[index] = nqtyTake,
                data_arr[index].qtyTake=ipt_taken[index],
                dataOk ={
                    pal_cid : data_arr[index].PAL_CID,
                    rrd_cid : data_arr[index].RRD_CID,
                    qtytaken : data_arr[index].qtyTake
                },
                DataSubmit.push(dataOk)
                    //console.log(item[x])
            ))
            // create JSON objects from Array
            //var arrayToString = JSON.parse(Object.assign({}, DataSubmit))
			
			var data = {
                    DataSubmit : DataSubmit
                }
				
            console.log(DataSubmit)
            //console.log(ipt_taken)

            if(navigator.onLine){
                // base.$('#preloader_modal').modal('show')
                set_is_disabled(true)

                var url = '/material'
                var method = 'post'

                base.$('#preloader_modal').modal('show')
                var response = await base.request(url, method, data)

                base.$('#preloader_modal').modal('hide')
                if(response != null){
                    if(response.status == 'success'){

                        await localStorage.removeItem('packing_input_palette')
                        //console.log(DataSubmit)
                        window.location.replace('/success')
                    }
                    else{
                        errorPost()
                    }
                }
                else{
                    errorPost()
                }
            }
            else{
                errorPost()
            }

        }
    }

    

    function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
        set_is_disabled(false)
    }

    return(
        <>
            <div className='row'>
                <div className='col-12 p-0'>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style={{fontSize : '.75rem'}}>Item</th>
                                    <th style={{fontSize : '.75rem'}}>Qty</th>
                                    <th style={{fontSize : '.75rem'}}>Taken</th>                                                                      
                                </tr>
                            </thead>
                        
                            <tbody>
                                {
                                    data_arr.map((data, index)=>(
                                        <tr key={data.RRD_CID}>
                                            <td className='align-middle' style={{fontSize : '.75rem'}}>{data.CINVENTORY}</td>
                                            <td className='align-middle' style={{fontSize : '.75rem'}}>{data.P2I_NQTY}</td>
                                            <td className='align-middle' style={{fontSize : '.75rem'}}>
                                                <input type='number' id={'i'+data.RRD_CID} className='form-control rounded'/>
                                            </td>
                                        </tr>
                                        //console.log(document.getElementById('i'+ data.RRD_CID).value)
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className='table-responsive' style={{backgroundColor : '#F5F5F5'}}>
                            <p className='m-0 text-center font-weight-bold' style={{color : '#777777'}}>Total {data_arr.length} Product</p>
                        </div>
                        <br/>
                        {
                        query.get('type') !== 'view' &&
                        <div className='position-fixed-responsive col-12 p-0' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                                {
                                    action_btn_arr.map((data, index)=>(
                                        <>
                                            <button className={'btn btn-lg w-100 rounded ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={()=>actionBtn(data.type)} key={index} disabled={is_disabled}>
                                                {
                                                    data.icon !== '' &&
                                                    <i className={data.icon + ' mr-2'}></i>    
                                                }
                                                {data.title}
                                            </button>
                                        </>
                                    ))
                                }
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}