import { FormControl, MenuItem, Select } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import ViewField from "../../../../components/viewField";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from "react";
import Base from "../../../../utils/base";

export default function AcademyForm({
    title,
    disabled,
    ///////////
    ipt_academy,
    handleAcademy,
    masterAcademyLevel,
    ipt_school,
    set_ipt_school,
    ipt_major,
    handleMajor,
    masterMajor,
    ipt_start,
    set_ipt_start,
    ipt_finish,
    set_ipt_finish,
    ipt_description,
    set_ipt_description,
    ipt_score,
    set_ipt_score
}){
    var base = new Base()
    const [masterSchool, set_vmasterSchool] = useState([]) 

    useEffect(async ()=>{
        base.$('#preloader_modal').modal('show')
        var submitSchool = {
            ideducation: ipt_academy
        }
        var url_school = '/hris/loadschool'
        var response_masterSchool = await base.request(url_school,'post',submitSchool)
        if (response_masterSchool.status=='success') {
            base.$('#preloader_modal').modal('show')
            var data = response_masterSchool.loadschool
            set_vmasterSchool(data)   
        }
        base.hidePreloader()
    },[])  

    return(
        <div className={'custom-tabs-bg-solusiqu p-3 mb-3'}>
            <h5>{title}</h5>
            <div className="form-group row">
                <div className="col row">
                    <label className='custom-input-solusiqu col-6 col-form-label'>Academy</label>
                </div>
                <div className="col-12">
                    <FormControl 
                        required
                        disabled={disabled}
                        sx={{ width:'100%',
                            "& .MuiInputBase-input": {
                                fontSize: '0.9rem',
                            }
                        }}
                    >
                        <Select
                            size='small'
                            value={ipt_academy}
                            onChange={handleAcademy}
                        >
                            {
                            masterAcademyLevel?.map((data,index)=>(
                                <MenuItem key={index} value={data.CIDEDUCATION}>{data.CEDUCATION}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='form-group row'>
                <div className="col row">
                    <label className='custom-input-solusiqu col-6 col-form-label'>School/Campus</label>
                </div>
                <div className="col-12">
                    <Autocomplete
                        onChange={set_ipt_school}
                        value={ipt_school}          
                        disabled={disabled}             	
                        options={masterSchool}
                        size='small'
                        getOptionLabel={(option) => option.CSCHOOL ? option.CSCHOOL: ""}
                        isOptionEqualToValue={(option, value) => option.CIDSCHOOL===value.CIDSCHOOL||value===''}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.CIDSCHOOL}>
                                {option.CSCHOOL}
                              </li>
                            );
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width:'100%',
                            "& .MuiInputBase-input": {
                                fontSize: '0.9rem',
                                textTransform:'uppercase',
                            }
                        }}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className="col row">
                    <label className='custom-input-solusiqu col-6 col-form-label'>Major</label>
                </div>
                <div className="col-12">
                    <FormControl 
                        required
                        disabled={disabled}
                        sx={{ width:'100%',
                            "& .MuiInputBase-input": {
                                fontSize: '0.9rem'
                            }
                        }}
                    >
                        <Select
                            size='small'
                            value={ipt_major}
                            onChange={handleMajor}
                        >
                            {
                            masterMajor?.map((data,index)=>(
                                <MenuItem key={index} value={data.CIDMAJOR}>{data.CMAJOR}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="form-group row pt-4">
                <div className="col-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            value={ipt_start}
                            disabled={disabled}
                            label='Start'
                            size='small'
                            views={['year', 'month', 'day']}
                            onChange={set_ipt_start}
                            sx={{width: '100%',
                                "& .MuiInputBase-input": {
                                    height: '0.35em',
                                    fontSize: '0.9rem'
                                }
                            }}
                        />
                    </LocalizationProvider>
                </div>
                <div className="col-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            value={ipt_finish}
                            disabled={disabled}
                            label='Finish'
                            size='small'
                            views={['year', 'month', 'day']}
                            onChange={set_ipt_finish}
                            sx={{width: '100%',
                                "& .MuiInputBase-input": {
                                    height: '0.35em',
                                    fontSize: '0.9rem'
                                }
                            }}
                        />
                    </LocalizationProvider>
                </div>
            </div>
            <ViewField
                data={ipt_description}
                disabled={disabled}
                inputClass='text-uppercase'
                type='text'
                useLabel={1}
                label='Description'
                onChange={set_ipt_description}
                style={{fontSize:'0.9rem'}}
            />
            <ViewField
                data={ipt_score}
                disabled={disabled}
                type='number'
                useLabel={1}
                label='Score'
                onChange={set_ipt_score}
                style={{fontSize:'0.9rem'}}
            />
        </div>
    )
}