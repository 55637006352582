import React, { useEffect, useState } from 'react';
import FormInput from './FormInput';
import Base from '../utils/base';
import { event } from 'jquery';

export default function MaterialScan({type, actionBtn, data_arr, viewFrom}){
    var base = new Base()

    useEffect(async ()=>{
        
    }, [])

    return(
        <>
            <div className='row'>
                <div className='col-12 p-0'>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                <th style={{fontSize : '.75rem'}}>{type === 'inventory' ? 'Inventory' : 'Palette'}</th>                            
                                <th style={{fontSize : '.75rem'}}>Qty</th>                                           
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data_arr.map((data, index)=>(
                                        <tr key={index}>
                                            <td className='align-middle' style={{fontSize : '.75rem'}}>{data.PDB_CDESCRIPTION}</td>                                            
                                            <td className='align-middle' style={{fontSize : '.75rem'}}>{data.quantity}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}