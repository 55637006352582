import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import DB from '../../utils/db';
import { useLiveQuery } from "dexie-react-hooks";
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import FormInput from '../../components/FormInput';

export default function PackagingCheckInput() {
	var base = new Base()

	function useQuery() {
		const { search } = useLocation()
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	let query = useQuery()

	const [action_btn_arr, set_action_btn_arr] = useState([
		{ title: 'Reinput Palette', icon: 'bi bi-upc-scan', btn_type: 'btn-outline-primary', type: 'retype_palette', is_show: true },
		{ title: 'Reinput Product', icon: 'bi bi-upc-scan', btn_type: 'btn-outline-primary', type: 'retype_product', is_show: true },
		{ title: 'Confirmed ' + (query.get('type') === 'remove' || query.get('type') === 'remove_all' ? 'Removing' + (query.get('type') === 'remove_all' ? ' All' : '') : 'Adding') + ' Item', icon: '', btn_type: 'btn-primary', type: 'confirm', is_show: true },
	])

	const [palette_qr_result, set_palette_qr_result] = useState({ PKC_CID: '', PDB_CDESCRIPTION: '', PDB_CBARCODE: '', palette: null })
	const [is_disabled, set_is_disabled] = useState(false)
	const [palette_data, set_palette_data] = useState({ PAL_CID: '', PKC_CID: '', prod_line: { PDL_CNAME: '' } })
	const arr_product = useLiveQuery(
		() => DB.product.orderBy('PDB_CID').toArray()
	);


	useEffect(async () => {
		var token = await localStorage.getItem('token')
		if (token == null) {
			window.location.href = '/auth/login'
		}

		var data_palette = await localStorage.getItem('packing_input_palette')
		data_palette = JSON.parse(data_palette)

		if (data_palette != null) {
			set_palette_data(data_palette)
			// var data_product = []
			var item = data_palette.arr_item

			if (data_palette.RRH_CID != null)
			{
				for (var x in item) {
					item[x].quantity = item[x].P2I_NQTY
					item[x].P2I_CID = item[x].P2I_CID
					await DB.product.add({
						PDB_CID: item[x].RRD_CID,
						PDB_CBARCODE: item[x].P2I_CQRCODE,
						PDB_CDESCRIPTION: item[x].CINVENTORY,
						quantity: parseInt(item[x].P2I_NQTY),
					});
					// data_product.push(item[x].production_barcode)
				}	
			} else
			{
				for (var x in item) {
					item[x].production_barcode.quantity = item[x].P2I_NQTY
					item[x].production_barcode.P2I_CID = item[x].P2I_CID
					await DB.product.add({
						PDB_CID: item[x].production_barcode.PDB_CID,
						PDB_CBARCODE: item[x].production_barcode.PDB_CBARCODE,
						PDB_CDESCRIPTION: item[x].production_barcode.PDB_CDESCRIPTION,
						quantity: item[x].production_barcode.quantity,
					});
					// data_product.push(item[x].production_barcode)
				}
			}

			// var sort = data_product.sort((a, b) => a.PDB_CID > b.PDB_CID ? 1 : -1)
			// set_arr_product(sort)
		}
		else {
			window.history.back()
		}

		// var product_arr = await localStorage.getItem('packing_input_product')
		// if (product_arr != null) {
		// 	product_arr = JSON.parse(product_arr)
		// 	var sort = product_arr.sort((a, b) => a.PDB_CID > b.PDB_CID ? 1 : -1)
		// 	set_arr_product(sort)
		// }
		if (arr_product == null) {

			for (var x in action_btn_arr) {
				if (action_btn_arr[x].type === 'retype_product') {
					action_btn_arr[x].is_show = false
					base.update_array(action_btn_arr, set_action_btn_arr, action_btn_arr[x], x)
				}
			}
		}

		if (query.get('type') === 'remove_all') {
			for (var x in action_btn_arr) {
				if (action_btn_arr[x].type === 'retype_palette') {
					action_btn_arr[x].is_show = false
					base.update_array(action_btn_arr, set_action_btn_arr, action_btn_arr[x], x)
				}
			}
		}

	}, [])

	// useEffect(async () => {
	// 	var product_arr = await localStorage.getItem('packing_input_product')
	// 	if (product_arr == null && arr_product.length > 0)
	// 		localStorage.setItem('packing_input_product', JSON.stringify(arr_product))
	// }, [arr_product])

	async function actionBtn(type) {
		if (type === 'retype_palette') {
			await localStorage.removeItem('packing_input_palette')
			window.location.replace('/packaging/input-id?type=' + query.get('type') + '&input_type=palette')
		}
		else if (type === 'retype_product') {
			// await localStorage.removeItem ('packing_input_product')
			window.location.href = '/packaging/input-id?type=' + query.get('type') + '&input_type=product&reinput=reinput'
		}
		else if (type === 'confirm') {

			if (navigator.onLine) {
				// base.$('#preloader_modal').modal('show')
				set_is_disabled(true)

				var arr_detail = []

				for (var x in arr_product) {
					var flag = 0
					var data_detail = null
					if (arr_product[x].data_type != null) {
						if (arr_product[x].data_type == 'new') {
							flag = 1
							data_detail = {
								barcode: { id: arr_product[x].PDB_CID },
								amount: arr_product[x].quantity
							}
						}
					}

					if (arr_product[x].action_type != null) {
						flag = 1
						data_detail = {
							barcode: { id: arr_product[x].PDB_CID },
							amount: arr_product[x].quantity,
							id: arr_product[x].P2I_CID
						}
					}

					if (flag) {
						if (data_detail != null) {
							arr_detail.push(data_detail)
						}
					}
				}

				var data = {
					arr_detail: arr_detail
				}

				var url = '/palette'
				var method = 'post'

				if (query.get('type') === 'add') {
					if (palette_data.data_type === 'old') {
						data.id = palette_data.PAL_CID
						data.type = 'add'
						method = 'put'
					}
					else {
						data.packing = { id: palette_data.PKC_CID }
					}
				}
				else {
					data.id = palette_data.PAL_CID
					method = 'put'
					data.type = 'remove'
				}

				if (query.get('type') === 'remove_all') {
					var response = await base.request('/palette/all?id=' + palette_data.PAL_CID, 'delete', {})
					if (response != null) {
						if (response.status == 'success') {
							await localStorage.removeItem('packagingData')
							window.location.replace('/success')
						}
						else {
							errorPost()
						}
					}
					else {
						errorPost()
					}
				}
				else {
					if (arr_detail.length > 0) {
						base.$('#preloader_modal').modal('show')
						var response = await base.request(url, method, data)

						base.$('#preloader_modal').modal('hide')
						if (response != null) {
							if (response.status == 'success') {
								await localStorage.removeItem('packagingData')
								window.location.replace('/success')
							}
							else {
								errorPost()
							}
						}
						else {
							errorPost()
						}
					}
				}

			}
			else {
				errorPost()
			}
		}
	}
	//console.log(palette_data)
	function errorPost() {
		base.hidePreloader()
		base.$('#error_modal').modal('show')
		set_is_disabled(false)
	}

	async function addProduct() {
		// var product_arr = await localStorage.getItem('packing_input_product')
		// console.log('test')
		// if(product_arr == null){
		//     await localStorage.setItem('packing_input_product', JSON.stringify(arr_product))
		// }
		window.location.href = '/packaging/input-id?type=' + query.get('type') + '&input_type=product'
	}

	async function backToPalette() {
		// await localStorage.removeItem('packing_input_product')
		DB.product.clear();
		window.history.back()
	}

	return (
		<>
			<div className='page_wrap'>
				<div className='row'>

					<div className='col-12'>
						<PageHeader title={(query.get('type') === 'add' ? 'Add Packaging Process' : query.get('type') === 'remove' || query.get('type') === 'remove_all' ? 'Remove Packaging Item' : 'View Packing Item')} icon={(query.get('type') === 'remove' || query.get('type') === 'remove_all' ? 'bi bi-dash-circle-fill' : null)} style={{ fontSize: '1.25rem', fontWeight: '600' }} is_back_btn={true} customBack={true} backToPage={() => backToPalette()} />
					</div>

					<div className='col-12 mt-3'>
						<div className='row m-0' >
							<div className='col-12 mt-3'>
								<div className='row'>
									<div className='col-12 px-0 d-flex align-items-center'>
										<p className='m-0'>Palette ID</p>
									</div>
									<div className='col-12'>
										<div className='row'>
											<SingleScan type={'palette'} data={palette_data} />
										</div>
									</div>

									<div className='col-12 p-0 mt-3'>
										<div className='row'>
											{
												query.get('type') === 'view' &&
												<div className='col-12 d-flex align-items-center'>
													{palette_data.RRH_CID == null ? <p className='m-0'>Lokasi Palet : {palette_data.prod_line.PDL_CNAME}</p> : ''}
												</div>
											}
											{
												query.get('type') === 'add' ?
													<>
														<div className='col d-flex align-items-center justify-content-end'>
															<button className='btn btn-outline-primary rounded' onClick={() => addProduct()}><i class="bi bi-plus-circle-fill"></i> Add Product</button>
														</div>
													</>
													: query.get('type') === 'remove' ?
														<div className='col d-flex align-items-center justify-content-end'>
															<button className='btn btn-outline-primary rounded' onClick={() => addProduct()}><i class="bi bi-plus-circle-fill"></i> Remove Product</button>
														</div>
														:
														<></>
											}

										</div>
									</div>
									<div className='col-12'>
										<MultipleScan type={'product'} data_arr={arr_product != null ? arr_product : []} viewFrom={palette_data.RRH_CID == null ? 'remove_packing' : 'material'} />
										{console.log(arr_product)}
									</div>

									<div className='col-12 p-0 mt-4'>
										<div className='row'>
											<div className='col-4 d-flex align-items-center'>
												<p className='m-0'>Total</p>
											</div>
											<div className='col-8'>
												<FormInput
													type={'number'}
													value={arr_product != null ? arr_product.length : 0}
													readOnly='readOnly' />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-12 p-0 mt-3' >
								<div className='py-2' style={{ backgroundColor: '#F5F5F5', marginBottom: '200px', }}>
									<p className='m-0 text-center font-weight-bold' style={{ color: '#777777' }}>Total {arr_product != null ? arr_product.length : 0} Product</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>


			{
				query.get('type') !== 'view' &&
				<div className='position-fixed w-100 py-2 shadow-lg' style={{ bottom: 0, backgroundColor: '#FAFAFA' }}>
					<div className='row m-0'>
						<div className='col-12'>
							{
								action_btn_arr.map((data, index) => (
									<>
										{
											data.is_show &&
											<button className={'btn btn-lg w-100 rounded ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={() => actionBtn(data.type)} key={index} disabled={is_disabled}>
												{
													data.icon !== '' &&
													<i className={data.icon + ' mr-2'}></i>
												}
												{data.title}
											</button>
										}
									</>
								))
							}
						</div>
					</div>
				</div>
			}
		</>
	)
}