import { useEffect, useState } from 'react';
import Base from '../utils/base'

export default function AuthHeader({title, is_back_btn}){
    var base = new Base()

    return(
        <>
        <div className='row'>
            <div className='col-7 d-flex align-items-center'>
                <h5 className='m-0'>
                    {
                        is_back_btn &&
                        <i className='fas fa-arrow-left mr-2' style={{cursor : 'pointer'}} onClick={()=>window.history.back()}></i>
                    }
                    {title}
                </h5>
            </div>
            <div className='col-5 d-flex align-items-center justify-content-end'>
                {/* <img src={base.img_logo} style={{width : '8rem'}} /> */}
                <div className='ratio-mx' style={{backgroundImage : 'url(' + base.img_logo + ')'}}></div>
            </div>
        </div>
        </>
    )
}