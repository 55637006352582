import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';

export default function PackagingConfirmData(){
    var base = new Base()

    const [action_btn_arr] = useState([
        {title : 'Rescan Palette/Product', icon : 'bi bi-upc-scan', btn_type : 'btn-outline-primary', type : 'scan'},
        {title : 'Confirmed', icon : '', btn_type : 'btn-primary', type : 'confirm'},
    ])

    const [multiple_qr_result_arr, set_multiple_qr_result_arr] = useState([])
    const [palette_qr_result, set_palette_qr_result] = useState({PKC_CID : '', PDB_CDESCRIPTION : '', PDB_CBARCODE : ''})
    const [is_disabled, set_is_disabled] = useState(false)
    const [palette_data, set_palette_data] = useState({PAL_CID : ''})

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    useEffect(async ()=>{
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        var data_packing = await localStorage.getItem('packagingData')
        data_packing = JSON.parse(data_packing)

        if(data_packing.palette_data != null){
            set_palette_data(data_packing.palette_data)
        }
        set_multiple_qr_result_arr(data_packing.arr_detail)
        set_palette_qr_result(data_packing.packing)

    }, [])

    async function actionBtn(type){
        if(type === 'scan'){
            window.location.replace('/packaging/scan-barcode')
        }
        else if(type === 'confirm'){

            if(navigator.onLine){
                // base.$('#preloader_modal').modal('show')
                set_is_disabled(true)

                var arr_detail = []
                
                for(var x in multiple_qr_result_arr){
                    var data_detail = null

                    if(palette_data.PAL_CID === ''){
                        data_detail = {
                            barcode : multiple_qr_result_arr[x].barcode,
                            amount : multiple_qr_result_arr[x].amount
                        }
                    }
                    else{
                        if(query.get('type') === 'add'){
                            if(multiple_qr_result_arr[x].status_scan === 'new'){
                                data_detail = {
                                    barcode : multiple_qr_result_arr[x].barcode,
                                    amount : multiple_qr_result_arr[x].amount,
                                }
                            }
                        }
                        else{
                            data_detail = {
                                barcode : multiple_qr_result_arr[x].barcode,
                                amount : multiple_qr_result_arr[x].amount,
                                id : multiple_qr_result_arr[x].P2I_CID
                            }
                        }
                    }

                    if(data_detail != null){
                        arr_detail.push(data_detail)
                    }
                }

                var data = {
                    arr_detail : arr_detail
                }
                
                var url = '/palette'
                var method = 'post'
                
                if(query.get('type') === 'add'){
                    if(palette_data.PAL_CID === ''){
                        data.packing = {id : palette_qr_result.PKC_CID}
                    }
                    else{
                        data.id = palette_data.PAL_CID
                        method = 'put'
                        data.type = 'add'
                    }
                }
                else{
                    data.id = palette_data.PAL_CID
                    method = 'put'
                    data.type = 'remove'
                }

                var response = await base.request(url, method, data)
                if(response != null){
                    if(response.status == 'success'){
                        await localStorage.removeItem('packagingData')
                        window.location.replace('/success')
                    }
                    else{
                        errorPost()
                    }
                }
                else{
                    errorPost()
                }
            }
            else{
                errorPost()
            }
        }
    }

    function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
        set_is_disabled(false)
    }

    async function resultActionBtn(type, index){
        if(type === 'remove'){
            base.remove_array(multiple_qr_result_arr, set_multiple_qr_result_arr, index)
        }
    }


    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={'Confirm Data'} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <div className='col-12 mt-3'>
                        <div className='row m-0'>
                            <div className='col-12 mt-3'>
                                <div className='row'>
                                    <div className='col-12 px-0 py-3 d-flex align-items-center' style={{borderBottom : '1px solid #EAEAEA'}}>
                                        <p className='m-0'>Palette ID</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <SingleScan type={'palette'} data={palette_qr_result} />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <MultipleScan type={'palette'} data_arr={multiple_qr_result_arr} actionBtn={(type, index)=>resultActionBtn(type, index)} />
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 p-0 mt-3'>
                                <div className='py-2' style={{backgroundColor : '#F5F5F5'}}>
                                    <p className='m-0 text-center font-weight-bold' style={{color : '#777777'}}>Total {multiple_qr_result_arr.length} Product</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className='position-fixed w-100 py-2 shadow-lg' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                <div className='row m-0'>
                    <div className='col-12'>
                    {
                        action_btn_arr.map((data, index)=>(
                            <button className={'btn btn-lg w-100 rounded ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={()=>actionBtn(data.type)} key={index} disabled={is_disabled}>
                                {
                                    data.icon !== '' &&
                                    <i className={data.icon + ' mr-2'}></i>    
                                }
                                {data.title}
                            </button>
                        ))
                    }
                    </div>
                </div>
            </div>
        </>
    )
}