import { useState } from "react"
import ScrollUp from "../../../components/scrollUp"

export default function TrainingHistory(data){
    const [trainingData, set_historyData]=useState(data.data)

    //console.log(trainingData)
    return(
        <>
        <div className='col-12 py-2'>
            <h5 style={{marginBottom:20}}>Training History</h5>
            <div className='row'>
                <div className='col-12 mb-5'>
                    <div className='row mb-5'>
                    {
                    trainingData.map((data, index)=>(
                        <div className='col-12 py-1 mb-3 custom-tabs-bg-solusiqu' style={{borderBottom : '1px solid #DADDDF', fontSize:'10px'}} key={index}>
                            <h5 className='m-0 text-capitalize mb-2' style={{color : '#0a0a0a', fontSize : '.65rem'}}>{data.CTRAINING}</h5>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{width:'80px'}}>Trainer</td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{data.CTRAINER}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'80px'}}>Date</td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{data.DSTART} - {data.DFINISH}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'80px'}}>Total (Hour)</td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{parseInt(data.NTOTALHOUR)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'80px'}}>Venue</td>
                                        <td style={{width:'5px'}}>:</td>
                                        <td>{data.CVENUE}</td>
                                    </tr>  
                                </tbody>
                            </table>   
                        </div>
                    ))
                    }
                    </div>
                </div>                
            </div>
        </div>
        </>
    )
}