import { useEffect, useState } from 'react';
import SingleScan from './singleScan';
import Base from '../utils/base'
import PaletteList from './paletteList';

export default function ModalNotPass(props) {
	var base = new Base()

	const [arr_reason, set_arr_reason] = useState([])
	const [selected_reason, set_selected_reason] = useState('')

	useEffect(() => {
		get_data()
	}, [])

	async function get_data(){
		var response = await base.request('/reason/all?is_obsolete=0')

		if(response != null){
			if(response.status === "success"){
				set_arr_reason(response.data)
			}
			else
				base.$('#error_modal').modal('show')
		}
		else
			base.$('#error_modal').modal('show')
	}

	async function submit(){
		if(selected_reason === "")
			console.log("Reason is not selected")
		else{
			base.$('#modalNotPass').modal('hide')
			props.onSubmit(selected_reason)
		}
	}

	return (
		<div className="modal fade" id="modalNotPass" tabIndex="-1" aria-labelledby="modalNotPassLabel" aria-hidden="true">
			<div className="modal-dialog modal-dialog-scrollable m-0">
				<div className="modal-content border-0 shadow-sm position-fixed" style={{ bottom: 0, borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}>
					<div class="modal-header" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}>
						<p className='m-0'>Reason for Not Pass</p>
					</div>
					<div className="modal-body">
						<div className='row'>
							<div className='col-12'>
								<div className='form-group'>
									<label>Reason</label>
									<select className='form-control' onChange={(e) => set_selected_reason(e.target.value)}>
										{
											arr_reason.map((reason, index) => (
												<option value={reason.NGR_CID}>{reason.NGR_CDESCRIPTION}</option>
											))
										}
									</select>
								</div>
							</div>
							<div className='col-12 mt-4 p-3'>
								<button className='btn btn-lg btn-primary w-100 rounded text-capitalize' onClick={() => submit()}>
									Submit
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}