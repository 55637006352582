import React, { useEffect, useState } from 'react';
import FormInput from './FormInput';
import Base from '../utils/base';
import { event } from 'jquery';

export default function MultipleScan({type, actionBtn, data_arr, viewFrom}){
    var base = new Base()

    useEffect(async ()=>{
        
    }, [])

    return(
        <>
            <div className='row'>
                <div className='col-12 p-0'>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                <th style={{fontSize : '.75rem'}}>{type === 'product' ? 'Product' : 'Palette'}</th>

                                {
                                    viewFrom !== 'material' &&
                                    <th style={{fontSize : '.75rem'}}>Barcode</th>
                                }

                                <th style={{fontSize : '.75rem'}}>Qty</th>

                                {/*<th style={{fontSize : '.75rem'}}>Action</th>*/}

                                {
                                    viewFrom === 'remove_packing' &&
                                    <th style={{fontSize : '.75rem'}}>Status</th>
                                }

                                {
                                    viewFrom !== 'history' || viewFrom !== 'packaging' &&
                                    <>
                                        <th></th>
                                    </>
                                }
                                {/* {
                                    viewFrom !== 'history' &&
                                    <th></th>
                                } */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data_arr.map((data, index)=>(
                                        <tr key={index}>
                                            <td className='align-middle' style={{fontSize : '.75rem'}}>{data.PDB_CDESCRIPTION}</td>    
                                            
                                            {
                                                viewFrom !== 'material' &&
                                                <td className='align-middle' style={{fontSize : '.75rem'}}>{data.PDB_CBARCODE}</td>
                                            }
                                            <td className='align-middle' style={{fontSize : '.75rem'}}>{data.quantity}</td>

                                            {
                                                viewFrom === 'remove_packing' &&
                                                <>
                                                {
                                                    data.action_type != null ?
                                                    <td className='align-middle' style={{fontSize : '.75rem'}}>Removed</td>
                                                    :
                                                    <td className='align-middle' style={{fontSize : '.75rem'}}>-</td>
                                                }
                                                </>
                                            }
                                            {/* <td className='align-middle'>
                                                <div className='px-2' style={{border : '1px solid #0085FF', backgroundColor : '#EBF8FF', borderRadius : '5rem'}}>
                                                    <p className='m-0 text-primary' style={{fontSize : '.75rem'}}>Potongan</p>
                                                </div>
                                            </td> */}

                                            {
                                                viewFrom !== 'history' || viewFrom !== 'packaging' &&
                                                <>
                                                <td className='align-middle'>
                                                    <button className='btn btn-sm btn-primary rounded' onClick={()=>actionBtn('view', index)}>View</button>
                                                </td>
                                                </>
                                            }
                                            {/* {
                                                viewFrom !== 'history' &&
                                                <td className='align-middle'>
                                                    <button className='btn btn-sm btn-danger' style={{borderRadius : '1rem'}} onClick={()=>actionBtn('remove', index)}><i className='bi bi-trash-fill text-white' style={{fontSize : '.75rem'}}></i></button>
                                                </td>
                                            } */}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}