import { useState, useEffect } from "react";
import PageHeader from "../../../components/pageHeader";

import WrongProcessModal from "../../../components/modalWrongProcess";
import Base from "../../../utils/base";
import dayjs from 'dayjs';

import AcademyForm from "./components/academyForm";

export default function AcademyRevision(){
    var base = new Base()

    const [error_message_modal, set_error_message_modal] = useState('')
    const [disableButton, set_disableButton] = useState(false)

    const [value, setValue] = useState('')
    const handleChange = (event) => {
        setValue(event.target.value)
    }
    const [ipt_emuid, set_ipt_emuid] = useState('')
    //////////
    const [masterAcademyLevel, set_masterAcademy] = useState([])
    const [masterMajor, set_masterMajor] = useState([])
    //////////
    const [ipt_academy1, set_ipt_academy1] = useState('')
    const handleAcademy1 = (event) =>{
        set_ipt_academy1(event.target.value)
    }
    const [ipt_school1, set_ipt_school1] = useState('')
    //const [ipt_school1_inputValue, set_ipt_school1_inputValue] = useState('')
    const [ipt_major1, set_ipt_major1] = useState('')
    const handleMajor1 = (event)=>{
        set_ipt_major1(event.target.value)
    }
    const [ipt_start1, set_ipt_start1] = useState('')
    const [ipt_finish1, set_ipt_finish1] = useState('')
    const [ipt_score1, set_ipt_score1] = useState(0)
    const [ipt_description1, set_ipt_description1] = useState('')

    const [ipt_academy2, set_ipt_academy2] = useState('')
    const handleAcademy2 = (event) =>{
        set_ipt_academy2(event.target.value)
    }
    const [ipt_school2, set_ipt_school2] = useState('')
    const [ipt_major2, set_ipt_major2] = useState('')
    const handleMajor2 = (event)=>{
        set_ipt_major2(event.target.value)
    }
    const [ipt_start2, set_ipt_start2] = useState('')
    const [ipt_finish2, set_ipt_finish2] = useState('')
    const [ipt_score2, set_ipt_score2] = useState(0)
    const [ipt_description2, set_ipt_description2] = useState('')

    const [ipt_academy3, set_ipt_academy3] = useState('')
    const handleAcademy3 = (event) =>{
        set_ipt_academy3(event.target.value)
    }
    const [ipt_school3, set_ipt_school3] = useState('')
    const [ipt_major3, set_ipt_major3] = useState('')
    const handleMajor3 = (event)=>{
        set_ipt_major3(event.target.value)
    }
    const [ipt_start3, set_ipt_start3] = useState('')
    const [ipt_finish3, set_ipt_finish3] = useState('')
    const [ipt_score3, set_ipt_score3] = useState(0)
    const [ipt_description3, set_ipt_description3] = useState('')

    useEffect(async ()=>{
        // await localStorage.clear()
        await base.cek_pwa_version()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                getData()
            }
        }
        //console.log(ipt_school1)
    }, [])

    async function getData(){
        base.$('#preloader_modal').modal('show')
        var cidemployee = await localStorage.getItem('psn_cid')

        /////////// master data
        var url_education = '/hris/loadeducation'
        var url_major = '/hris/loadmajor'

        var response_masterEdu = await base.request(url_education,'post')
        if (response_masterEdu.status=='success') {
            set_masterAcademy(response_masterEdu.loadeducation)
            console.log(response_masterEdu)
        }

        var response_masterMajor = await base.request(url_major,'post')
        if (response_masterMajor.status=='success') {
            set_masterMajor(response_masterMajor.loadmajor)
            console.log(response_masterMajor)
        }
        ///////////
        var url_trans = '/hris/employeeupdateview'
        var dataSubmit = {
            cidemployee,
            npage:4
        }
        var response_trans = await base.request(url_trans,'post',dataSubmit)
        if (response_trans.status=='success') {
            var data = response_trans.employeeupdateview
            for(let x in data){
                if (data[x]==null) {
                    data[x]=''
                }
            }
            set_ipt_score1(data.EMU_NFINALSCORE1)
            set_ipt_description1(data.EMU_CDESCRIPTION1)
            set_ipt_start1(dayjs(data.EMU_DSTART1))
            set_ipt_finish1(dayjs(data.EMU_DFINISH1))
            set_ipt_major1(data.EMU_CIDMAJOR1)
            set_ipt_academy1(data.EMU_CIDEDUCATION1)

            set_ipt_score2(data.EMU_NFINALSCORE2)
            set_ipt_description2(data.EMU_CDESCRIPTION2)
            set_ipt_start2(dayjs(data.EMU_DSTART2))
            set_ipt_finish2(dayjs(data.EMU_DFINISH2))
            set_ipt_major2(data.EMU_CIDMAJOR2)
            set_ipt_academy2(data.EMU_CIDEDUCATION2)

            set_ipt_score3(data.EMU_NFINALSCORE3)
            set_ipt_description3(data.EMU_CDESCRIPTION3)
            set_ipt_start3(dayjs(data.EMU_DSTART3))
            set_ipt_finish3(dayjs(data.EMU_DFINISH3))
            set_ipt_major3(data.EMU_CIDMAJOR3)
            set_ipt_academy3(data.EMU_CIDEDUCATION3)

            if (data.EMU_CID) {
                set_ipt_emuid(data.EMU_CID)
            }

            console.log(data)

            var submitSchool1 = {
                idschool: data.EMU_CIDSCHOOL1
            }    
            var submitSchool2 = {
                idschool: data.EMU_CIDSCHOOL2
            }    
            var submitSchool3 = {
                idschool: data.EMU_CIDSCHOOL3
            }
            
            var url_school = '/hris/getschoolbyID'
            if (submitSchool1.idschool) {
                var response_dataschool1 = await base.request(url_school,'post',submitSchool1)
                if (response_dataschool1.status=='success') {
                    var data_school1 = response_dataschool1.loadschool
                    set_ipt_school1(data_school1)
                    console.log(data_school1)
                }     
            }
            if (submitSchool2.idschool) {
                var response_dataschool2 = await base.request(url_school,'post',submitSchool2)
                if (response_dataschool2.status=='success') {
                    var data_school2 = response_dataschool2.loadschool
                    set_ipt_school2(data_school2)
                }    
            }
            if (submitSchool3.idschool) {
                var response_dataschool3 = await base.request(url_school,'post',submitSchool3)
                if (response_dataschool3.status=='success') {
                    var data_school3 = response_dataschool3.loadschool
                    set_ipt_school3(data_school3)
                }    
            }
        }
        base.hidePreloader()
    }

    async function actSubmit(){
        var dataSubmit = {
            cidemployee : await localStorage.getItem('psn_cid'),
            npage : 4,
            emu_cid : ipt_emuid,

            emu_cideducation1 : ipt_academy1,
            emu_cidschool1 : ipt_school1.CIDSCHOOL,
            emu_cdescription1 : ipt_description1?ipt_description1.toUpperCase():null,
            emu_dstart1 : ipt_start1.$y?base.formatDate(ipt_start1.$y,ipt_start1.$M+1,ipt_start1.$D):null,
            emu_dfinish1 : ipt_finish1.$y?base.formatDate(ipt_finish1.$y,ipt_finish1.$M+1,ipt_finish1.$D):null,
            emu_cidmajor1 : ipt_major1,
            emu_nfinalscore1 : ipt_score1,

            emu_cideducation2 : ipt_academy2,
            emu_cidschool2 : ipt_school2.CIDSCHOOL,
            emu_cdescription2 : ipt_description2?ipt_description2.toUpperCase():null,
            emu_dstart2 : ipt_start2.$y?base.formatDate(ipt_start2.$y,ipt_start2.$M+1,ipt_start2.$D):null,
            emu_dfinish2 : ipt_finish2.$y?base.formatDate(ipt_finish2.$y,ipt_finish2.$M+1,ipt_finish2.$D):null,
            emu_cidmajor2 : ipt_major2,
            emu_nfinalscore2 : ipt_score2,

            emu_cideducation3 : ipt_academy3,
            emu_cidschool3 : ipt_school3.CIDSCHOOL,
            emu_cdescription3 : ipt_description3?ipt_description3.toUpperCase():null,
            emu_dstart3 : ipt_start3.$y?base.formatDate(ipt_start3.$y,ipt_start3.$M+1,ipt_start3.$D):null,
            emu_dfinish3 : ipt_finish3.$y?base.formatDate(ipt_finish3.$y,ipt_finish3.$M+1,ipt_finish3.$D):null,
            emu_cidmajor3 : ipt_major3,
            emu_nfinalscore3 : ipt_score3
        }
        set_disableButton(true)
        var url = '/hris/employeeupdatepost'
        var response = await base.request(url,'post',dataSubmit)
        if (response.status=='success') {
            window.location.replace('/success')
        }else{
            base.hidePreloader()
            set_error_message_modal('failed to send data')
            base.$('#modalWrongProcess').modal('show')
        }
        console.log(dataSubmit)

    }

    async function backToParent() {
		// await localStorage.removeItem('packing_input_product')
		// DB.product.clear();
		window.history.back()
	}

    return(
        <div className='page_wrap' style={{marginTop:60}}>
            <div className='row'>
                <div className='fixed-top page_wrap shadow-md' style={{backgroundColor: '#FFF', height:100}}>
                    <div className='pt-5'>
                        <PageHeader title='Academy Data' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} customBack={true} backToPage={() => backToParent()}/>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <AcademyForm
                        title='Academy 1'
                        disabled={disableButton}
                        ipt_academy={ipt_academy1}
                        handleAcademy={handleAcademy1}
                        masterAcademyLevel={masterAcademyLevel}
                        ipt_school={ipt_school1}
                        set_ipt_school={(event, value) => set_ipt_school1(value)}
                        ipt_major={ipt_major1}
                        handleMajor={handleMajor1}
                        masterMajor={masterMajor}
                        ipt_start={ipt_start1}
                        set_ipt_start={(newValue) => set_ipt_start1(newValue)}
                        ipt_finish={ipt_finish1}
                        set_ipt_finish={(newValue) => set_ipt_finish1(newValue)}
                        ipt_description={ipt_description1}
                        set_ipt_description={(e) => set_ipt_description1(e.target.value)}
                        ipt_score={ipt_score1}
                        set_ipt_score={(e) => set_ipt_score1(e.target.value)}
                    />
                    <AcademyForm
                        title='Academy 2'
                        disabled={disableButton}
                        ipt_academy={ipt_academy2}
                        handleAcademy={handleAcademy2}
                        masterAcademyLevel={masterAcademyLevel}
                        ipt_school={ipt_school2}
                        set_ipt_school={(event, value) => set_ipt_school2(value)}
                        ipt_major={ipt_major2}
                        handleMajor={handleMajor2}
                        masterMajor={masterMajor}
                        ipt_start={ipt_start2}
                        set_ipt_start={(newValue) => set_ipt_start2(newValue)}
                        ipt_finish={ipt_finish2}
                        set_ipt_finish={(newValue) => set_ipt_finish2(newValue)}
                        ipt_description={ipt_description2}
                        set_ipt_description={(e) => set_ipt_description2(e.target.value)}
                        ipt_score={ipt_score2}
                        set_ipt_score={(e) => set_ipt_score2(e.target.value)}
                    />
                    <AcademyForm
                        title='Academy 3'
                        disabled={disableButton}
                        ipt_academy={ipt_academy3}
                        handleAcademy={handleAcademy3}
                        masterAcademyLevel={masterAcademyLevel}
                        ipt_school={ipt_school3}
                        set_ipt_school={(event, value) => set_ipt_school3(value)}
                        ipt_major={ipt_major3}
                        handleMajor={handleMajor3}
                        masterMajor={masterMajor}
                        ipt_start={ipt_start3}
                        set_ipt_start={(newValue) => set_ipt_start3(newValue)}
                        ipt_finish={ipt_finish3}
                        set_ipt_finish={(newValue) => set_ipt_finish3(newValue)}
                        ipt_description={ipt_description3}
                        set_ipt_description={(e) => set_ipt_description3(e.target.value)}
                        ipt_score={ipt_score3}
                        set_ipt_score={(e) => set_ipt_score3(e.target.value)}
                    />
                    <div className={'mt-2 float-right'}>
                        <button className='btn btn-success rounded' 
                                disabled={disableButton}
                                onClick={actSubmit}
                        >Save
                        </button>
                    </div>
                </div>
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal}/>
        </div>
    )
}