import * as React from 'react';
import { useEffect, useState } from 'react';
import Base from '../../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';
import { useLiveQuery } from "dexie-react-hooks";
import Dexie from 'dexie'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import PageHeader from "../../../components/pageHeader"
import { Checkbox, TextareaAutosize } from '@mui/material';
import WrongProcessModal from '../../../components/modalWrongProcess';

export default function ViewOvertime(){

    var base = new Base()    

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const db = new Dexie('permission')
    db.version(1).stores({
        detail: 'id_detail, dpermission, tstart, tfinish, dabsentee',
        header: 'id_header, drequest, cnorequest, cnotes'
    })
    const [error_message_modal, set_error_message_modal] = useState('')

    const [viewOvertimeType, set_viewOvertimeType] = useState([''])
    const [viewStatus, set_viewStatus] = useState(query.get('type'))
    const [readonlyValue, set_readonlyValue] = useState(false)

    const [actionDateDetail, set_actionDateDetail] = useState(dayjs(new Date()))

    /*const [overtimeTypeValue, set_overtimeTypeValue] = useState('')
    const handleOvertimeType = (event) => {
        set_overtimeTypeValue(event.target.value);
    };*/

    const [cemployee, set_cemployee] = useState('')
    const [timeStart_Detail, set_timeStartD] = useState('')
    const handleTimeStartD = (event) => {
        set_timeStartD(event.target.value);
    };
    const [custom_timeStart, set_custom_timeStart] = useState('')

    const [timeDiff_Detail, set_timeDiffD] = useState('')
    const handleTimeDiffD = (event) => {
        set_timeDiffD(event.target.value);
    };
    const [custom_timeFinish, set_custom_timeFinish] = useState('')

    const [permissionNotes, set_permissionNotes] = useState('')
    const onNotesChange = (event) =>
    {
        set_permissionNotes(event.target.value)
    };  
    
    const [flagApprove, set_flagApprove] = useState('')

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '../auth/login'
        }
        else {
            if(navigator.onLine){
                getOvertimeType()
                getViewData()

                if(viewStatus.startsWith('view')){
                    set_readonlyValue(true)
                }else if(viewStatus=='create'){
                    set_readonlyValue(false)
                }
            }
        }
    }, [])

    async function getOvertimeType(){
        var url = '/hris/loadovertimetype'//?cidemployee='+cidemployee+'&nmonth='+(iptMonth.$M+1)+'&nyear='+iptMonth.$y
        var response = await base.request(url,'post')
        //console.log(url)
        if(response != null){
            if(response.status == 'success'){                

                var overtimeType = response.loadovertimetype
                
                base.hidePreloader()
                set_viewOvertimeType(overtimeType)

                console.log(overtimeType)     
            }
        }
    }

    async function getViewData(){
        var id = query.get('id')
        var dataurl = {
            id
        }
        var url = '/hris/overtimeview'
        var response = await base.request(url,'post',dataurl)
        //console.log(url)
        if(response != null){
            if(response.status == 'success'){                

                var dataView = response.overtimeview[0]
                if(viewStatus.startsWith('view')){
                    dataView.CUSTOM_OVS_TSTART = new Date('1999-01-01 '+dataView.OVS_TSTART).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    dataView.CUSTOM_OVS_TFINISH = new Date('1999-01-01 '+dataView.OVS_TFINISH).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    //dataView.OVS_TSTART = new Date('1999-01-01 '+dataView.OVS_TSTART).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    //dataView.OVS_TFINISH = new Date('1999-01-01 '+dataView.OVS_TFINISH).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })
                    set_permissionNotes(dataView.OVH_CNOTES)
                    //set_positionValue(dataView.PMS_NPOSITION)
                    //set_overtimeTypeValue(dataView.OVT_CID)
                    set_actionDateDetail(dayjs(dataView.OVS_DDATE))
                    //set_absenteeDateDetail(dayjs(dataView.PMS_DABSENTEE))
                    set_timeStartD(dataView.OVS_TSTART)
                    set_timeDiffD(dataView.OVS_TFINISH)
                    set_custom_timeStart(dataView.CUSTOM_OVS_TSTART)
                    set_custom_timeFinish(dataView.CUSTOM_OVS_TFINISH)
                    set_flagApprove(dataView.OVH_NFLAGAPPROVE)
                    set_cemployee({CEMPLOYEE: dataView.CEMPLOYEE, CNIP: dataView.CNIP})
                }

                base.hidePreloader()
                
                console.log(dataView)     
            }
        }
    }

    async function editClicked()
    {
        set_readonlyValue(prevState => !prevState)
        console.log(readonlyValue)
    }

    async function saveClicked(){
        var dataSubmit = null        

        dataSubmit = {
            cidemployee : await localStorage.getItem('psn_cid'),
            ovh_cid : query.get('id')?query.get('id'):'',
            daction : base.formatDate(actionDateDetail.$y,(actionDateDetail.$M + 1),actionDateDetail.$D),
            //cidtype_ovt : overtimeTypeValue,
            timeStart : timeStart_Detail,
            timeFinish : timeDiff_Detail,
            cnotes : permissionNotes.toUpperCase()
        }
        //console.log(dataSubmit)

        //dataSubmit.push(dataTmp)
        if(!dataSubmit.daction||!dataSubmit.timeStart||!dataSubmit.timeFinish||!dataSubmit.cnotes){
            if(!dataSubmit.daction){
                set_error_message_modal('Request date is empty')
            }else if(!dataSubmit.timeStart){
                set_error_message_modal('Time start is empty')
            }else if(!dataSubmit.timeFinish){
                set_error_message_modal('Time finish is empty')
            }else if(!dataSubmit.cnotes){
                set_error_message_modal('Note is empty')
            }else{
                set_error_message_modal('Data is empty')
            }
            base.$('#modalWrongProcess').modal('show')
        }else{
            if(window.confirm('Data can\'t change after submit, are you sure?')){
                var url = '/hris/overtimepost'
                base.$('#preloader_modal').modal('show')

                if(navigator.onLine){

                    var response = await base.request(url, 'post', dataSubmit)
        
                    base.$('#preloader_modal').modal('hide')
                    if(response != null){
                        if(response.status == 'success'){
                            let resultId = response.overtimepost.ROVH_CID 
                            base.hidePreloader()
                            window.location.replace('/success?bb=3&id=' + resultId)
                            //window.location.replace('/success?bb=1')
                        }
                        else{
                            base.hidePreloader()
                            set_error_message_modal(response.message)
                            base.$('#modalWrongProcess').modal('show')
                        }
                    }
                    else{
                        errorPost()
                    }
                }
                else{
                    errorPost()
                }
                console.log(dataSubmit)
            }
        }
        //set_readonlyValue(prevState => !prevState)
    }

    async function submitClicked(){
        base.$('#preloader_modal').modal('show')
        var url = '/hris/overtimeapproval'
        var dataSubmit = {
            id : query.get('id')??'',
            NFLAGAPPROVE : 5,
            RCNIP : cemployee.CNIP,
            RCEMPLOYEE : cemployee.CEMPLOYEE,
            RTSTART : custom_timeStart,
            RTEND : custom_timeFinish,
            RDOVERTIME : base.formatDate(actionDateDetail.$y,(actionDateDetail.$M + 1),actionDateDetail.$D),
            RCNOTES : permissionNotes.toUpperCase()
        }
        console.log(dataSubmit)

        if (dataSubmit.id) {
            var response = await base.request(url, 'post', dataSubmit)
            if (response.status=='success') {
                let resultId = dataSubmit.id
                window.location.replace('/success?bb=3&id=' + resultId)
            }
        }
        base.hidePreloader()
    }

    async function cancelClicked()
    {
        if(viewStatus=='view'){
            set_readonlyValue(prevState => !prevState)
            window.location.reload();
        }else if(viewStatus=='create'){
            //set_readonlyValue(prevState => !prevState)
            window.location.replace('/ess/overtime')
        }
    } 
    
    async function deleteClicked()
    {    
        var dataSubmit = {
            id: query.get('id')?query.get('id'):''
        }

        if (dataSubmit.id) {
            if(window.confirm('are you sure to delete this data?')){
                if(navigator.onLine){
                    var url = '/hris/overtimedelete'
                    var response = await base.request(url, 'post', dataSubmit)
                    if(response != null){
                        if(response.status == 'success'){
                            base.hidePreloader()                 
                            window.location.replace('/success?bb=3.1')
                            //window.location.replace('/success?bb=1')
                        }
                        else{
                            
                            base.hidePreloader()
                            
                            base.$('#modalWrongProcess').modal('show')
                        }
                    }
                    else{
                        errorPost()
                    }
                }
                else{
                    errorPost()
                }
            }
        }
    }  
    
    function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>
                    <div className='pt-5 fixed-top page_wrap' style={{backgroundColor:'#FFFFFF',height:80}}>
                        <PageHeader title='Overtime' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>
                    <br/><br/>
                    <div className='col-12 mt-4 mb-3'>
                        <div className="form-group">
                            <label className='form-label'>Request Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    value={actionDateDetail}
                                    size='small'
                                    readOnly={readonlyValue}
                                    views={['year', 'month', 'day']}
                                    onChange={(newValue) => set_actionDateDetail(newValue)}
                                    sx={{width: '100%',
                                        "& .MuiInputBase-input": {
                                            height: '0.35em'
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        {/*<div class="form-group">
                            <label>Type</label>
                            <Select
                                className='rounded'
                                value={overtimeTypeValue}
                                onChange={handleOvertimeType}
                                displayEmpty
                                readOnly={readonlyValue}
                                inputProps={{ 'aria-label': 'Without label' }}
                                size='small'
                                sx={{width:'100%',fontSize:'14px'}}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                            viewOvertimeType.map((data)=>(
                                <MenuItem value={data.OVT_CID}>{data.OVT_CNAME}</MenuItem>
                            ))
                            }
                            </Select>
                        </div>*/}
                        <div className="form-group">
                            <label className='form-label'>Time Start</label>
                            <input type={viewStatus.startsWith('view')&&readonlyValue?'text':'time'} 
                                    className='form-control rounded'
                                    value={readonlyValue?custom_timeStart:timeStart_Detail}
                                    onChange={handleTimeStartD}
                                    readOnly={readonlyValue}
                                    
                            />
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Time Finish</label>
                            <input type={viewStatus.startsWith('view')&&readonlyValue?'text':'time'} 
                                    className='form-control rounded'
                                    value={readonlyValue?custom_timeFinish:timeDiff_Detail}
                                    onChange={handleTimeDiffD}
                                    readOnly={readonlyValue}
                                    
                            />
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Notes</label>
                            <TextareaAutosize 
                                className='rounded'
                                defaultValue={permissionNotes}
                                onChange={(event) => onNotesChange(event)}
                                size='small'
                                readOnly={readonlyValue}
                                style={{width:'100%', textTransform:'uppercase'}}
                                minRows='4'
                                maxRows='6'
                            />
                        </div>
                    </div>
                    <div className='form-group col-12'>
                        {
                            ((readonlyValue === true)&&(viewStatus==='view')&&(flagApprove===0)) &&
                            <>    
                                <button className='btn btn-info rounded float-right rounded ml-2' onClick={()=> editClicked()}>Edit</button>
                                <button className='btn btn-danger rounded float-right rounded ml-2' onClick={()=> deleteClicked()}>Delete</button>
                                <button className='btn btn-success rounded float-right rounded' onClick={()=> submitClicked()}>Submit</button>
                            </> 
                        }
                        {
                            readonlyValue === false &&
                            <>
                                <button className='btn btn-secondary rounded float-right rounded ml-2' onClick={()=> cancelClicked()}>Cancel</button>
                                <button className='btn btn-success rounded float-right rounded' onClick={()=> saveClicked()}>Save</button>
                                

                            </>                         
                        }
                    </div>
                </div>
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal} />
        </>
    )
}