import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MaterialDetail from './materialDetail';
import MaterialDetailRollback from './materialDetailRollback';
import MaterialDetailRollbackNonQty from './materialDetailNonQty';
import PageHeader from '../../components/pageHeader';

export default function MaterialCheckInput(){
    var base = new Base()

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [action_btn_arr, set_action_btn_arr] = useState([
        {title : 'Reinput Palette', icon : 'bi bi-upc-scan', btn_type : 'btn-outline-primary', type : 'retype_palette'},
        {title : 'Confirm', icon : '', btn_type : 'btn-primary', type : 'confirm'},
    ])

    const [palette_qr_result, set_palette_qr_result] = useState({PKC_CID : '', PDB_CDESCRIPTION : '', PDB_CBARCODE : '', palette : null})
    const [is_disabled, set_is_disabled] = useState(false)
    const [palette_data, set_palette_data] = useState({PAL_CID : '', PKC_CID : ''})
    const [arr_product, set_arr_product] = useState([])
    const [submit_data, set_submit_data] = useState({CIDRRDT : '', NQTY_TAKEN : ''})

    useEffect(async ()=>{
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        var data_palette = await localStorage.getItem('packing_input_palette')
        data_palette = JSON.parse(data_palette)

        if(data_palette != null){
            //console.log(data_palette)
            var data_product = []
            
            if(query.get('type') === 'material_back'){
                var item = data_palette
                
                for(var x in item){
                    //console.log(item[x])
                    item[x].PAL_CID = item[x].CIDTRANSFER
                    item[x].PKC_CID = item[x].CIDTRANSFER
                    data_product.push(item[x])
                }
                set_arr_product(data_product)
                set_palette_data(data_palette[0])
                console.log(data_product)
            }
            else{
                set_palette_data(data_palette)
                console.log(palette_data)
                var item = data_palette.arr_item

                for(var x in item){
                    //console.log(item[x])
                    item[x].PAL_CID = item[x].P2I_CIDPALETTE
                    //item[x].PDB_CDESCRIPTION = item[x].rrdt.CINVENTORY
                    //item[x].quantity = parseInt(item[x].P2I_NQTY)
                    item[x].qtyTake = 0
                    data_product.push(item[x])
                }
                set_arr_product(data_product)
            }
            console.log(data_product)
        }
        else{
            window.history.back()
        }
    }, [])

    async function actionBtn(type){
        if(type === 'retype_palette'){
            await localStorage.removeItem('packing_input_palette')
            window.location.replace('/packaging/input-id?type=' + query.get('type') + '&input_type=palette')
        }
        else if(type === 'confirm'){
            var item = palette_data.arr_item
            var data_product = []
            for(var x in item){
                //console.log(item[x])
                //data_product.push(item[x])
            }
            console.log(palette_data.arr_item)
        }
    }

    function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
        set_is_disabled(false)
    }

    async function addProduct(){
        window.location.href = '/packaging/input-id?type=' + query.get('type') + '&input_type=product'
    }

    function onBackPressed(){
        localStorage.removeItem('packing_input_palette')
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                    <PageHeader title={(query.get('type') === 'material_back' ? 'Material Return (All)' : query.get('type') === 'material_back1' ? 'Material Return (Partial)' : 'Material')} icon={(query.get('type') === 'remove' ? 'bi bi-dash-circle-fill' : null)} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} onBack={() => onBackPressed()}/>
                    </div>

                    <div className='col-12 mt-3'>
                        <div className='row m-0'>
                            <div className='col-12 mt-3'>
                                <div className='row'>
                                    <div className='col-12 px-0 d-flex align-items-center'>
                                        <p className='m-0'>Palette ID</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <SingleScan type={'palette'} data={palette_data} />
                                        </div>
                                    </div>

                                    <div className='col-12 p-0 mt-3'>
                                        <div className='row'>
                                            <div className='col d-flex align-items-center'>
                                                <p className='m-0'>Product</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                    {query.get('type') === 'material_back' ? (
                                            <MaterialDetailRollback type={'product'} data_arr={arr_product} viewFrom={'material'}/>
                                        ) : query.get('type') === 'material_back1' ? (<MaterialDetailRollbackNonQty type={'product'} data_arr={arr_product} viewFrom={'material'}/>
                                        ) : (
                                            <MaterialDetail type={'product'} data_arr={arr_product} viewFrom={'material'}/>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>

                            {/*<div className='col-12 p-0 mt-3'>
                                <div className='py-2' style={{backgroundColor : '#F5F5F5'}}>
                                    <p className='m-0 text-center font-weight-bold' style={{color : '#777777'}}>Total {arr_product.length} Product</p>
                                </div>
                            </div>*/}
                        </div>
                    </div>

                </div>
            </div>


            {/*
                query.get('type') !== 'view' &&
                <div className='position-fixed w-100 py-2 shadow-lg' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                    <div className='row m-0'>
                        <div className='col-12'>
                        {
                            action_btn_arr.map((data, index)=>(
                                <>
                                    <button className={'btn btn-lg w-100 rounded ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={()=>actionBtn(data.type)} key={index} disabled={is_disabled}>
                                        {
                                            data.icon !== '' &&
                                            <i className={data.icon + ' mr-2'}></i>    
                                        }
                                        {data.title}
                                    </button>
                                </>
                            ))
                        }
                        </div>
                    </div>
                </div>
                    */}
        </>
    )
}