import { useEffect, useCallback, useRef, useState } from 'react';
import Base from '../../utils/base';

import DB from '../../utils/db';
import { useLiveQuery } from "dexie-react-hooks";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import FormInput from '../../components/FormInput';
import WrongProcessModal from '../../components/modalWrongProcess'; 

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ViewField from '../../components/viewField';


export default function ProfileEmployee(){

	var base = new Base()    

    const [selectedFile, set_selectedFile] = useState('')

    const [user_data, set_user_data] = useState({})
    /*const user_data = useLiveQuery(
      () => DB.employee.orderBy('CEMPLOYEE').toArray()
    );*/
    //const [nleaveremain, set_nleaveremain] = useState({NREALSISA : ''})
    const [ciduser, set_ciduser] = useState([])
    const [employee_picture, set_employee_picture] = useState('')
    const [uri, set_uri] = useState('')
    const [location, set_location] = useState({latitude: '', longitude : ''})

    const [employee_data, set_employee_data] = useState([]) 
    const [empdata, set_empdata] = useState([]) 
    const [emp2data, set_emp2data] = useState([])

    const [is_button_disable, set_is_button_disable] = useState(false)
    const [error_message_modal, set_error_message_modal] = useState('')
    const [alert_data, set_alert_data] = useState({message : '', type : ''})

    const [greeting, set_greeting] = useState('')

    const [register_form_arr, set_register_form_arr] = useState([        
        {label : 'Employee Name', input_type : 'text', type : 'username', value : '', is_error : false, readonly :'readonly'},      
        {label : 'Old Password', input_type : 'password', type : 'old_password', value : '', is_error : false, is_show : false},
        {label : 'New password', input_type : 'password', type : 'password', value : '', is_error : false, is_show : false},
        {label : 'Repeat New Password', input_type : 'password', type : 'retype_password', value : '', is_error : false, is_show : false},
    ])

    const [time, setTime] = useState(0);

    // Hours calculation
    const hours = Math.floor(time / 3600);

    // Minutes calculation
    const minutes = Math.floor((time % 3600) / 60);

    // Seconds calculation
    const seconds = Math.floor((time % 60) / 1);       

	useEffect(async ()=>{
        // await localStorage.clear()
        base.cek_pwa_version()        

        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }
        else {
            if(navigator.onLine){
                get_data()
            }
            else{
                var CIDUSER = 'OFFLINE'
                var arrciduser= {CIDUSER}                
                await set_ciduser(arrciduser)
            }
        }

    }, [])

    useEffect(() => {
      let intervalId;
      
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 1), 1000);
      
      return () => clearInterval(intervalId);
    }, [time]);

    async function get_data(){
        base.$('#preloader_modal').modal('show')        

        var cidemployee = await localStorage.getItem('psn_cid')
        //console.log(cidemployee)
        var data= {
                    cidemployee
                  }
        var url = '/hris/employeeprofile' //?cidemployee='+cidemployee
        var response = await base.request(url, 'post', data)
        console.log(response)

        if(response != null){
            if(response.status == 'success'){
                var data = response.employee
                //var dataLeaveRemain = response.nleaveremain 
                var ciduser = response.data
                console.log(data)
                await set_ciduser(ciduser)
                console.log(ciduser)                
                
                //set_nleaveremain(dataLeaveRemain)

                //data.NLEAVEREMAIN = dataLeaveRemain.NREALSISA
                console.log(data.DBIRTHDAY)
                //data.CDBIRTHDAY = await base.formatdatetime(data.DBIRTHDAY, 'DD MMMM YYYY')    
                data.DBIRTHDAY = new Date(data.DBIRTHDAY).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"long", day:"numeric"})
                var vardb = await base.getdatabasename()
                console.log(vardb)
                
                for (var i = 0; i < vardb.length; i++){                    
                if (vardb[i].ctype == 'DBMAIN'){
                  data.CDBMAIN = vardb[i].cdatabasename                
                  } 
                else if (vardb[i].ctype == 'DBPIC'){
                  data.CDBPIC = vardb[i].cdatabasename                
                  }
                else if (vardb[i].ctype == 'DBLOG'){
                  data.CDBLOG = vardb[i].cdatabasename                  
                  }
                else if (vardb[i].ctype == 'DBPICLOG'){
                  data.CDBPICLOG = vardb[i].cdatabasename                  
                  }
                }                
                

                /*data.CDBMAIN = await base.getdatabasename('DBMAIN')
                data.CDBPIC = await base.getdatabasename('DBPIC')
                data.CDBLOG = await base.getdatabasename('DBLOG')*/
                set_user_data(data)

                var employee_picture = response.employeepic
                set_employee_picture(employee_picture) 
                var uri = `data:image/jpeg;base64,${employee_picture}`;
                set_uri(uri) 
                console.log(uri)

                /*await DB.employee.add({
                  CIDEMPLOYEE: data.CIDEMPLOYEE,
                  CEMPLOYEE: data.CEMPLOYEE,
                  CNIP: data.CNIP,
                });*/

                if (navigator.geolocation) {
                  await navigator.geolocation.getCurrentPosition(success, error);
                } else {
                  console.log("Geolocation not supported");
                }

                //console.log(employee_picture) 
                console.log(user_data)    
                for(var x in register_form_arr){
                    if(register_form_arr[x].label === 'Employee Name'){
                        register_form_arr[x].value = data.CEMPLOYEE
                        base.update_array(register_form_arr, set_register_form_arr, register_form_arr[x], x)                        
                        break
                    }                
                }      
            }
        }

      const xtime = base.getservertime_integer()
      xtime.then((resp)=> {

        console.log(resp)
        setTime(resp)
      });  

      var greet = base.greeting()
      greet.then((resp)=> {

        console.log(resp)
        set_greeting(resp)
      });  

      base.hidePreloader()      
 	}  

  async function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    
    var loc = {latitude : '', longitude : '112.7506713'}
    loc.latitude = latitude;
    loc.longitude = longitude;
    await set_location(loc);
    
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }

  async function error() {
    var loc = {latitude : '-7.2731118', longitude : '112.7506713'}
    
    await set_location(loc);

    console.log(location);
    console.log("Unable to retrieve your location");
  }

 	async function logoutBtn(){        

      var token = await localStorage.getItem('token')
      var lparam = {token}
      var url = '/auth/logout'
      var response = await base.request(url, 'post', lparam)
      console.log(response)
      
      if(response != null){
        if(response.status == 'success'){    

          await localStorage.clear()
          window.location.href = '/auth/login'
        }
      }
        
    }

    async function loginAsEmployee(){
      //var e = document.getElementById("cbxEmployee2");
      var value = empdata.CIDEMPLOYEE;
      if (value === undefined) 
      {
        value = employee_data[0].CIDEMPLOYEE
      }

      var url = '/util/encrypt?ctext='+value
      var response = await base.request(url)
      if(response != null){
        if(response.status == 'success'){

          console.log(value)
          //await localStorage.removeItem('cidemployee')
          //await localStorage.setItem('cidemployee', value)  

          await localStorage.removeItem('psn_cid')
          await localStorage.setItem('psn_cid', response.data)
          //set_alert('Login Success! Please Wait...', 'success')

          setTimeout(() => {
            window.location.reload()
          }, 750);    
        }
      }
    }

    async function editChangePassword(e){
        e.preventDefault()
        base.$('#modalChangePass').modal('show')
    }

    async function testSendEmail(e){
        e.preventDefault()

        var url = '/sendmail'
        var response = await base.request(url)
        if(response != null){
         if(response.status == 'success'){
            alert('send email success')
            }
        }
    }

    async function editChangeEmployee(e){      
        if (ciduser.CIDUSER === 'ADMIN')
          {    
            base.$('#preloader_modal').modal('show')
            
            var url = '/hris/employeeactive'
            var response = await base.request(url)
            //var response=''
            console.log(response)
                    
            if(response !== null){
                if(response.status === 'success'){

                  var data = response.data
                  //console.log(data)

                  for(var x in data){
                    data[x].CEMPLOYEENIP = data[x].CEMPLOYEE+' ('+data[x].CNIP+')'
                  }

                  
                  set_employee_data(data)     

                  for (var i = 0; i < data.length; i++){                    
                    if (data[i].CIDEMPLOYEE == user_data.CIDEMPLOYEE){
                       // we found it
                      set_empdata(data[i])
                      set_emp2data(data[i])
                      break
                    }
                  }                

                  //console.log(employee_data)
                  //console.log(data)

                base.hidePreloader()                                        
              }
          }
        }  

        e.preventDefault()
        base.$('#modalLoginAs').modal('show')
    }
	
    async function changeInputPass(value, index){
        if (register_form_arr[index].label != 'Employee Name')
        {

        set_alert()
        set_is_button_disable(false)

        var data_index = register_form_arr[index]
        register_form_arr[index].value = value
        for(var x in register_form_arr){
            register_form_arr[x].is_error = false
        }
        base.update_array(register_form_arr, set_register_form_arr, data_index, index)

        }
    }

    async function set_alert(message='', type=''){
        set_alert_data({message : message, type : type})
    }

    async function saveChangePass(){
        var data_register = {username : '', old_password : '', password : '', retype_password : ''}

        for(var x in register_form_arr){
            if(register_form_arr[x].value === ''){
                register_form_arr[x].is_error = true
                base.update_array(register_form_arr, set_register_form_arr, register_form_arr[x], x)                
                break
            }
            else{
                data_register[register_form_arr[x].type] = register_form_arr[x].value
            }
        }    

        //console.log(data_register)
        
        if(data_register.password !== data_register.retype_password){              
            set_error_message_modal("Retype Password does't match")
            base.$('#modalWrongProcess').modal('show')
        } else
        {
            var cidemployee = await localStorage.getItem('psn_cid')
            var url = '/auth/change-pass'//?cidemployee='+user_data.CIDEMPLOYEE
            data_register.cidemployee = cidemployee
            var response = await base.request(url, 'put', data_register)
            if(response != null){
                if(response.status == 'success'){                    
                    window.location.reload()                    
                } else
                {
                    set_error_message_modal(response.message)
                    base.$('#modalWrongProcess').modal('show')
                }
            }
        }
    }
    //console.log(user_data)    

    async function changeImage(value){
      console.log(value.target.files[0])
      set_selectedFile(value.target.files[0])

      const data = new FormData() 
      data.append('images', value.target.files[0])
      data.append('cidreff', await localStorage.getItem('psn_cid'))

      for (var pair of data.entries()) {
          console.log(pair[0]+ ' - ' + pair[1]); 
      }     

      var url = '/hris/uploademployeepicture'
      var response = await base.request(url, 'postmultipart', data)
      console.log(response)
      
      if(response != null){
        if(response.status == 'success'){    
           console.log(response) 
           window.location.reload()
        }
      }
    }

return(
        <div className='page_wrap'>
            <div className='row'>
                <div className='pt-5 fixed-top page_wrap' style={{backgroundColor:'#FFFFFF',height:80}}>
                    <PageHeader title={'Profile'} style={{fontSize : '1.5rem', fontWeight : '600'}} />
                </div>

                
                
                {
                  ciduser.CIDUSER === "ADMIN" &&
                  <>   
                    <div className='col-12 mt-4'>
                      <a className='custom-btn-solusiqu btn btn-warning float-right' onClick={(e)=>editChangeEmployee(e)}>Change Employee</a>
                    </div>    
                  </>
                }

                <div className='col-12' style={{paddingBottom:'0px', paddingTop:'15px'}}>  
                    <a type="submit" className='custom-img-profile-solusiqu' onClick={()=>base.$('#file_input').trigger('click')}>                    
                      <img className='custom-img-profile-solusiqu' src = {uri}/>   
                    </a>                
                    <form encType="multipart/form-data" id="imageForm">
                      <input type="file" name="photo" accept="image/*" id="file_input" className="d-none" onChange={(event)=>changeImage(event)} />                                      
                    </form>                    
                    <div style={{fontSize : '1rem', fontWeight : '500'}}>
                    <center>
                      {/*<div>{greeting}, </div>*/}
                      <p>({user_data.CNIP})<br/>{user_data.CEMPLOYEE}</p>
                      {/*<p className="stopwatch-time">
                        {hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:
                        {seconds.toString().padStart(2, "0")}
                      </p>*/}
                    </center>
                    </div>
                </div>                
                
                <div className='col-12'>
                
                        <div className='mb-5'>
                <div className='card mb-4 rounded' style={{padding:'10px'}}>
                                   
                      {/*<tr>
                          <td width='30%'>ID</td>
                          <td width='1%'>:</td>
                          <td>{user_data.CNIP}</td>
                      </tr>
                      <tr>
                          <td width='30%'>Name</td>
                          <td width='1%'>:</td>
                          <td>{user_data.CEMPLOYEE}</td>
                      </tr>*/}
                     
                      <ViewField type='text' useLabel='1' readonly={true} data={user_data.CEMAIL??''} label='Email' id='iemail'/>                        
                      <ViewField type='text' useLabel='1' readonly={true} data={user_data.DBIRTHDAY??''} label='Birthday' id='idob'/>
                      <ViewField type='text' useLabel='1' readonly={true} data={user_data.CPOSITION??''} label='Position' id='ipos'/>                      
                      <ViewField type='text' useLabel='1' readonly={true} data={user_data.CDEPARTMENT??''} label='Department' id='idept'/>  

                      {/*<tr>                        
                          <td style={{width:'100px'}}>Email</td>
                          <td>:</td>
                          <td>{user_data.CEMAIL}</td>
                      </tr>
                      <tr>
                          <td style={{width:'100px'}}>Birth Date</td>
                          <td>:</td>
                          <td>{user_data.DBIRTHDAY}</td>
                      </tr>
                      <tr>
                          <td style={{width:'100px'}}>Position</td>
                          <td>:</td>
                          <td>{user_data.CPOSITION}</td>
                      </tr>
                      <tr>
                          <td style={{width:'100px'}}>Department</td>
                          <td>:</td>
                          <td>{user_data.CDEPARTMENT}</td>
                      </tr>  

                      <tr>
                          <td style={{width:'100px'}}>Location</td>
                          <td>:</td>
                          <td>{location.latitude}; {location.longitude}</td>
                    </tr>  
                      <tr style={{borderBottom: "1px solid rgba(0, 0, 0, 0.125)"}}>
                        <td style={{width:'100px'}}></td>
                        <td></td>
                        <td></td>
                      </tr>                                      
                      <tr>
                          <td style={{width:'100px'}}></td>
                          <td></td>
                          <td></td>
                      </tr>      */}                                            
                    </div>
                  </div>
                </div>

                {                    
                        ciduser.CIDUSER === "ADMIN" &&
                        <>   
                          <div className='col-12'>                
                            <div className='mb-5'>
                              <div className='card mb-4 rounded' style={{padding:'10px'}}>
                              
                                <ViewField type='text' useLabel='1' readonly={true} data={ciduser.CIDUSER??''} label='Login As' id='iduser'/>
                                <ViewField type='text' useLabel='1' readonly={true} data={base.base_url??''} label='API' id='ibaseurl'/>
                                <ViewField type='text' useLabel='1' readonly={true} data={user_data.CDBMAIN??''} label='DB Main' id='idbmain'/>
                                <ViewField type='text' useLabel='1' readonly={true} data={user_data.CDBPIC??''} label='DB Pic' id='idbpic'/>
                                <ViewField type='text' useLabel='1' readonly={true} data={user_data.CDBLOG??''} label='DB Log' id='idblog'/>
                                <ViewField type='text' useLabel='1' readonly={true} data={user_data.CDBPICLOG??''} label='DB Pic Log' id='idbpiclog'/>
                              
                              </div>
                            </div>
                          </div>  
                        </>
                }      

                {
                  ciduser.CIDUSER !== "OFFLINE" &&
                  <>     
                    <div className='qu-mx-footer'>
                        {
                          ciduser.CIDUSER !== "ADMIN" &&
                          <a href='#!' className='custom-btn-solusiqu btn btn-info float-left' onClick={(e)=>editChangePassword(e)}>Change Password</a>
                        }                          
                                            
                        <a href='#!' className='custom-btn-solusiqu btn btn-info float-left' onClick={(e)=>testSendEmail(e)}>Test Send Email</a>
                                                  
                        <button onClick={()=>logoutBtn()} className='custom-btn-solusiqu btn btn-danger float-right' role='button'>Sign Out</button>
                    </div>
                  </>
                }
            </div>  

            <div className="modal fade" id="modalChangePass" tabIndex="-1" aria-labelledby="modalChangePassLabel" aria-hidden="true">
                <div className="modal-dialog p-4 pt-5 mt-5">
                    <div className="modal-content border-0 shadow-sm" style={{borderRadius : '.75rem'}}>
                        <div className="modal-body">
                            <div className={'row'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3>Change Password</h3>                                                                                            
                                        </div>                                                        
                                        <div className='col-12'>                                            
                                            <div className='row'>
                                            {
                                                register_form_arr.map((data, index)=>(
                                                    <div className={"col-12 form-group m-0" + (index != 0 ? ' mt-3' : '')} key={index}>
                                                        <FormInput
                                                            label={data.label}
                                                            type={data.type}
                                                            value={data.value}
                                                            changeInput={(value)=>changeInputPass(value, index)}
                                                            is_error={data.is_error}
                                                            error_message={(data.label) + ` can't be empty`}
                                                            />
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=>saveChangePass()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalLoginAs" tabIndex="-1" aria-labelledby="modalLoginAsLabel" aria-hidden="true" data-toggle="modal" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog p-4 pt-5 mt-5">
                <div className="modal-content border-0 shadow-sm" style={{borderRadius : '.75rem'}}>
                  <div className="modal-body">
                    <div className='row'>
                      <div className='col-12'>
                        <div className='col-12'>
                          <div>
                            <h3>Login As Employee</h3>
                          </div>
                          <div className='form-group' id='cbxDropdownSearch'>
                            <Autocomplete         
                                onChange={(event, value) => set_empdata(value)} // prints the selected value                                                                
                                value={empdata}                                    
                                onInputChange={(event, inputValue) => set_emp2data(inputValue)} // prints the selected value                                                                
                                //inputValue={emp2data}
                                id="cbxEmployee2"
                                options={employee_data}
                                size='small'                               
                                //defaultValue={employee_data[0]}
                                //value={employee_data[0]}         
                                getOptionLabel={(option) => option.CEMPLOYEENIP ? option.CEMPLOYEENIP : ""}
                                renderInput={(params) => <TextField {...params} label='Employee'/>}
                            />                  
                          </div>
                          <div className='form-group'>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={()=>loginAsEmployee()}>Login</button>
                  </div>
                </div>
              </div>
            </div>

            <WrongProcessModal type={'error'} message={error_message_modal} />          
        </div>


      )

}