import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import DB from '../../utils/db'
import { useLiveQuery } from "dexie-react-hooks";
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import FormInput from '../../components/FormInput';
import ResultModalInputID from '../../components/InputIDResultModal';
import WrongProcessModal from '../../components/modalWrongProcess';
import InputQRForm from '../../components/inputQRForm';
import ModalPaletteResult from '../../components/modalPaletteResult';

export default function ShipementInputID(){
    var base = new Base()
    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [input_type, set_input_type] = useState('')
    const [qr_text, set_qr_text] = useState('')
    const [palette_result_data, set_palette_result_data] = useState({PAL_CID : ''})
    const [deliver_order_data, set_deliver_order_data] = useState({CIDDO : '', CDESTADDRESS : '', CREQUESTNO : ''})
    const [production_line_id, set_production_line_id] = useState('')
    const [scan_type, set_scan_type] = useState('palette')

    const [error_message_modal, set_error_message_modal] = useState('')

    const arr_palette = useLiveQuery(
        () => DB.palette.toArray()
    );

    const [search_callback, set_search_callback] = useState('')

    useEffect(async ()=>{
        
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        var production_line_id = await localStorage.getItem('production_line_id')
        set_production_line_id(production_line_id)


        // var data_palette = await localStorage.getItem('packing_input_palette')
        // if(data_palette != null){
        //     data_palette = JSON.parse(data_palette)
        //     set_arr_palette(data_palette)
        // }

        base.$('#preloader_modal').on('hidden.bs.modal', function (event) {
            base.$('#inputQR').trigger('focus')
        })
    }, [])

    useEffect(()=>{
        if(palette_result_data.PAL_CID !== ''){
            if(query.get('input_type') === 'palette'){
                base.hidePreloader()
                // base.$('#modalResult').modal('show')
            }
        }

    }, [palette_result_data])

    useEffect(()=>{
        if(deliver_order_data.CIDDO !== ''){
            submitBtn('confirm')
        }
    }, [deliver_order_data])

    useEffect(()=>{
        set_qr_text('')
    }, [arr_palette])

    useEffect(async ()=>{
        if(qr_text === ''){
            base.$('#modalResult').modal('hide')
        }
    }, [qr_text])

    function searchQR(key){
        if(key === 'Enter'){
            if(qr_text !== ''){
                resultQR()
            }
            else{
                base.$('#inputQR').focus()
            }
        }
        else if(key === 'Cancel'){
            var flag = 1

            if(query.get('type') === 'back_to_warehouse'){
                if(deliver_order_data.CIDDO === ''){
                    flag = 0
                }
            }
            else{
                if(arr_palette.length === 0){
                    flag = 0
                }
            }
            
            if(flag){
                submitBtn('submit')
            }            
            else{
                window.history.back()
            }
        }
    }

    function changeQRInput(value){
        var qr = value.toUpperCase()
        set_qr_text(qr)
    }

    async function resultQR(){
        if(navigator.onLine){
            var barcode = qr_text

            var url = ''
            if(query.get('type') === 'back_to_warehouse'){
                url = '/delivery-order?barcode=' + barcode
            }
            else{
                url = '/palette?barcode=' + barcode + '&production_line_id=' + production_line_id
            }

            base.$('#preloader_modal').modal('show')

            var response = await base.request(url)
            if(response != null){
                if(response.status == 'success'){
                    var data = response.data
                    var flag_scan = 0

                    set_qr_text('')

                    if(data != null){
                        if (data.RRH_CID != null) {
                            flag_scan = 0
                            base.hidePreloader()
                            set_error_message_modal("This barcode is Material Barcode, can't process")
                            base.$('#modalWrongProcess').modal('show')	
                        }

                        if(query.get('type') === 'back_to_warehouse'){
                            set_scan_type('delivery_order')
                            set_deliver_order_data(data)
                            // base.$('#modalResult').modal('show')
                        }
                        else{
                            if(data.prod_line.PDL_CNAME === 'WAREHOUSE'){
                                flag_scan = 1
                            }
                            else{
                                base.hidePreloader()
                                set_error_message_modal(`The Current Pallete ID has already been Scanned`)
                                base.$('#modalWrongProcess').modal('show')
                            }

                            for(var x in arr_palette){
                                if(data.PAL_CID === arr_palette[x].PAL_CID){
                                    flag_scan = 0
                                    base.hidePreloader()
                                    set_error_message_modal(`The Current Pallete ID has already been Scanned`)
                                    base.$('#modalWrongProcess').modal('show')
                                    break
                                }
                            }
                        }

                        if(flag_scan){
                            if(data.PAL_NFLAGTYPE == 0){
                                data.quantity = 1
                                await DB.palette.add({
                                    PAL_CID: data.PAL_CID,
                                    PKC_CCODE: data.PKC_CCODE,
                                    quantity: data.quantity,
                                });
                                // base.add_array(arr_palette, set_arr_palette, data)
                                // set_palette_result_data(data)
                                base.hidePreloader()
                                // base.$('#modalResult').modal('show')
                            }
                            else{
                                base.hidePreloader()
                            }
                        }

                        base.hidePreloader()
                    }
                    else{
                        base.hidePreloader()

                        set_error_message_modal(`Data not found`)
                        base.$('#modalWrongProcess').modal('show')
                    }

                }
                else{
                    if (query.get('type') === 'back_to_warehouse'){
                        base.hidePreloader()
                        set_error_message_modal(response.message) 
                        base.$('#modalWrongProcess').modal('show')
                    }
                    else{
                        base.hidePreloader()
                        base.$('#modalErrorQR').modal('show')
                    }
                }
            }
            else{
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }
        }
    }

    function keyUpInput(e){
        if(e.keyCode >= 65 && e.keyCode <= 90 || e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode !== 32 && e.keyCode !== 8 && e.keyCode !== 16 && e.keyCode !== 20 && e.keyCode !== 44 && e.keyCode !== 13){
            if(qr_text.length > 5){
                clearTimeout(search_callback)
                set_search_callback(
                    setTimeout(() => {
                        resultQR()
                    }, 500)
                )
            }
        }
    }

    async function submitBtn(type){
        if(type === 'retype'){
            set_palette_result_data({})
            set_qr_text('')
        }
        else{
            // arr_palette.push(palette_result_data)
            // var arr_temp = []
            // for(let palette of arr_palette){
            //     arr_temp.push({
            //         PAL_CID: palette.PAL_CID,
            //         PKC_CCODE: palette.PKC_CCODE,
            //         quantity: palette.quantity,
            //     })
            // }
            // await localStorage.setItem('packing_input_palette', JSON.stringify(arr_temp))

            await localStorage.setItem('delivery_order_data', JSON.stringify(deliver_order_data))
            window.location.href = '/shipment/input-id/check?type=' + query.get('type')
        }
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={(query.get('type') === 'back_to_warehouse' ? 'Back to FG Warehouse' : query.get('type') === 'shipment' ? 'Shipment' : '')} icon={(query.get('type') === 'remove' ? 'bi bi-dash-circle-fill' : null)} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <InputQRForm input_type={input_type} qr_text={qr_text} changeQRInput={(val)=>changeQRInput(val)} searchQR={(key)=>searchQR(key)} keyUpInput={(e)=>keyUpInput(e)} total_data={arr_palette != null ? arr_palette.length : 0} />

                </div>
            </div>

            {/* <ResultModalInputID palette_data={palette_result_data} scan_type={scan_type} process={query.get('type')} submitBtn={(type)=>submitBtn(type)} deliver_order_data={deliver_order_data} /> */}
            <WrongProcessModal type={'error'} message={error_message_modal} />

            <ModalPaletteResult data_arr={arr_palette != null ? arr_palette : []} viewFrom={'history'} wh_type={''} submitBtn={(type)=>submitBtn(type)} />

        </>
    )
}