import { useEffect, useRef, useState } from 'react';
import Base from '../../../utils/base';
import PageHeader from '../../../components/pageHeader';
import WrongProcessModal from '../../../components/modalWrongProcess';
import ViewField from '../../../components/viewField';

import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

export default function EmployeeAttend(){
    var base = new Base()	
    let DefaultIcon = L.icon({
		iconUrl: icon,
		shadowUrl: iconShadow,
		iconSize: [28, 46],
		iconAnchor: [12, 45]
	});
	L.Marker.prototype.options.icon = DefaultIcon;

    const [error_message_modal, set_error_message_modal] = useState('')
    const [absenteeData, set_absenteeData] = useState('')
    const [absenteeTime, set_absenteeTime] = useState('')
    const [absenteeLog, set_absenteeLog] = useState([])
    
    const [time, setTime] = useState(0);
    // Hours calculation
    const hours = Math.floor(time / 3600);
    // Minutes calculation
    const minutes = Math.floor((time % 3600) / 60);
    // Seconds calculation
    const seconds = Math.floor((time % 60) / 1);   

    const [location, set_location] = useState({latitude: '', longitude : ''})

	useEffect(() => {
        const intervalId = setInterval(() => {
            setTime((prevCounter) => prevCounter + 1);
          }, 1000);
      
        return () => clearInterval(intervalId);
    }, [time]);

    useEffect(async ()=>{
        //set_absenteeTime(dayjs('2022/01/01T'+hours.toString().padStart(2, "0")+':'+minutes.toString().padStart(2, "0")+':'+seconds.toString().padStart(2, "0")))
        set_absenteeTime(hours.toString().padStart(2, "0")+':'+minutes.toString().padStart(2, "0")+':'+
                        seconds.toString().padStart(2, "0"))
    }, [time]);
    
    useEffect(async () => {
        // await localStorage.clear()		
        var token = await localStorage.getItem('token')
        console.log(token)
        
        if (token == null) {
            window.location.href = '/auth/login'
        }
        else {			
            if (navigator.onLine) {       
                base.cek_pwa_version()
                /*var get_default_input = await base.get_default_input()
                set_default_input(get_default_input)*/	
                get_data()	
                getTimeLocation()		
            }
            else {
                base.$('#error_modal').modal('show')
            }
        }
    }, [])

    async function getTimeLocation(){
        if (navigator.geolocation) {          

          await navigator.geolocation.getCurrentPosition(success, positionError,{enableHighAccuracy: true,
                                                                           timeout: 10000,
                                                                           maximumAge: 1000});
        } else {
            console.log("Geolocation not supported");
        }

        const xtime = base.getservertime_integer()
          xtime.then((resp)=> {

          console.log(resp)
          setTime(resp)
        });
    }

    async function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        
        var loc = {latitude : '-7.2731118', longitude : '112.7506713'}
        loc.latitude = latitude;
        loc.longitude = longitude;
        set_location(loc);
        
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }
    
    async function error() {
        var loc = {latitude : '-7.2731118', longitude : '112.7506713'}
        
        set_location(loc);
    
        console.log(loc);
        console.log("Unable to retrieve your location");        
    }

    function positionError( error ) { 
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          var loc = {latitude : '-7.2731118', longitude : '112.7506713'}
                  
        set_location(loc);
    
        console.log(loc);
        console.log("Unable to retrieve your location");        
      } else {
        switch ( error.code ) { 
          case error.PERMISSION_DENIED: 
              
              alert( "User denied the request for Geolocation." ); 
              window.history.back();
              break;
  
          case error.POSITION_UNAVAILABLE: 
  
              alert( "Location information is unavailable." ); 
              window.history.back();
              break;
  
          case error.TIMEOUT: 
  
              alert( "The request to get user location timed out." ); 
              window.history.back();
              break;
  
          case error.UNKNOWN_ERROR: 
  
              alert( "An unknown error occurred." ); 
              window.history.back();
              break;
        }
      }

    }

    async function get_data(){
        base.$('#preloader_modal').modal('show')
        var url = '/hris/employeehome' 
		var cidemployee = await localStorage.getItem('psn_cid')
        let dstart = await base.getCurrentDate()
        let dend = await base.getCurrentDate()
		var dataSend1 = {cidemployee}
		var dataSend2 = {dstart,dend,cidemployee}
		var response = await base.request(url,'post',dataSend1)

        if (response!=null) {
            if (response.status=='success') {
                var data = response.employeehome
                if (data.ABS_DDATE) {
                    data.ABS_DDATE = new Date(data.ABS_DDATE).toLocaleDateString('id', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
                }else{
                    data.ABS_DDATE='no schedule today..'
                }
                if (data.WSH_TSTART) {
					data.WSH_TSTART = new Date('1999-01-01 '+data.WSH_TSTART).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })					
				}
				if (data.WSH_TFINISH) {
					data.WSH_TFINISH = new Date('1999-01-01 '+data.WSH_TFINISH).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })					
				}
                set_absenteeData(data)
                base.hidePreloader() 
            }          
        }else{
            window.location.replace('/auth/login')
        }
        console.log(data)

        var url_raw = '/hris/employeeabsentee_raw'
        var response_raw = await base.request(url_raw,'post',dataSend2)

        if (response_raw != null) {
            if (response_raw.status=='success') {
                var absentee_raw = response_raw.employeeabsentee_raw
                for(var x in absentee_raw){
                    if (absentee_raw[x].RAW_TABSENTEE) {
                        absentee_raw[x].RAW_TABSENTEE = new Date('1999-01-01 '+absentee_raw[x].RAW_TABSENTEE).toLocaleTimeString('id', { hour: "2-digit", minute: "2-digit" })					
                    }
                }
                set_absenteeLog(absentee_raw)
            }else{
                /*setTimeout(() => {
					base.$('#error_modal').modal('show')
				}, 750);*/
            }
        }else{
            window.location.replace('/auth/login')
        }
        console.log(absentee_raw)
    }

    async function actSubmit(e){	
		e.preventDefault()	
		var url ='/hris/employeeshareloc'
		var cidemployee = await localStorage.getItem('psn_cid')
		var dataSend = {cidemployee: cidemployee, latitude:location.latitude, longitude:location.longitude}

		if(navigator.onLine){
			var response = await base.request(url,'post',dataSend)
			if (response != null){
				if(response.status=='success'){
					base.hidePreloader()               
        	        window.location.replace('/success')
				}else{
					base.hidePreloader() 
					set_error_message_modal(response.message)           
        	        base.$('#modalWrongProcess').modal('show')
				}
			}else{
				errorPost()
			}
		}else{
			errorPost()
		}		
	}

    function errorPost(){
        //base.hidePreloader()
        base.$('#error_modal').modal('show')
    }

    function ChangeMapView({ coords }) {
        const map = useMap();
        map.setView(coords, map.getZoom());        
      
        return null;
    }    

    return(
        <div className="page_wrap">
            <div className="row">
                <div className='col-12 position-fixed w-100 py-2 shadow-md' style={{ top: 0, backgroundColor: '#FFF', zIndex:1, height:'100px' }}>
                    <div className='pt-5'>
                        <PageHeader title='Attend' style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>
                </div>
                <div className='col-12 mt-4'>
                    <ViewField 
                        data={absenteeData.ABS_DDATE??''} 
                        type='text' 
                        readonly={true}
                        style={{fontWeight:'bold'}}
                        useLabel={1} label={'Date'}
                    />
                </div>
                <div className='col-12'>
                    <ViewField 
                        data={absenteeData.WRK_CMANUALCODE??''} 
                        type='text' 
                        readonly={true}
                        style={{fontWeight:'bold'}}
                        useLabel={1} label={'Workshift Code'}
                    />
                </div>
                <div className='col-12'>
                    <div className="d-flex justify-content-center mb-3">
                        <div className='pr-1'>
                            <ViewField style={{textAlign:'center',fontWeight:'bold'}} 
                                       data={absenteeData.WSH_TSTART??''} 
                                       type='text' 
                                       readonly={true}
                                       useLabel={1} 
                                       label={'Check In'}
                            />
                        </div>
                        <div className='pl-1'>
                            <ViewField style={{textAlign:'center',fontWeight:'bold'}} 
                                       data={absenteeData.WSH_TFINISH??''} 
                                       type='text' 
                                       readonly={true}
                                       useLabel={1} 
                                       label={'Check Out'}
                            />
                        </div>
                    
                    </div>
				</div>
                <div className='col-12'>
                    {/*
                    <FormControl sx={{mb:'15px', width:'100%'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimeField
                                label='Time (this time sync from server)'
                                value={absenteeTime}
                                size='small'
                                onChange={(newValue) => set_absenteeTime(newValue)}
                                format="HH:mm:ss"
                                readOnly
                            />
                        </LocalizationProvider>
                    </FormControl>
                    
                    <ViewField 
                        data={absenteeTime}
                        onChange={(newValue) => set_absenteeTime(newValue)}  
                        type='text'                  
                    />*/}
                </div>
                <div className='col-12'>
                    <div className='rounded' style={{height:'100%'}}>
                        <MapContainer center={[location.latitude,location.longitude]} zoom={18} scrollWheelZoom={false} style={{width:'100%', height:'40vh'}}>
                            <TileLayer
                                attribution='&copy; OpenStreetMap | Leaflet |'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <ChangeMapView coords={[location.latitude,location.longitude]} />
                            <Marker position={[location.latitude,location.longitude]} zoom={18}>
                              <Popup>
                                You Are Here! 
                              </Popup>  
                            </Marker>
                        </MapContainer>
                    </div>
                </div> 
                <div className='col mt-3'>
                    <button className='btn btn-success rounded col-12' style={{height:'50px'}} onClick={(e)=>actSubmit(e)}><b>Punch</b></button>     
                </div>
                <div className='col-12 mt-5'>
                    <h6 className='pr-2' style={{display:'inline'}}>Attendance Log</h6>(<i>shorted by newest</i>)
                    <div className='col mb-2 custom-tabs-bg-solusiqu rounded'></div>
                    <div className='col p-2'>
                        {
                            absenteeLog?.map((data, index)=>(
                                <li key={index} style={{display:'block'}}><i className='bi bi-alarm pr-2'></i>{data.RAW_TABSENTEE}</li>
                            ))
                        }
                    </div>
                </div>
                    {/*<p className="stopwatch-time">
                        {hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:
                        {seconds.toString().padStart(2, "0")}
                    </p>*/}
            </div>
            <WrongProcessModal type={'error'} message={error_message_modal} />
        </div>
    )
}