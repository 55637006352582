import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ModalTracking from '../../components/TrackingModal';
import PageHeader from '../../components/pageHeader';
import NoData from '../../components/NoData';

export default function HistoryIndex(){
    var base = new Base()

    const [search, set_search] = useState('')
    const [history_arr, set_history_arr] = useState([])
    const [is_loading_data, set_is_loading_data] = useState(true)

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }
        else{
            if(navigator.onLine){
                get_data()
            }
            else{
                base.$('#error_modal').modal('show')
            }
        }
    }, [])

    useEffect(async ()=>{
        get_data()
    }, [search])

    async function get_data(e){
        base.$('#preloader_modal').modal('show')

        var url = '/tracking/monitoring?search=' + search
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                var data = response.data

                for(var x in data){
                    data[x].id_display = data[x].ID.split('SCAN_HISTORY_')[1]
                    data[x].detail_url = '/last-history/detail?id=' + data[x].ID + '&type=' + data[x].prod_line.PDL_CCATEGORY

                    
                    data[x].desc = ''
                    if(data[x].DESCRIPTION != null){
                        var split_desc = data[x].DESCRIPTION.split('_')
                        if(split_desc.length > 0){
                            for(var y in split_desc){
                                data[x].desc += split_desc[y] + ' '
                            }
                        }
                    }
                    
                    data[x].title = data[x].prod_line.PDL_CCATEGORY + ' - ' + (data[x].DESCRIPTION == null ? data[x].prod_line.PDL_CNAME : data[x].desc)

                    data[x].total_product = 0
                    var detail = data[x].detail
                    for(var y in detail){
                        if(detail[y].packing_case === null){
                            data[x].total_product += 1
                        }
                    }
                }
                
                base.hidePreloader()
                set_is_loading_data(false)
                set_history_arr(data)
                //console.log(data)
            }
            else{
                base.hidePreloader()
                set_is_loading_data(false)
                base.$('#error_modal').modal('show')
            }
        }
        else{
            base.hidePreloader()
            set_is_loading_data(false)
            base.$('#error_modal').modal('show')
        }
    }

    async function changeSearch(value){
        set_search(value)
    }

    async function toDetail(index){
        //console.log(history_arr[index].ID)
        // window.location.href = '/last-history/detail?id=' + history_arr[index].id
    }

    return(
        <div className='page_wrap'>
            <div className='row'>

                <div className='col-12'>
                    <PageHeader title={'Last History'} style={{fontSize : '1.5rem', fontWeight : '600'}} />
                </div>

                <div className='col-12 mt-5'>
                    <div className='row'>
                        {/*<div className='col-12'>
                            <div class="input-group form-search">
                                <div class="input-group-prepend">
                                    <span class="input-group-text rounded">
                                        <i className='bi bi-search'></i>
                                        <i className="fas fa-search"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control rounded" placeholder="Search Form" value={search} onChange={(e)=>changeSearch(e.target.value)} />
                            </div>
                        </div>*/}

                        <div className='col-12 mb-5'>
                            <div className='row mb-5'>

                                {
                                    history_arr.length > 0 ?
                                    <>
                                        {
                                            history_arr.map((data, index)=>(
                                                <div className='col-12 py-1' style={{borderBottom : '1px solid #DADDDF', cursor : 'pointer'}} key={index} onClick={()=>toDetail(index)}>
                                                    <a href={data.detail_url} style={{textDecoration : 'none'}}>
                                                        <div className='row'>
                                                            {/*<div className='col-auto'>
                                                                <img src={base.img_no_image} style={{height : '3.75rem', width : '3.75rem', borderRadius : '3.75rem'}} />
                                            </div>*/}
                                                            <div className='col py-auto'>
                                                                <p className='m-0'>{data.id_display}</p>
                                                                <p className='m-0 text-capitalize' style={{color : '#6E7882', fontSize : '.75rem'}}>{data.title}</p>
                                                                <p className='m-0 mt-1' style={{fontSize : '.85rem'}}>Total Product : {data.total_product}</p>
                                                            </div>
                                                            <div className='col-auto d-flex justify-content-end'>
                                                                <p className='m-0' style={{color : '#6E7882', fontSize : '.75rem'}}>{base.moment(data.CREATEDATE).format('DD MMM, HH:mm')}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            !is_loading_data &&
                                            <NoData />
                                        }
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}