import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import DB from '../../utils/db'
import { useLiveQuery } from "dexie-react-hooks";
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import FormInput from '../../components/FormInput';
import ResultModal from './components/ResultModal';
import InputQRForm from '../../components/inputQRForm';
import WrongProcessModal from '../../components/modalWrongProcess';

export default function PackagingInputID() {
	var base = new Base()
	function useQuery() {
		const { search } = useLocation()
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	let query = useQuery()

	const [menu_arr] = useState([
		{ title: 'Input ID', type: 'input', nav: '#!' },
		{ title: 'Scan Barcode', type: 'scan', nav: '/packaging/scan-barcode?type=' + query.get('type') },
	])

	const [input_type, set_input_type] = useState('')
	const [qr_text, set_qr_text] = useState('')
	const [palette_result_data, set_palette_result_data] = useState({ PAL_CID: '' })
	const [product_result_data, set_product_result_data] = useState({ PDB_CID: '', PDB_CDESCRIPTION: '', quantity: 0 })

	const product_result_arr = useLiveQuery(
		() => DB.product.toArray()
	);
	const [is_first_time, set_is_first_time] = useState(true)
	const [total_scan, set_total_scan] = useState(0)
	const [first_total_scan, set_first_total_scan] = useState(0)

	const [scan_type, set_scan_type] = useState('')
	const [production_line_id, set_production_line_id] = useState('')
	const arr_product = useLiveQuery(
		() => DB.product.toArray()
	);

	const [error_type_modal, set_error_type_modal] = useState('')
	const [error_message_modal, set_error_message_modal] = useState('')

	const [last_process, set_last_process] = useState('')
	const [last_prod_line, set_last_prod_line] = useState('')

	const [search_callback, set_search_callback] = useState('')

	useEffect(async () => {
		var token = await localStorage.getItem('token')
		if (token == null) {
			window.location.href = '/auth/login'
		}
		set_scan_type(query.get('input_type'))
		set_input_type(query.get('input_type')[0].toUpperCase() + query.get('input_type').slice(1))

		var production_line_id = await localStorage.getItem('production_line_id')
		set_production_line_id(production_line_id)

		var data_palette = await localStorage.getItem('packing_input_palette')
		data_palette = JSON.parse(data_palette)

		if (data_palette != null) {
			set_palette_result_data(data_palette)
		}


		// var product_arr = await localStorage.getItem('packing_input_product')
		// if (product_arr != null) {
		// 	product_arr = JSON.parse(product_arr)
		// 	set_arr_product(product_arr)
		// 	set_product_result_arr(product_arr)
		// }

		get_prod_line()

		base.$('#modalResult').on('hidden.bs.modal', function (event) {
			set_qr_text('')
		})

		base.$('#preloader_modal').on('hidden.bs.modal', function (event) {
			base.$('#inputQR').trigger('focus')
		})

		base.$('#modalWrongProcess').on('hidden.bs.modal', function (event) {
			set_qr_text('')
			base.$('#inputQR').trigger('focus')
		})
		
	}, [])

	useEffect(() => {
		if (palette_result_data.PAL_CID !== '') {
			if (query.get('input_type') === 'palette') {
				base.hidePreloader()
				// base.$('#modalResult').modal('show')
			}
		}

	}, [palette_result_data])

	useEffect(() => {
		// if(product_result_data.PDB_CID !== ''){
		// }
		if (product_result_arr != null && product_result_arr.length > 0) {
			set_qr_text('')
			base.hidePreloader()
			// base.$('#modalResult').modal('show')
			calculate_total_scan()
		}
		// if(is_first_time){
		// 	set_first_total_scan(product_result_arr.length)
		// 	set_is_first_time(false)
		// }
		// if (!is_first_time)
		// 	localStorage.setItem('packing_input_product', JSON.stringify(product_result_arr))
		
	}, [product_result_arr])

	useEffect(async () => {
		if (qr_text === '') {
			base.$('#modalResult').modal('hide')
		}
	}, [qr_text])

	function calculate_total_scan() {
		var temp = 0
		for (let data of product_result_arr) {
			if ((data.action_type != null && data.action_type !== 'remove') || data.action_type == null)
				temp++
		}
		set_total_scan(temp)
	}

	function menuSelected(index) {
		window.location.href = menu_arr[index].nav
	}


	async function get_prod_line() {
		var url = '/production/line/data?type=last_state'

		var response = await base.request(url)
		if (response != null) {
			if (response.status == 'success') {
				set_last_prod_line(response.data.PDL_CCODE)
			}
		}
	}

	function searchQR(key) {
		if (key === 'Enter') {
			if (qr_text !== '') {
				resultQR()
			}
			else {
				base.$('#inputQR').focus()
			}
		}
		else if (key === 'Cancel') {
			if (palette_result_data.PAL_CID !== '') {
				submitBtn('submit')
			}
			else {
				backToPalette()
			}
		}
	}

	function changeQRInput(value) {
		var qr = value.toUpperCase()
		set_qr_text(qr)
	}

	async function resultQR() {
		if (navigator.onLine) {
			var barcode = qr_text
			var url = '/' + (scan_type === 'palette' ? 'packing' : scan_type === 'product' ? 'tracking' : '') + '?barcode=' + barcode + (scan_type === 'product' ? '&for=palette&production_line_id=' + production_line_id : '')

			base.$('#preloader_modal').modal('show')

			var response = await base.request(url)
			if (response != null) {
				if (response.status == 'success') {
					var data = response.data
					console.log(data)
					if (data != null) {
						if (scan_type === 'palette') {
							var result = data
							var flag = 1

							result.data_type = 'new'
							if (data.palette != null) {
								if (data.palette.RRH_CID != null && query.get('type') != 'view') {
									flag = 0
									base.hidePreloader()
									set_error_type_modal('error')
									set_error_message_modal("This barcode is Material Barcode, can't process")
									base.$('#modalWrongProcess').modal('show')	
								}

								result.palette.data_type = 'old'
								result = result.palette

								if (query.get('type') !== 'view') {
									if (data.palette.prod_line.PDL_CNAME === 'WAREHOUSE' || data.palette.prod_line.PDL_CNAME === 'TEMP WAREHOUSE') {
										flag = 0
										set_error_type_modal('error')
										set_error_message_modal(`Palette ID status is already in ` + data.palette.prod_line.PDL_CNAME)
										base.$('#modalWrongProcess').modal('show')
									}
									else if (data.palette.prod_line.PDL_CNAME === 'SHIPMENT') {
										flag = 0
										set_error_type_modal('error')
										set_error_message_modal(`The Current Pallete ID status is already in Shipment, can't be process`)
										base.$('#modalWrongProcess').modal('show')
									}
								}
							}

							if (data.PKC_NFLAGOBSOLETE == 1) {
								flag = 0
								set_error_type_modal('error')
								set_error_message_modal(`Palette ID doesn't have any sales order`)
								base.$('#modalWrongProcess').modal('show')
							}

							if (flag) {
								await localStorage.setItem('packing_input_palette', JSON.stringify(result))
								window.location.href = '/packaging/input-id/check?type=' + query.get('type')
							}
							else {
								set_qr_text('')
							}

						}
						else if (scan_type === 'product') {
							if (data.prod_line != null) {
								var flag = 1
								var qty = 0

								if (data.previous_monitor == null) {
									// if(query.get('type') === 'add'){
									//     set_error_type_modal('error')
									//     set_error_message_modal(`Product must enter WRAP before PACKING`)
									// }
									if (data.last_monitor != null) {
										qty = data.last_monitor.PDM_NQTY

										if (data.last_monitor.PDM_CPRODUCTIONLINE === 'WAREHOUSE' || data.last_monitor.PDM_CPRODUCTIONLINE === 'TEMP WAREHOUSE') {
											flag = 0
											set_error_type_modal('error')
											set_error_message_modal(`Product is already in ` + data.last_monitor.PDM_CPRODUCTIONLINE)
											base.$('#modalWrongProcess').modal('show')
										}
										else if (data.last_monitor.PDM_CPRODUCTIONLINE === 'SHIPMENT') {
											flag = 0
											set_error_type_modal('error')
											set_error_message_modal(`Product is already in Shipment`)
											base.$('#modalWrongProcess').modal('show')
										}
										if (data.last_monitor.PDM_CPRODUCTIONLINE === 'MATERIAL') {
											flag = 0
											set_error_type_modal('error')
											set_error_message_modal(`Product is already in Material`)
											base.$('#modalWrongProcess').modal('show')
										}
									}
								}
								else {
									qty = data.previous_monitor.PDM_NQTY
								}


								if (data.prod_line != null) {
									if (data.prod_line.PDL_CCODE !== 'PACKING') {
										if (query.get('type') === 'remove') {
											flag = 0
											base.hidePreloader()
											set_error_type_modal('error')
											set_error_message_modal('The Current Product ID is not in the packaging')
											base.$('#modalWrongProcess').modal('show')
										}
									}
									if (data.prod_line.PDL_CCODE !== last_prod_line) {
										if (query.get('type') === 'add') {
											flag = 0
											base.hidePreloader()
											set_last_process(data.last_monitor.PDM_CPRODUCTIONLINE)
											set_error_type_modal('packaging')
											base.$('#modalWrongProcess').modal('show')
										}
									}
								}

								if (data.PDB_NFLAGNG == 1) {
									flag = 0
									set_error_type_modal('error')
									set_error_message_modal('Product status not pass in ' + data.last_monitor.PDM_CPRODUCTIONLINE)
									base.$('#modalWrongProcess').modal('show')
								}
								if (data.PDB_NFLAGOBSOLETE == 1) {
									flag = 0
									set_error_type_modal('error')
									set_error_message_modal(`Product ID doesn't have any sales order`)
									base.$('#modalWrongProcess').modal('show')
								}

								var palette_item = []
								palette_item = arr_product

								// for(var x in palette_item){
								//     if(data.SOH_CID !== palette_item[x].SOH_CID){
								//         flag = 0
								//         base.hidePreloader()
								//         set_error_type_modal('error')
								//         set_error_message_modal(`SO doesn't match`)
								//         base.$('#modalWrongProcess').modal('show')
								//         break 
								//     }
								// }

								if (query.get('type') === 'remove') {
									var is_found = 0
									for (var x in arr_product) {
										if (arr_product[x].PDB_CID === data.PDB_CID) {
											flag = 1
											is_found = 1
											base.$('#modalWrongProcess').modal('hide')
											break
										}
									}

									if (!is_found) {
										flag = 0
										base.hidePreloader()
										set_error_message_modal(`The Current Product ID isn't in this palette`)
										base.$('#modalWrongProcess').modal('show')
									}
								}
								else {
									for (var x in arr_product) {
										if (arr_product[x].PDB_CID === data.PDB_CID) {
											flag = 0
											base.hidePreloader()
											set_error_message_modal(`The Current Product ID has already been Scanned`)
											base.$('#modalWrongProcess').modal('show')
											break
										}
									}
								}


								if (flag) {
									if (query.get('reinput') != null) {
										data.quantity = qty
										set_product_result_data(data)
										await DB.product.update(parseInt(arr_product.length) - 1, {
											PDB_CID: data.PDB_CID,
											PDB_CDESCRIPTION: data.PDB_CDESCRIPTION,
											PDB_CBARCODE: data.PDB_CBARCODE,
											quantity: data.quantity,
										});
										// base.update_array(arr_product, set_arr_product, data, parseInt(arr_product.length) - 1)
									}
									else {
										var flag_result = 0
										if (data.prod_line.PDL_CNAME === 'PACKING') {
											if (query.get('type') !== 'remove') {
												base.hidePreloader()
												set_error_message_modal(`The Current Product ID has already been Scanned`)
												base.$('#modalWrongProcess').modal('show')
											}
											else {
												flag_result = 1
											}
										}
										else {
											flag_result = 1
										}


										if (flag_result) {
											var flag_add = 1
											var arr = JSON.parse(JSON.stringify(product_result_arr))
											data.quantity = 1
											for (var x in arr) {
												if (data.PDB_CID === arr[x].PDB_CID && query.get('type') === 'add') {
													flag_add = 0
													base.hidePreloader()
													set_error_message_modal(`The Current Product ID has already been Scanned`)
													base.$('#modalWrongProcess').modal('show')
													break
												}
											}

											if (query.get('type') === 'remove') {
												data.action_type = 'remove'
											}

											if (flag_add) {
												if (query.get('type') === 'add'){
													// base.add_array(product_result_arr, set_product_result_arr, data)
													await DB.product.add({
														PDB_CID: data.PDB_CID,
														PDB_CDESCRIPTION: data.PDB_CDESCRIPTION,
														PDB_CBARCODE: data.PDB_CBARCODE,
														quantity: data.quantity,
													});
												}
												else if (query.get('type') === 'remove') {
													for (let data1 of arr) {
														if (data1.PDB_CID === data.PDB_CID) {
															// data1.action_type = "remove"
															await DB.product.update(data1.id, {
																action_type: "remove",
															});
															break
														}
													}
													// set_product_result_arr(arr)
												}
											}
											// set_product_result_data(data)
										}
									}
								}
							}
							else if (data.prod_line == null) {
								if (query.get('type') === 'add') {
									base.hidePreloader()
									set_error_type_modal('error')
									set_error_message_modal(`Product must enter WRAP before PACKING`)
									base.$('#modalWrongProcess').modal('show')
								}
							}
						}
					}
					else {
						set_qr_text('')
						set_error_type_modal('error')
						set_error_message_modal(`Data not found`)
						base.$('#modalWrongProcess').modal('show')
					}

					base.hidePreloader()
				}
			}
		}
	}

	function keyUpInput(e) {
		if (e.keyCode >= 65 && e.keyCode <= 90 || e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode !== 32 && e.keyCode !== 8 && e.keyCode !== 16 && e.keyCode !== 20 && e.keyCode !== 44 && e.keyCode !== 13) {
			if (qr_text.length > 5) {
				clearTimeout(search_callback)
				set_search_callback(
					setTimeout(() => {
						resultQR()
					}, 500)
				)
			}
		}
	}

	async function submitBtn(type) {
		if (type === 'retype') {
			set_palette_result_data({})
			set_qr_text('')
		}
		else {
			if (scan_type === 'palette') {
				await localStorage.setItem('packing_input_palette', JSON.stringify(palette_result_data))
			}
			else if (scan_type === 'product') {
				product_result_data.data_type = 'new'

				if (query.get('type') !== 'remove') {
					if (query.get('type') === 'add') {
						for (var x in product_result_arr) {
							await DB.product.update(product_result_arr[x].id, {
								data_type: "new",
							});
							// product_result_arr[x].data_type = 'new'
						}
					}
					// if(query.get('reinput') == null){
					//     product_result_arr.push(product_result_data)
					// }
				}

				// await localStorage.setItem('packing_input_product', JSON.stringify(product_result_arr))
			}

			palette_result_data.arr_item = []
			await localStorage.setItem('packing_input_palette', JSON.stringify(palette_result_data))
			window.location.href = '/packaging/input-id/check?type=' + query.get('type')
		}
	}

	async function backToPalette() {
		window.location.href = '/'
		// await localStorage.removeItem('packing_input_product')
		// window.history.back()
	}

	return (
		<>
			<div className='page_wrap'>
				<div className='row'>

					<div className='col-12'>
						<PageHeader title={(query.get('type') === 'add' ? 'Add Packaging Process' : query.get('type') === 'remove' || query.get('type') === 'remove_all' ? 'Remove Packaging Item' : 'View Packing Item')} icon={(query.get('type') === 'remove' || query.get('type') === 'remove_all' ? 'bi bi-dash-circle-fill' : null)} style={{ fontSize: '1.25rem', fontWeight: '600' }} is_back_btn={true} customBack={true} backToPage={() => backToPalette()} />
					</div>

					<InputQRForm input_type={input_type} qr_text={qr_text} changeQRInput={(val) => changeQRInput(val)} searchQR={(key) => searchQR(key)} keyUpInput={(e) => keyUpInput(e)} total_data={total_scan} />

				</div>
			</div>

			<ResultModal palette_data={palette_result_data} product_data={product_result_data} product_arr={product_result_arr != null ? product_result_arr : []} scan_type={scan_type} process={query.get('type')} submitBtn={(type) => submitBtn(type)} />

			<WrongProcessModal type={error_type_modal} message={error_message_modal} process={last_process} />

		</>
	)
}