import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import FormInput from '../../components/FormInput';
import ScanCamera from '../../components/scanCamera';
import PageHeader from '../../components/pageHeader';
import ErrorQRModal from '../../components/errorQr';
import PaletteList from '../../components/paletteList';
import WrongProcessModal from '../../components/modalWrongProcess';

export default function ShipmentScanBarcode(){
    var base = new Base()

    const [multiple_qr_result_arr, set_multiple_qr_result_arr] = useState([])

    const [is_find_qr, set_is_find_qr] = useState(true)
    const [production_line_id, set_production_line_id] = useState('')
    const [is_no_palette, set_is_no_palette] = useState(false)

    const [error_type_modal, set_error_type_modal] = useState('')
    const [error_message_modal, set_error_message_modal] = useState('')

    const [deliver_order_data, set_deliver_order_data] = useState({CIDDO : '', CDESTADDRESS : ''})

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        base.$('#modalResult').on('hidden.bs.modal', function () {
            set_is_no_palette(false)
            set_is_find_qr(true)
        })
        base.$('#error_modal').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })

        base.$('#modalErrorQR').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })

        var production_line_id = await localStorage.getItem('production_line_id')
        set_production_line_id(production_line_id)

        var qr_result_temp = await localStorage.getItem('qr_result_temp')
            if(qr_result_temp != null){
                qr_result_temp = JSON.parse(qr_result_temp)
                set_multiple_qr_result_arr(qr_result_temp)
                base.$('#modalResult').modal('show')
            }
    }, [])

    async function actionBtn(type, index){
        if(type === 'view'){
            var qr_result_temp = multiple_qr_result_arr
            await localStorage.setItem('qr_result_temp', JSON.stringify(qr_result_temp))
            window.location.href = '/inventory/detail-palette?id=' + multiple_qr_result_arr[index].PAL_CID
        }
        else if(type === 'remove'){
            base.remove_array(multiple_qr_result_arr, set_multiple_qr_result_arr, index)
        }
    }

    async function submitBtn(){

        if(navigator.onLine){
            
            base.$('#preloader_modal').modal('show')
            var url = '/shipment'
            var data = {}
            var flag = 1

            if(query.get('type') === 'back_to_warehouse'){
                url = '/shipment/back-warehouse'
                data = {
                    delivery_order : {id : deliver_order_data.CIDDO}
                }
            }
            else{
                if(multiple_qr_result_arr.length > 0){
                    var arr_palette = []
                    for(var x in multiple_qr_result_arr){
                        arr_palette.push({
                            palette : {id : multiple_qr_result_arr[x].PAL_CID}
                        })
                    }
                    
                    data = {
                        type : query.get('type'),
                        arr_palette : arr_palette
                    }
                }
                else{
                    flag = 0
                }
            }

            if(flag){
                var response = await base.request(url, 'post', data)
                if(response != null){
                    if(response.status == 'success'){
                        window.location.href = '/success'
                    }
                    else{
                        errorProcess()
                    }
                }
                else{
                    errorProcess()
                }
            }
            else{
                if(query.get('type') === 'back_to_warehouse'){
                    errorProcess()
                }
                else{
                    set_is_no_palette(true)
                }
            }
    
        }
        else{
            errorProcess()
        }
    }

    async function errorProcess(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
    }

    async function resultScan(value){
        if(is_find_qr){
            set_is_find_qr(false)
            base.$('#preloader_modal').modal('show')
            
            var url = ''
            var barcode = value.split('#')[0]
            if(query.get('type') === 'back_to_warehouse'){
                url = '/delivery-order?barcode=' + barcode
            }
            else{
                url = '/palette?barcode=' + barcode + '&production_line_id=' + production_line_id
            }

            var response = await base.request(url)
            if(response != null){
                if(response.status == 'success'){
                    var data = response.data
                    var flag_scan = 0

                    if(data != null){
                        if(query.get('type') === 'back_to_warehouse'){
                            set_deliver_order_data(data)
                            base.$('#modalResult').modal('show')
                        }
                        else{
                            if(data.prod_line.PDL_CNAME === 'WAREHOUSE'){
                                flag_scan = 1
                            }
                            else{
                                set_is_find_qr(true)
                                base.hidePreloader()
                                set_error_message_modal(`The Current Pallete ID has already been Scanned`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                        }

                        if(flag_scan){
                            if(data.PAL_NFLAGTYPE == 0){
                                var new_flag = 1
                                var data_arr = multiple_qr_result_arr
                                for(var x in data_arr){
                                    if(data_arr[x].PAL_CID === data.PAL_CID){
                                        new_flag = 0
                                    }
                                }
                                if(new_flag){
                                    data.quantity = 1
                                    base.add_array(multiple_qr_result_arr, set_multiple_qr_result_arr, data)
                                }
                                base.hidePreloader()
                                base.$('#modalResult').modal('show')
                            }
                            else{
                                set_is_find_qr(true)
                                base.hidePreloader()
                            }
                        }

                        base.hidePreloader()
                    }
                    else{
                        set_is_find_qr(true)
                        base.hidePreloader()

                        set_error_message_modal(`Palette is not ready to be shipped, still isn't in the Warehouse.`)
                        base.$('#modalWrongProcess').modal('show')
                    }

                }
                else{
                    set_is_find_qr(true)
                    base.hidePreloader()
                    base.$('#modalErrorQR').modal('show')
                }
            }
            else{
                set_is_find_qr(true)
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }

        }
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={(query.get('type') === 'back_to_warehouse' ? 'Back to FG Warehouse' : query.get('type') === 'shipment' ? 'Shipment' : '')} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <div className='col-12 mt-5'>
                        <p className='m-0 text-capitalize' style={{fontSize : '1.25rem'}}>Scan {(query.get('type') === 'back_to_warehouse' ? 'Delivery Order' : query.get('type') === 'shipment' ? 'Palette' : '')} Barcode</p>
                    </div>

                    <div className='col-12 mt-3 p-0' style={{height : '100%'}}>
                        <ScanCamera resultScan={(value)=>resultScan(value)} scan_type={(query.get('type') === 'back_to_warehouse' ? 'Delivery Order' : query.get('type') === 'shipment' ? 'Palette' : '')} />
                    </div>

                </div>
            </div>

            <div className="modal fade" id="modalResult" tabIndex="-1" aria-labelledby="modalResultLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable m-0">
                    <div className="modal-content border-0 shadow-sm position-fixed" style={{bottom : 0, borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                        <div class="modal-header" style={{backgroundColor : '#E1EAEF', borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col p-0 d-flex align-items-center'>
                                        <p className='m-0'>Scan Result</p>
                                    </div>

                                    {
                                        query.get('type') !== 'back_to_warehouse' &&
                                        <div className='col p-0 d-flex align-items-center justify-content-end'>
                                            <div className='px-3 py-1' style={{backgroundColor : '#EAEAEA', border : '1px solid #347AB5', borderRadius : '5rem'}}>
                                                <p className='m-0' style={{color : '#347AB5', fontSize : '.75rem'}}>{multiple_qr_result_arr.length} Scan</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className={'row m-0'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 p-0'>
                                            <div className='row'>
                                                <div className='col-4 d-flex align-items-center'>
                                                    <p className='m-0'>Process</p>
                                                </div>
                                                <div className='col-8'>
                                                    <FormInput
                                                        type={'text'}
                                                        value={(query.get('type') === 'back_to_warehouse' ? 'Back to FG Warehouse' : query.get('type'))}
                                                        readOnly
                                                        style={{textTransform : 'uppercase'}}
                                                        changeInput={(value)=>console.log(value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            {
                                                query.get('type') === 'back_to_warehouse' ?
                                                <div className='row'>
                                                    <div className='col-12 p-3 mt-3' style={{backgroundColor : '#FCFCFC', border : '1px solid #EAEAEA', borderRadius : '.5rem'}}>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <p className='m-0'>{deliver_order_data.CIDDO}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <p className='m-0'>{deliver_order_data.CDESTADDRESS}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <PaletteList data_arr={multiple_qr_result_arr} actionBtn={(type, index)=>actionBtn(type, index)} />
                                            }
                                        </div>

                                        {
                                            is_no_palette ?
                                            <div className='col-12 p-0'>
                                                <div class={"alert alert-danger m-0 rounded"} role="alert">No Palette Added</div>
                                            </div>
                                            : <></>
                                        }

                                        <div className='col-12 mt-4 p-0'>
                                            <button className='btn btn-primary shadow-sm rounded w-100' onClick={()=>submitBtn()}>Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ErrorQRModal />

            <WrongProcessModal type={'error'} message={error_message_modal} />
        </>
    )
}