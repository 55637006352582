import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import DB from '../../utils/db'
import { useLiveQuery } from "dexie-react-hooks";
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import PaletteList from '../../components/paletteList';
import WrongProcessModal from '../../components/modalWrongProcess';

export default function ShipmentCheckInput(){
    var base = new Base()

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [action_btn_arr, set_action_btn_arr] = useState([
        {title : 'Reinput Palette', icon : 'bi bi-upc-scan', btn_type : 'btn-outline-primary', type : 'retype_palette'},
        {title : 'Confirm', icon : '', btn_type : 'btn-primary', type : 'confirm'},
    ])

    const [palette_qr_result, set_palette_qr_result] = useState({PKC_CID : '', PDB_CDESCRIPTION : '', PDB_CBARCODE : '', palette : null})
    const [is_disabled, set_is_disabled] = useState(false)
    const [palette_data, set_palette_data] = useState({PAL_CID : '', PKC_CID : ''})
    const [arr_product, set_arr_product] = useState([])
    const [error_message_modal, set_error_message_modal] = useState('')

    const arr_palette = useLiveQuery(
        () => DB.palette.orderBy('PAL_CID').toArray()
    );

    const [deliver_order_data, set_deliver_order_data] = useState({CIDDO : '', CDESTADDRESS : ''})

    useEffect(async ()=>{
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        // var data_palette = await localStorage.getItem('packing_input_palette')
        // data_palette = JSON.parse(data_palette)
        // if(data_palette != null){
        //     for(var x in data_palette){
        //         data_palette[x].quantity = 1
        //     }

        //     console.log(data_palette)
        //     var sort = data_palette.sort((a,b)=>a.PAL_CID > b.PAL_CID ? 1 : -1)
        //     set_arr_palette(sort)
        // }

        // if(data_palette != null){
        //     set_palette_data(data_palette)
        //     console.log(data_palette)
        //     var data_product = []
        //     var item = data_palette.item
        //     for(var x in item){
        //         item[x].production_barcode.quantity = item[x].P2I_NQTY
        //         data_product.push(item[x].production_barcode)
        //     }
        //     set_arr_product(data_product)
        // }
        // else{
        //     window.history.back()
        // }

        var data_delivery_order = await localStorage.getItem('delivery_order_data')
        data_delivery_order = JSON.parse(data_delivery_order)
        if(data_delivery_order != null){
            console.log(data_delivery_order)
            set_deliver_order_data(data_delivery_order)
        }

        
    }, [])

    async function actionBtn(type){
        if(type === 'retype_palette'){
            // await localStorage.removeItem('packing_input_palette')
            DB.palette.clear()
            window.location.replace('/packaging/input-id?type=' + query.get('type') + '&input_type=palette')
        }
        else if(type === 'confirm'){

            if(navigator.onLine){
                var url = '/shipment'

                if(query.get('type') === 'back_to_warehouse'){
                    url += '/back-warehouse'
                    data = {
                        delivery_order : {id : deliver_order_data.CIDDO}
                    }
                }
                else{
                    var palette_arr = []
                    for(var x in arr_palette){
                        palette_arr.push({palette : {id : arr_palette[x].PAL_CID}})
                    }
    
                    var data = {
                        type : query.get('type'),
                        arr_palette : palette_arr
                    }
                }
                
                var method = 'post'
                set_is_disabled(true)
                base.$('#preloader_modal').modal('show')

                var response = await base.request(url, method, data)
                
                base.$('#preloader_modal').modal('hide')
                if(response != null){
                    if(response.status == 'success'){
                        // await localStorage.removeItem('packing_input_palette')
                        DB.palette.clear()
                        window.location.replace('/success')
                    }
                    else{
                        base.hidePreloader()
                        set_error_message_modal(response.message)
                        base.$('#modalWrongProcess').modal('show')
                    }
                }
                else{
                    errorPost()
                }
            }
            else{
                errorPost()
            }
        }
    }

    function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
        set_is_disabled(false)
    }

    async function addPalette(){
        // var palette_arr = await localStorage.getItem('packing_input_palette')
        // if(palette_arr == null){
        //     await localStorage.setItem('packing_input_palette', JSON.stringify(arr_palette))
        // }
        window.location.href = '/shipment/input-id?type=' + query.get('type')
    }

    async function viewPalette(type='', index){
        window.location.href = '/inventory/detail-palette?id=' + arr_palette[index].PAL_CID
    }


    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                    <PageHeader title={(query.get('type') === 'back_to_warehouse' ? 'Back to FG Warehouse' : query.get('type') === 'shipment' ? 'Shipment' : '')} icon={(query.get('type') === 'remove' ? 'bi bi-dash-circle-fill' : null)} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <div className='col-12 mt-3'>
                        <div className='row m-0'>
                            <div className='col-12 mt-3'>
                                <div className='row'>

                                    {
                                        (query.get('type') === 'back_to_warehouse') &&
                                        <div className='col-12 px-0 d-flex align-items-center'>
                                            <p className='m-0'>{(query.get('type') !== 'back_to_warehouse' ? 'Palette ID' : 'DO Request No.')}</p>
                                        </div>
                                    }

                                    {
                                        query.get('type') !== 'back_to_warehouse' ?
                                        <>
                                            <div className='col-12 p-0 mt-3'>
                                                <div className='row'>
                                                    <div className='col d-flex align-items-center'>
                                                        <p className='m-0'>Produk</p>
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-end'>
                                                        <button className='btn btn-outline-primary rounded' onClick={()=>addPalette()}><i class="bi bi-plus-circle-fill"></i> Add Palette</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                {/* <MultipleScan type={'product'} data_arr={arr_product} /> */}
                                                <PaletteList data_arr={arr_palette != null ? arr_palette : []} process="" viewFrom={'history'} actionBtn={(type, index)=>viewPalette(type, index)} />
                                            </div>
                                        </>
                                        :
                                        <>
                                        <div className='col-12 p-3 mt-3' style={{backgroundColor : '#FCFCFC', border : '1px solid #EAEAEA', borderRadius : '.5rem'}}>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <p className='m-0'>{deliver_order_data.CREQUESTNO}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 mt-3'>
                                            <p className='m-0'>Customer : {deliver_order_data.CDESTNAME}</p>
                                            <p className='m-0'>PO No. : {deliver_order_data.CPONO}</p>
                                            <p className='m-0'>Invoice No. : {deliver_order_data.CINVOICENO}</p>
                                        </div>
                                        </>
                                    }
                                </div>
                            </div>

                            {
                                query.get('type') !== 'back_to_warehouse' &&
                                <div className='col-12 p-0 mt-3'>
                                    <div className='py-2' style={{backgroundColor : '#F5F5F5'}}>
                                        <p className='m-0 text-center font-weight-bold' style={{color : '#777777'}}>Total {arr_palette != null ? arr_palette.length : 0} Product</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>


            {
                query.get('type') !== 'view' &&
                <div className='position-fixed w-100 py-2 shadow-lg' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                    <div className='row m-0'>
                        <div className='col-12'>
                        {
                            action_btn_arr.map((data, index)=>(
                                <>
                                    <button className={'btn btn-lg w-100 rounded ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={()=>actionBtn(data.type)} key={index} disabled={is_disabled}>
                                        {
                                            data.icon !== '' &&
                                            <i className={data.icon + ' mr-2'}></i>    
                                        }
                                        {data.title}
                                    </button>
                                </>
                            ))
                        }
                        </div>
                    </div>
                </div>
            }
            <WrongProcessModal type={'error'} message={error_message_modal} />
        </>
    )
}