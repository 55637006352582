import React, { useEffect, useState } from 'react';
import Base from '../utils/base';

export default function SingleScan({type, data}){
    var base = new Base()

    useEffect(async ()=>{
        
    }, [])

    return(
        <>
            <div className='col-12 p-3 mt-3' style={{backgroundColor : '#FCFCFC', border : '1px solid #EAEAEA', borderRadius : '.5rem'}}>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col'>
                                <p className='m-0'>{type === 'product' ? data.PDB_CDESCRIPTION : type === 'palette' ? data.PKC_CCODE : ''}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <p className='m-0'>{type === 'product' ? data.PDB_CID : type === 'palette' ? (data.PDB_CDESCRIPTION != null ? data.PDB_CDESCRIPTION : '') : ''}</p>
                    </div>
                </div>
            </div>
        </>
    )
}