import React, { Component } from 'react'
// import '../App.css'
import axios from 'axios'
import moment from 'moment'
import Jquery from 'jquery'

import 'popper.js'
import 'bootstrap'

export default class Base extends Component {

	//base_url = 'https://mxcore2.duckdns.org:4433/api';
	// base_url = 'https://daiken-api.quantumtri.com/api';
	//base_url = 'https://mxcore2.duckdns.org:47001/api'; //dev api
	base_url = 'https://mxcore2.duckdns.org:48001/api'; //live api
	// base_url = 'https://ddi-factory.co.id:4433/api'

	timeoutAxios = 3000000
	currencyFormat = 'id-ID'

	axios = axios
	moment = moment
	$ = Jquery

	state = {    
    offline: !navigator.onLine
  }

	constructor(props) {
		super(props)
		axios.defaults.headers.common['Content-Type'] = 'application/json'
		axios.defaults.headers.common['Accept'] = 'application/json'
		axios.defaults.timeout = this.timeoutAxios
	}

	async request(url, method = "get", data = {}, onUploadProgress = response => { }) {
		var token = await localStorage.getItem('token')
		//console.log(token)
		if (token != null) {
			axios.defaults.headers.common['Authorization'] = token
		}

		try {
			var response
			if (method === 'get') {
				response = await axios.get(this.base_url + url)
			}
			else if (method === 'post') {
				response = await axios.post(this.base_url + url, data, {
					headers: { "Content-Type": "application/json" },
					onUploadProgress
				})
				.catch(function (error) {
					if (error.response) {
						console.log(error);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				});
			}
			else if (method === 'postmultipart') {
				response = await axios.post(this.base_url + url, data, {
					headers: { "Content-Type": "multipart/form-data" },
					onUploadProgress
				})
				.catch(function (error) {
					if (error.response) {
						console.log(error);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				});
			}
			else if (method === 'put') {
				response = await axios.put(this.base_url + url, data, {
					headers: { "Content-Type": "application/json" },
					onUploadProgress
				})
			}
			else if (method === 'delete') {
				response = await axios.delete(this.base_url + url)
			}

			if (response.status !== 401) {
				return response.data
			}
			else {
				localStorage.clear()
				window.location.href = '/auth/login'
			}
		} catch (e) {
			this.hidePreloader()
			this.$('#error_modal').modal('show')
			if (e.message === 'Request failed with status code 401') {
				await localStorage.clear()
				window.location.replace('/auth/login')
			}
			else {
				return e
			}
			//   setTimeout(() => {
			// 	console.log(this.base_url + url, e)

			// 	if(e.message === 'Network Error'){
			// 	}
			//   }, 500)
		}
	}

	img_no_image = require('../assets/img/no_image_available.jpeg')
	img_no_profile = require('../assets/img/no_profile_picture.png')
	img_logo = require('../assets/img/6001.png')
	img_logo_daiken = require('../assets/img/solusiqu-64.png')
	img_success = require('../assets/img/success1.png')
	img_loading = require('../assets/img/loading.gif')

	img_scan_qr = require('../assets/img/scan_qr.png')

	setOfflineStatus = () => {
    this.setState({ offline: !navigator.onLine })
  }

	componentDidMount() {
		window.addEventListener('online', this.setOfflineStatus)
    window.addEventListener('offline', this.setOfflineStatus)
	}

	componentWillUnmount() {
		window.removeEventListener('online', this.setOfflineStatus)
    window.removeEventListener('offline', this.setOfflineStatus)
	}	

	async update_array(arr, set_state, data = {}, index = 0) {
		var temp = [...arr]
		temp[index] = data
		set_state(temp)
	}

	add_array(arr, set_state, data = {}) {
		var temp = [...arr]
		temp.push(data)
		set_state(temp)
	}

	update_object(data, set_state, variable = null, key = '') {
		var temp = JSON.parse(JSON.stringify(data))
		temp[key] = variable
		set_state(temp)
	}

	remove_array(arr, set_state, index = 0) {
		var temp = [...arr]
		temp.splice(index, 1)
		set_state(temp)
	}

	url_photo(type, file_name) {
		var image_url = this.url_image + '/' + type + '?file_name=' + file_name + '&rnd=' + moment().format('HHmmss')
		return image_url
	}

	hidePreloader() {
		setTimeout(() => {
			this.$('#preloader_modal').modal('hide')
		}, 500);
	}

	getcidapp(){
		return document.querySelector('meta[name="cidapp"]').content
	}

	getversioninfo(){
		return document.querySelector('meta[name="pwaVer"]').content
	}

	async get_default_input() {
		var url = '/auth/profile'
		var response = await this.request(url)
		if (response != null) {
			if (response.status == 'success') {
				var data = response.data
				return data.DEFAULT_INPUT
			}
		}
	}

	async cek_pwa_version(){		

		if(navigator.onLine){
			
			var url = '/util/appversion?cidapp='+document.querySelector('meta[name="cidapp"]').content
			var response = await this.request(url)
			if (response != null) {
				var pwaVer = response.appversion
				console.log(pwaVer)
				var docVer = document.querySelector('meta[name="pwaVer"]').content
				if (pwaVer !== docVer) {											
	
					var token = await localStorage.getItem('token')
		      var lparam = {token}
		      var url = '/auth/logout'
		      var response = await this.request(url, 'post', lparam)

		      if(response != null){
        	if(response.status == 'success'){    			

						alert("Version di web dan database berbeda\nVersion di web = "+docVer+"\nVersion di db = "+pwaVer);					    

						await localStorage.clear()
						window.location.replace('/auth/login')
						}
					}
				}	
			}
		} 
	}

	async getdatabasename()
	{
		var url = '/util/databasename'
		var response = await this.request(url)
		if (response != null) {
			if (response.status === 'success') {		
				var cdate = await response.databaseinfo
				return cdate				
			}
		}
	}	

	async setConfig(){
		var url = '/hris/getconfig'
		var response = await this.request(url,'post')
		if (response != null) {
			if (response.status==='success') {
				var configList = await response.config
				for(var x in configList){
					await localStorage.setItem(configList[x].CIDCONFIG,configList[x].NVALUE)
				}
			}
		}
	}

	async greeting()
	{
		let timeOfDay;
	  const date = new Date();
	  const hours = date.getHours();	  

	  if (hours < 11) {
	    timeOfDay = 'Good Morning';	    
	  } else if (hours >= 11 && hours < 17) {
	    timeOfDay = 'Good Afternoon';	    
	  } else {
	    timeOfDay = 'Good Evening';
	  }

	  return timeOfDay

		/*var url = '/util/greeting'
		var response = await this.request(url)
		if (response != null) {
			if (response.status === 'success') {		
				var cgreeting = await response.greeting
				return cgreeting
			}
		}*/
	}

	async getservertime_integer()
	{
		var url = '/util/servertime'
		var response = await this.request(url)
		if (response != null) {
			if (response.status === 'success') {		
				var DTIME = await response.dtime
				var DDTIME = new Date(DTIME); 
				//var time = DDTIME.getHours() + ':' + DDTIME.getMinutes() + ':' + DDTIME.getSeconds();
				var time = (DDTIME.getSeconds() * 1 )+(DDTIME.getMinutes()*60)+(DDTIME.getHours()*3600)

				return time
			}
		}
	}	

	async formatdatetime(dtgl,s)
	{
		var dataSubmit = null        

        dataSubmit = {        	        	
        	ddate : dtgl,
        	cformat : s, 
        }        

		var url = '/util/formatdatetime'
		var response = await this.request(url, 'post', dataSubmit)
		if (response != null) {
			if (response.status === 'success') {		
				var cdate = await response.cdate
				return cdate				
			}
		}
	}

	formatDate(y,m,d) {	    
		if (m < 10) 
			m = '0' + m;
		if (d < 10) 
			d = '0' + d;
	
		return [y, m, d].join('-');
	}

	async getCurrentDate(){
		var vDate = new Date()

		let nDay = vDate.getDate()
		let nMonth = vDate.getMonth() + 1
		let nYear = vDate.getFullYear()

		if (nMonth < 10) {
			nMonth = '0' + nMonth
		}
		if (nDay < 10) {
			nDay = '0' + nDay
		}

		var currentDate = nYear+'/'+nMonth+'/'+nDay
		//var currentDate = [nYear, nMonth, nDay].join('/')
		return currentDate;
	}

	/** Check if storage is persisted already.
	  @returns {Promise<boolean>} Promise resolved with true if current origin is
	  using persistent storage, false if not, and undefined if the API is not
	  present.
	*/
	async isStoragePersisted() {
	  return await navigator.storage && navigator.storage.persisted ?
	    navigator.storage.persisted() :
	    undefined;
	}

	/** Tries to convert to persisted storage.
	  @returns {Promise<boolean>} Promise resolved with true if successfully
	  persisted the storage, false if not, and undefined if the API is not present.
	*/
	async persist() {
	  return await navigator.storage && navigator.storage.persist ?
	    navigator.storage.persist() :
	    undefined;
	}

	/** Queries available disk quota.
	  @see https://developer.mozilla.org/en-US/docs/Web/API/StorageEstimate
	  @returns {Promise<{quota: number, usage: number}>} Promise resolved with
	  {quota: number, usage: number} or undefined.
	*/
	async showEstimatedQuota() {
	  return await navigator.storage && navigator.storage.estimate ?
	    navigator.storage.estimate() :
	    undefined;
	}

	/** Tries to persist storage without ever prompting user.
	  @returns {Promise<string>}
	    "never" In case persisting is not ever possible. Caller don't bother
	      asking user for permission.
	    "prompt" In case persisting would be possible if prompting user first.
	    "persisted" In case this call successfully silently persisted the storage,
	      or if it was already persisted.
	*/
	async tryPersistWithoutPromtingUser() {
	  if (!navigator.storage || !navigator.storage.persisted) {
	    return "never";
	  }
	  let persisted = await navigator.storage.persisted();
	  if (persisted) {
	    return "persisted";
	  }
	  if (!navigator.permissions || !navigator.permissions.query) {
	    return "prompt"; // It MAY be successful to prompt. Don't know.
	  }
	  const permission = await navigator.permissions.query({
	    name: "persistent-storage"
	  });
	  if (permission.state === "granted") {
	    persisted = await navigator.storage.persist();
	    if (persisted) {
	      return "persisted";
	    } else {
	      throw new Error("Failed to persist");
	    }
	  }
	  if (permission.state === "prompt") {
	    return "prompt";
	  }
	  return "never";
	}

	async showEstimatedQuota() {
		if (navigator.storage && navigator.storage.estimate) {
			const estimation = await navigator.storage.estimate();
			console.log(`Quota: ${estimation.quota}`);
			console.log(`Usage: ${estimation.usage}`);
		} else {
			console.error("StorageManager not found");
		}
	}
}