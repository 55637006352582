import { Component, useState } from 'react';
import dayjs from 'dayjs';
/*import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { TextField, colors } from '@mui/material';*/
import ViewField from '../../../components/viewField';
import ScrollUp from '../../../components/scrollUp';

export default function PersonInfo({data}){

    const [joinValue, set_joinValue] = useState(dayjs(data.DJOIN))

    return(
        <>
            <div className='col-12 py-2 custom-tabs-bg-solusiqu'>
                <h5 style={{marginBottom:20}}>Personal Information</h5>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CNIP} label='ID' id='idNo'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CEMPLOYEE} label='Full Name' id='fullName'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CKTP} label='KTP' id='ktpNo'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.DJOIN} label='Join Date' id='joinDate'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CMARITALSTATUS} label='Marital Status' id='martialStatus'/>
                {/*<div className='form-group row'>
                    <label for='joinDate' className='custom-input-solusiqu col-3 col-form-label'>Join Date</label>
                    <div className='col-9'>                  
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                            label=""
                            value={joinValue}
                            onChange={(newValue) => set_joinValue(newValue)}
                            format="LL"
                            className='form-control'
                            fullWidth
                            readOnly
                            size='small'
                            sx={{
                                "& .MuiInputBase-inputSizeSmall": {
                                    height: '12px',
                                    fontSize: '9.5px',
                                    color: '#2F2F2F',
                                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
                                }
                              }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                */}
                <hr className='hr'></hr>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CIDPTKPSTATUS} label='PTKP' id='ptkp'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CNPWP} label='NPWP' id='npwp'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CBPJSJK} label='BPJS Kes.' id='bpjsjk'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CBPJSJP} label='BPJS TK / JP' id='bpjsjk'/>
                <hr className='hr'></hr>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CSTATUS} label='Status' id='status'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CPOSITION} label='Position' id='position'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CCOMPANY} label='Company' id='company'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CPROJECT} label='Project' id='project'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CDEPARTMENT} label='Department' id='department'/>
                <ViewField type='text' useLabel='1' readonly={true} data={data.CSUBDEPT} label='Sub Dept.' id='subdept'/>
            </div>  
        </>
    )
}