import React, { useEffect, useState } from 'react';
import Base from '../utils/base';

export default function SelectInputQR({menu_arr, menuSelected, type}){
    var base = new Base()

    useEffect(async ()=>{
    }, [])

    return(
        <>
            <div className='col-12 mt-5 pt-4'>
                <div className='row m-0'>
                    <div className='col-12 text-center'>
                        <p className='m-0'>Pick choice of {type} Data</p>
                    </div>
                    {
                        menu_arr.map((data, index)=>(
                            <>
                                <div className='col-12 mt-4' key={index}>
                                    <button className='btn btn-lg btn-primary rounded w-100' onClick={()=>menuSelected(index)}>{data.title}</button>
                                </div>
                                {
                                    index === 0 &&
                                    <div className='col-12 mt-4 text-center' key={index}>
                                        <p className='m-0'>or</p>
                                    </div>
                                }
                            </>
                        ))
                    }
                </div>
            </div>
        </>
    )
}