import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import FormInput from '../../components/FormInput';
import ResultModalInputID from '../../components/InputIDResultModal';
import WrongProcessModal from '../../components/modalWrongProcess';
import InputQRForm from '../../components/inputQRForm';

export default function MaterialInputID(){
    var base = new Base()
    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [input_type, set_input_type] = useState('')
    const [qr_text, set_qr_text] = useState('')
    const [palette_result_data, set_palette_result_data] = useState({PAL_CID : ''})
    const [production_line_id, set_production_line_id] = useState('')

    const [error_message_modal, set_error_message_modal] = useState('')

    useEffect(async ()=>{
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        var production_line_id = await localStorage.getItem('production_line_id')
        set_production_line_id(production_line_id)

        var data_palette = await localStorage.getItem('packing_input_palette')
        data_palette = JSON.parse(data_palette)

        if(data_palette != null){
            set_palette_result_data(data_palette)
        }
    }, [])

    useEffect(()=>{
        if(palette_result_data.PAL_CID !== ''){
            if(query.get('input_type') === 'palette'){
                base.hidePreloader()
                submitBtn('submit')
                // base.$('#modalResult').modal('show')
            }
        }

    }, [palette_result_data])

    useEffect(async ()=>{
        if(qr_text === ''){
            base.$('#modalResult').modal('hide')
        }
    }, [qr_text])

    function searchQR(key){
        if(key === 'Enter'){
            if(qr_text !== ''){
                resultQR()
            }
            else{
                base.$('#inputQR').focus()
            }
        }
		else if (key === 'Cancel') {
			window.history.back()
		}
    }

    function changeQRInput(value){
        var qr = value.toUpperCase()
        set_qr_text(qr)
    }

    async function resultQR(){
        if(navigator.onLine){
            var barcode = qr_text
            var url = ''
            if (query.get('type') === 'material_back'){
                url='/material/get_st?scanid=' + barcode
            } else if(query.get('type') === 'material_back1'){
                url = '/palette?barcode=' + barcode + '&production_line_id=' + production_line_id + '&type=material_back1'
            } else {
                url = '/palette?barcode=' + barcode + '&production_line_id=' + production_line_id + '&type=material'
            }
            //console.log(url)

            base.$('#preloader_modal').modal('show')

            var response = await base.request(url)
            console.log(response)
            if(response != null){
                if(response.status == 'success'){
                    var data = response.data
                    var flag_scan = 0
                    //console.log(data)

                    if(data != null){
                        if(query.get('type') === 'back_to_packing'){
                            if(data.prod_line != null){
                                if(data.prod_line.PDL_CNAME === 'PACKING'){
                                    base.hidePreloader()
                                    set_error_message_modal(`The Current Pallete ID status is still in Packing, can't be process`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                                else{
                                    set_palette_result_data({PAL_CID : ''})
                                    flag_scan = 1
                                }
                            }
                            else {
                                flag_scan = 1
                            }
                        }
                        else{
                            if(data.prod_line != null){
                                if(data.prod_line.PDL_CNAME !== 'WAREHOUSE'){
                                    flag_scan = 1
                                }
                                else{
                                    base.hidePreloader()
                                    set_error_message_modal(`The Current Pallete ID has already been Scanned`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                            }
                            else{
                                flag_scan = 1
                            }
                        }

                        if(flag_scan){
                            if(data.PAL_NFLAGTYPE == 1){
                                base.hidePreloader()
                                var result = data
                                result.data_type = 'old'

                                set_palette_result_data(result)
                                // base.$('#modalResult').modal('show')
                            }
                            else{
                                if (query.get('type') === 'material_back'){
                                    base.hidePreloader()
                                    var result = data
                                    result.data_type = 'old'

                                    set_palette_result_data(result)
                                    console.log(data)
                                }
                                else{
                                    base.hidePreloader()
                                    set_error_message_modal(`Product must be material`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                            }
                        }
                        base.hidePreloader()
                    }
                    else{
                        base.hidePreloader()

                        set_error_message_modal('Data not found')
                        base.$('#modalWrongProcess').modal('show')
                    }

                }
                else{
                    if (query.get('type') === 'material_back'){
                        base.hidePreloader()
                        set_error_message_modal(response.message) 
                        base.$('#modalWrongProcess').modal('show')
                    }
                    else{
                        base.hidePreloader()
                        base.$('#modalErrorQR').modal('show')
                    }
                }
            }
            else{
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }
        }
    }

    async function submitBtn(type){
        if(type === 'retype'){
            set_palette_result_data({PAL_CID : ''})
            set_qr_text('')
        }
        else{
            // var arr_temp = []
            // for(let palette of palette_result_data){
            //     arr_temp.push({
            //         PAL_CID: palette.PAL_CID,
            //         PDB_CDESCRIPTION: palette.PDB_CDESCRIPTION,
            //     })
            // }			
            await localStorage.setItem('packing_input_palette', JSON.stringify(palette_result_data))
            set_palette_result_data({PAL_CID : ''})
            window.location.href = '/material/input-id/check?type=' + query.get('type')
        }
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={(query.get('type') === 'material_back' ? 'Material Return (All)' : query.get('type') === 'material_back1' ? 'Material Return (Partial)' : 'Material')} icon={(query.get('type') === 'remove' ? 'bi bi-dash-circle-fill' : null)} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <InputQRForm input_type={input_type} qr_text={qr_text} changeQRInput={(val)=>changeQRInput(val)} searchQR={(key)=>searchQR(key)} />

                </div>
            </div>

            <ResultModalInputID palette_data={palette_result_data} scan_type={'palette'} process={query.get('type')} submitBtn={(type)=>submitBtn(type)} />
            <WrongProcessModal type={'error'} message={error_message_modal} />

        </>
    )
}