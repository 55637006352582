import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Navbar from './navbar';
import HomeIndex from '../home';
import AuthLogin from '../auth/login';
import AuthRegister from '../auth/register';
import AuthForgetPass from '../auth/forgetPass';
import AddTrackingRecord from '../tracking/addRecord';
import SuccessPage from '../success';
import TrackingScanBarcode from '../tracking/scanBarcode';
import PackagingScanBarcode from '../packaging/scanBarcode';
import PackagingConfirmData from '../packaging/confirmData';
import InventoryScanBarcode from '../inventory/scanBarcode';
import InventoryDetailPalette from '../inventory/detailPalette';
import ProfileIndex from '../profile';
import ProfileEmployee from '../profile/employee';
import HistoryIndex from '../history';
import HistoryDetail from '../history/detail';
import ErrorModal from '../../components/errorModal';
import Preloader from '../../components/preloader';

import WarehouseScanBarcode from '../warehouse/scanBarcode';
import ShipmentScanBarcode from '../shipment/scanBarcode';
import MaterialScanBarcode from '../material/scanBarcode';
import WarehouseConfirmData from '../warehouse/confirmData';
import MaterialConfirmData from '../material/confirmData';
import PackagingSelectInput from '../packaging/selectInput';
import PackagingInputID from '../packaging/inputID';
import PackagingCheckInput from '../packaging/checkInput';
import WarehouseSelectInput from '../warehouse/selectInput';
import WarehouseInputID from '../warehouse/inputID';
import WarehouseCheckInput from '../warehouse/checkInput';
import ShipmentSelectInput from '../shipment/selectInput';
import ShipementInputID from '../shipment/inputID';
import ShipmentCheckInput from '../shipment/checkInput';
import MaterialSelectInput from '../material/selectInput';
import MaterialInputID from '../material/inputID';
import MaterialCheckInput from '../material/checkInput';

import ESS_EmployeeInfo from '../ess/employeeinfo/index';
import ESS_Absentee from '../ess/employeeabsentee'
import ESS_Leave from '../ess/employeeleave';
import ViewLeave from '../ess/employeeleave/view';
import ESS_Permission from '../ess/employeepermission';
import ViewPermission from '../ess/employeepermission/view';
import ESS_Overtime from '../ess/employeeovertime';
import ViewOvertime from '../ess/employeeovertime/view';
import DexieTest from '../dexie_test';
import EmployeeAttend from '../ess/employeeattend';
import ESS_Revision from '../ess/employeerevision';
import PersonalRevision from '../ess/employeerevision/personal';
import FamilyRevision from '../ess/employeerevision/family';
import AddressRevision from '../ess/employeerevision/address';
import TaxRevision from '../ess/employeerevision/tax';
import AcademyRevision from '../ess/employeerevision/academy';
import {Mailbox} from '../mailbox';
import { Badge } from '@mui/material';

export default class BaseLayout extends Base{

  constructor(props) {
    super(props);
    this.state = { 
      token : '',
      bottom_nav : [
        {title : 'Home', icon : 'home', nav : '/'},
        {title : 'Notification', icon : 'mail', nav : '/mailbox'},
        {title : 'Profile', icon : 'person', nav : '/profile/employee'}
      ],
      notifValue : 0
    }
  }

  async getNotifCount(){
    var base = new Base()
    var url = '/notification/loadnotification'
    var token = await localStorage.getItem('token')
    var dataSubmit = {
      cidemployee : await localStorage.getItem('psn_cid')
    }
    if(token){
      var response = await base.request(url,'post',dataSubmit)
    }

    if (response!=null) {
      if (response.status==='success') {
        this.setState({notifValue: response.loadnotification[0].NCOUNT})
        console.log('success get notif count')
      }
    }
  }
  
  async componentDidMount(){
    var token = await localStorage.getItem('token')
    await this.setState({token : token})
  
    //var pwaver = {document.title}
    let version = document.title

    const last_version = localStorage.getItem('version')
    if(last_version == null || (last_version != null && last_version !== version)){
      localStorage.setItem('version', version)
      window.location.reload(true);
    }

    if (!localStorage.getItem('MSG_TIMER')&&token) {
      setTimeout(() => { 
        console.log('notif set timeout')
        var notifTimer = localStorage.getItem('MSG_TIMER') * 1000
        if (notifTimer>0){
          this.getNotifCount.bind(this)
          this.notifInterval = setInterval(this.getNotifCount.bind(this), notifTimer)
        }
      }, 3000); 
    }else{
      var notifTimer = localStorage.getItem('MSG_TIMER') * 1000
      if (notifTimer>0){
        this.getNotifCount.bind(this)
        this.notifInterval = setInterval(this.getNotifCount.bind(this), notifTimer)
      }
    }
  }

  /*async componentDidUpdate(){
    var notifTimer = localStorage.getItem('MSG_TIMER') * 1000
    if (notifTimer>0){
      this.getNotifCount.bind(this)
      this.notifInterval = setInterval(this.getNotifCount.bind(this), notifTimer)
    }
  }*/

  async componentWillUnmount(){
    clearInterval(this.notifInterval)
  }

  async bottomNav(index){
    window.location.href = this.state.bottom_nav[index].nav
  }

  render(){
    const { token, bottom_nav, notifValue } = this.state

    return (
      <>
      <Router>
        <div className='position-relative' id="wrapper" style={{width : '100wh', height : '100vh'}}>

            
            <div className='position-absolute w-100 pb-5 mb-5 mt-5' style={{top : 0}}>
              <div className='mt-3 pb-5 mb-5'>
                <Routes>
                    <Route exact path={"/"} element={<HomeIndex />}></Route>
                    <Route exact path={"/auth/login"} element={<AuthLogin />}></Route>
                    <Route exact path={"/auth/register"} element={<AuthRegister />}></Route>
                    <Route exact path={"/auth/forget-password"} element={<AuthForgetPass />}></Route>

                    <Route exact path={"/tracking/add-record"} element={<AddTrackingRecord />}></Route>
                    <Route exact path={"/tracking/scan-barcode"} element={<TrackingScanBarcode />}></Route>

                    <Route exact path={"/packaging/scan-barcode"} element={<PackagingScanBarcode />}></Route>
                    <Route exact path={"/packaging/confirm"} element={<PackagingConfirmData />}></Route>

                    <Route exact path={"/packaging/input"} element={<PackagingSelectInput />}></Route>
                    <Route exact path={"/packaging/input-id"} element={<PackagingInputID />}></Route>
                    <Route exact path={"/packaging/input-id/check"} element={<PackagingCheckInput />}></Route>

                    <Route exact path={"/warehouse/scan-barcode"} element={<WarehouseScanBarcode />}></Route>
                    <Route exact path={"/warehouse/confirm"} element={<WarehouseConfirmData />}></Route>
                    <Route exact path={"/warehouse/input"} element={<WarehouseSelectInput />}></Route>
                    <Route exact path={"/warehouse/input-id"} element={<WarehouseInputID />}></Route>
                    <Route exact path={"/warehouse/input-id/check"} element={<WarehouseCheckInput />}></Route>

                    <Route exact path={"/temporary_warehouse/scan-barcode"} element={<WarehouseScanBarcode />}></Route>
                    <Route exact path={"/temporary_warehouse/confirm"} element={<WarehouseConfirmData />}></Route>
                    <Route exact path={"/temporary_warehouse/input"} element={<WarehouseSelectInput />}></Route>
                    <Route exact path={"/temporary_warehouse/input-id"} element={<WarehouseInputID />}></Route>
                    <Route exact path={"/temporary_warehouse/input-id/check"} element={<WarehouseCheckInput />}></Route>

                    <Route exact path={"/shipment/scan-barcode"} element={<ShipmentScanBarcode />}></Route>
                    <Route exact path={"/shipment/input"} element={<ShipmentSelectInput />}></Route>
                    <Route exact path={"/shipment/input-id"} element={<ShipementInputID />}></Route>
                    <Route exact path={"/shipment/input-id/check"} element={<ShipmentCheckInput />}></Route>

                    <Route exact path={"/material/scan-barcode"} element={<MaterialScanBarcode />}></Route>
                    <Route exact path={"/material/confirm"} element={<MaterialConfirmData />}></Route>

                    <Route exact path={"/material/input"} element={<MaterialSelectInput />}></Route>
                    <Route exact path={"/material/input-id"} element={<MaterialInputID />}></Route>
                    <Route exact path={"/material/input-id/check"} element={<MaterialCheckInput />}></Route>

                    <Route exact path={"/inventory/scan-barcode"} element={<InventoryScanBarcode />}></Route>
                    <Route exact path={"/inventory/detail-palette"} element={<InventoryDetailPalette />}></Route>

                    <Route exact path={"/last-history"} element={<HistoryIndex />}></Route>
                    <Route exact path={"/last-history/detail"} element={<HistoryDetail />}></Route>

                    <Route exact path={"/profile"} element={<ProfileIndex />}></Route>
                    <Route exact path={"/profile/employee"} element={<ProfileEmployee />}></Route>
                    <Route exact path={"/mailbox"} element={<Mailbox />}></Route>

                    <Route exact path={"/ess/employeeinfo"} element={<ESS_EmployeeInfo />}></Route>
                    <Route exact path={"/ess/employeeattend"} element={<EmployeeAttend />}></Route>

                    <Route exact path={"/ess/leave"} element={<ESS_Leave />}></Route>
                    <Route exact path={"/ess/leave/detail"} element={<ViewLeave />}></Route>

                    <Route exact path={"/ess/absentee"} element={<ESS_Absentee />}></Route>
                    <Route exact path={"/ess/absenteedetail"} element={<HistoryDetail />}></Route>

                    <Route exact path={"/ess/permission"} element={<ESS_Permission />}></Route>
                    <Route exact path={"/ess/permission/detail"} element={<ViewPermission />}></Route>

                    <Route exact path={"/ess/overtime"} element={<ESS_Overtime/>}></Route>
                    <Route exact path={"/ess/overtime/detail"} element={<ViewOvertime/>}></Route>

                    <Route exact path={"/ess/revision"} element={<ESS_Revision/>}></Route>
                    <Route exact path={"/ess/revision/personal"} element={<PersonalRevision/>}></Route>
                    <Route exact path={"/ess/revision/family"} element={<FamilyRevision/>}></Route>
                    <Route exact path={"/ess/revision/address"} element={<AddressRevision/>}></Route>
                    {/*<Route exact path={"/ess/revision/tax"} element={<TaxRevision/>}></Route>*/}
                    <Route exact path={"/ess/revision/academy"} element={<AcademyRevision/>}></Route>

                    <Route exact path={"/success"} element={<SuccessPage />}></Route>
                    <Route exact path={"/dexie-test"} element={<DexieTest />}></Route>
                </Routes>
              </div>
            </div>

            {
              token !== null &&
              <>
                {
                  window.location.pathname === '/' || window.location.pathname === '/last-history' || window.location.pathname === '/profile' || window.location.pathname === '/profile/employee' || window.location.pathname === '/mailbox'?
                  <div className='position-fixed w-100 py-2 shadow-lg' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                    <div className='row m-0'>
                        {
                          bottom_nav.map((data, index)=>(
                            <div className='col d-flex align-items-center justify-content-center' style={{cursor : 'pointer'}} onClick={()=>this.bottomNav(index)} key={index}>
                              <div>
                                
                                  <p className={'m-0 text-center' + (window.location.pathname === data.nav ? ' text-primary' : '')} style={{lineHeight : '.25rem', color : '#737373'}}>
                                  <Badge badgeContent={data.title==='Notification'?notifValue:0} color='info' max={50}>
                                    <i className="material-icons" style={{fontSize : '1.25rem'}}>{data.icon}</i>
                                  </Badge>
                                  </p>
                                <p className={'m-0 text-center' + (window.location.pathname === data.nav ? ' text-primary' : '')} style={{color : '#737373', fontSize : '.75rem'}}>{data.title}</p>
                              </div>
                            </div>
                          ))
                        }
                    </div>
                  </div>
                  : <></>
                }
              </>
            }

            <ErrorModal />
            <Preloader />

        </div>
      </Router>
      </>
    );
  }
}