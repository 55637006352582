import { useEffect, useState } from 'react';
import SingleScan from './singleScan';
import Base from '../utils/base'
import PaletteList from './paletteList';

export default function ModalPaletteResult({data_arr, submitBtn, scan_type, wh_type, process, viewFrom}){
    var base = new Base()

    const [action_btn_arr] = useState([
        {title : (process === 'view' ? 'View' : 'Proceed'), icon : '', btn_type : 'btn-primary', type : 'confirm'},
        // {title : 'Reinput Palette ID', icon : 'bi bi-upc-scan', btn_type : 'btn-outline-primary', type : 'retype'},
    ])

    return(
        <>
        <div className="modal fade" id="modalResult" tabIndex="-1" aria-labelledby="modalResultLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable m-0">
                <div className="modal-content border-0 shadow-sm position-fixed" style={{bottom : 0, borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                    <div class="modal-header" style={{backgroundColor : '#E1EAEF', borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col p-0 d-flex align-items-center'>
                                    <p className='m-0'>Validation Result</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className={'row m-0'}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <PaletteList data_arr={data_arr} viewFrom={viewFrom} wh_type={wh_type} />
                                    </div>
                                    <div className='col-12 mt-4 p-0'>
                                    {
                                        action_btn_arr.map((data, index)=>(
                                            <button className={'btn btn-lg w-100 rounded text-capitalize ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={()=>submitBtn(data.type)} key={index}>
                                                {
                                                    data.icon !== '' &&
                                                    <i className={data.icon + ' mr-2'}></i>
                                                }
                                                {data.title}
                                            </button>
                                        ))
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}