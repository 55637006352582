import PageHeader from "./pageHeader"
import { FormControl } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export default function MenuHeaderTwoDate({headTitle,dateStartValue,onChangeDateStart,dateFinishValue,onChangeDateFinish,onClickFilter}){
    return(
        <div className='page_wrap fixed-top py-2' style={{backgroundColor:'#ffffff', height:250}}>
             <div className='row'>
                <div className='col-12 pt-5'>
                    <PageHeader title={headTitle} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    <div className='custom-tabs-bg-solusiqu mt-2'>
                        <div className='mb-5 pt-3 px-4'>
                            <div className='pb-3' style={{textAlign:'center'}}>
                                <h6>Filter</h6>
                            </div>
                            <FormControl className='col-6' sx={{mb:'5px'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="Start"
                                        value={dateStartValue}
                                        size='small'
                                        views={['year', 'month', 'day']}
                                        onChange={onChangeDateStart}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                height: '0.35em',
                                                display: 'inline'
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl className='col-6' sx={{mb:'5px'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="Finish"
                                        value={dateFinishValue}
                                        size='small'
                                        views={['year', 'month', 'day']}
                                        onChange={onChangeDateFinish}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                height: '0.35em',
                                                display: 'inline'
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <button className='btn btn-primary rounded float-right' 
                                    onClick={onClickFilter}
                            >Check
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}